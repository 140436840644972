<script setup>
import {
  GrModal,
  GrModalContent,
  GrModalFooter,
  GrModalClose,
  GrModalHeader,
  GrButton,
} from "@/components/GrComponents";
import { getCurrentInstance, inject } from "vue";
import { useStore } from "@/utils/useStore"

const store = useStore()
const instance = getCurrentInstance()
const vm = instance.proxy
const getSetModalDelet = inject("getSetModalDelet");
const questionSelected = inject("questionSelected");

function toast(message, type) {
  vm.$bvToast.toast(message, {
    title: vm.$t("opportunities.profit"),
    variant: type,
    autoHideDelay: 2000,
    appendToast: true,
  });
}

async function deleteQuestion (){
  const resp = await store.dispatch('questions/deletQuestion',questionSelected.value)
  if(resp){
    toast(vm.$t('opportunities.modal.success_delet'), "success")
  }
  getSetModalDelet.value = {}
}
</script>

<template>
  <div>
    <GrModal :custonEvents="false" :modelValue.sync="getSetModalDelet">
      <GrModalContent class="size-custon" size="sm">
        <GrModalHeader />
        <span class="delete-title">{{$t('opportunities.modal.confirm_delete_title')}}</span>
        <p class="delete-decription">{{$t('opportunities.modal.confirm_delete_decription')}}</p>
        <GrModalFooter class="footer-position">
          <GrModalClose class="costom-width">
            <GrButton class="size-button btn-close" variant="gray-light">
              {{ $t("opportunities.modal.button_cancel") }}
            </GrButton>
          </GrModalClose>
          <GrButton variant="red" class="size-button costom-width" @click="deleteQuestion()">
            {{ $t("opportunities.modal.confirm_delete") }}
          </GrButton>
        </GrModalFooter>
      </GrModalContent>
    </GrModal>
  </div>
</template>

<style lang="scss" scoped>
.footer-position {
  justify-content: space-evenly !important;
}
.size-custon{
  width: var(--w-98) !important;
}
.size-button {
  height: var(--h-12);
}
.btn-close{
  width: 100%;
}
.costom-width {
  width: 45%;
}
.delete-title{
  width: 100%;
  text-align: center;
  font-size: var(--font-xl);
  font-weight: var(--weight-bold);
  color: var(--primary-800);

}
.delete-decription{
  text-align: center;
  font-size: var(--font-md);
  color: var(--gray-200);
}
</style>