<script setup>
import { computed } from "vue";

const emit = defineEmits(["click"]);

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: "primary",
  },
  loading: {
    type: Boolean,
    default: false,
  },
  fullSize: {
    type: Boolean,
    default: false,
  },
});

const cssClasses = computed(() => {
  let classes = [];

  props.outline
    ? classes.push(`gr-button-outline-${props.variant}`)
    : classes.push(`gr-button-${props.variant}`);
  props.fullSize ? classes.push("gr-button-full") : null;
  return classes;
});

const opacityIsLoading = () => {
  if (props.loading) {
    return "opacity: 0.5;";
  }
  return "";
};

const colorSpinner = () => {
  if (props.outline) {
    const colors = {
      primary: "var(--primary-500)",
      secondary: "var(--secondary-500)",
      orange: "var(--orange-500)",
      red: "var(--red-500)",
      "green-dark": "var(--green-dark-500)",
      "gray-dark": "var(--gray-500)",
      "gray-white": "var(--gray-white)",
      alternative: "var(--secondary-500)",
    };
    return colors[props.variant];
  }

  if (props.variant === "alternative") {
    return "var(--primary-500)";
  }

  return "var(--gray-white)";
};

function click(event) {
  if (event.type === "click") {
    emit("click");
  }
}
</script>

<template>
  <button
    :class="['gr-button', ...cssClasses]"
    :disabled="disabled"
    @click.prevent="click"
  >
    <div v-show="loading" class="gr-button-container-spinner">
      <div class="gr-button-spinner" />
    </div>

    <div class="gr-button-content" :style="opacityIsLoading()">
      <slot />
    </div>
  </button>
</template>

<style lang="scss" scoped>
.gr-button {
  position: relative;
  border: none;
  outline: none;
  font-weight: var(--weight-semibold);
  height: 55px;
  padding: var(--spacing-0) var(--spacing-8);
  border-radius: var(--radius-lg);
  font-size: var(--font-sm);
  border: none;
  outline: none;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2);
  border: 1px solid transparent;
  user-select: none;

  .gr-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-3);
    text-wrap: nowrap;
    overflow: hidden;
    color: inherit;
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    .gr-button-spinner {
      background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/3.8px
          3.8px no-repeat,
        conic-gradient(#0000 30%, #ffffff);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        #ffffff 0
      );
      mask: radial-gradient(farthest-side, #0000 calc(100% - 3.8px), #ffffff 0);
    }
  }
  &:hover {
    .gr-button-spinner {
      background: radial-gradient(farthest-side, #ffffff 94%, #0000) top/3.8px
          3.8px no-repeat,
        conic-gradient(#0000 30%, #ffffff);
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        #ffffff 0
      );
      mask: radial-gradient(farthest-side, #0000 calc(100% - 3.8px), #ffffff 0);
    }
  }

  @include size(lg) {
    font-size: var(--font-xs);
  }
  @include size(sm) {
    height: var(--h-11);
    font-size: var(--font-xs);
  }
}

.gr-button-full {
  width: 100%;
}

.gr-button-primary {
  color: var(--gray-white);
  background: var(--primary-500);
  &:hover {
    color: var(--gray-white);
    background: var(--primary-400);
  }
  &:focus {
    background: var(--primary-600);
  }
  &:disabled {
    background: var(--primary-500);
  }
}

.gr-button-outline-primary {
  color: var(--primary-500);
  background: var(--gray-white);
  border: 1.5px solid var(--primary-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--primary-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--primary-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--primary-500);
  }
}

.gr-button-secondary {
  color: var(--gray-white);
  background: var(--blue-500);
  &:hover {
    color: var(--gray-white);
    background: var(--blue-400);
  }
  &:focus {
    background: var(--blue-600);
  }
  &:disabled {
    background: var(--blue-500);
  }
}

.gr-button-outline-secondary {
  color: var(--secondary-500);
  background: var(--gray-white);
  border: 1.5px solid var(--secondary-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--secondary-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--secondary-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--secondary-500);
  }
}

.gr-button-alternative {
  color: var(--primary-500);
  background: var(--primary-50);
  &:hover {
    color: var(--primary-500);
    background: var(--primary-100);
    .gr-button-spinner {
      background: radial-gradient(farthest-side, var(--primary-500) 94%, #0000)
          top/3.8px 3.8px no-repeat,
        conic-gradient(#0000 30%, var(--primary-500)) !important;
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      ) !important;
      mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      ) !important;
    }
  }
  &:focus {
    background: var(--primary-100);
  }
  &:disabled {
    background: var(--primary-100);
    .gr-button-spinner {
      background: radial-gradient(farthest-side, var(--primary-500) 94%, #0000)
          top/3.8px 3.8px no-repeat,
        conic-gradient(#0000 30%, var(--primary-500));
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      );
      mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      );
    }
  }
}

.gr-button-outline-alternative {
  color: var(--primary-500);
  background: var(--gray-white);
  border: 1.5px solid var(--primary-500) !important;
  &:hover {
    color: var(--primary-500);
    background: var(--primary-50);

    .gr-button-spinner {
      background: radial-gradient(farthest-side, var(--primary-500) 94%, #0000)
          top/3.8px 3.8px no-repeat,
        conic-gradient(#0000 30%, var(--primary-500)) !important;
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      ) !important;
      mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      ) !important;
    }
  }
  &:focus {
    color: var(--primary-500);
    background: var(--primary-100);
  }
  &:disabled {
    color: var(--primary-500);
    background: var(--primary-100);

    .gr-button-spinner {
      background: radial-gradient(farthest-side, var(--primary-500) 94%, #0000)
          top/3.8px 3.8px no-repeat,
        conic-gradient(#0000 30%, var(--primary-500));
      -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      );
      mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 3.8px),
        var(--primary-500) 0
      );
    }
  }
}

.gr-button-orange {
  color: var(--gray-white);
  background: var(--orange-500);
  &:hover {
    color: var(--gray-white);
    background: var(--orange-400);
  }
  &:focus {
    background: var(--orange-600);
  }
  &:disabled {
    background: var(--orange-500);
  }
}

.gr-button-outline-orange {
  color: var(--orange-500);
  background: var(--gray-white);
  border: 1.5px solid var(--orange-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--orange-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--orange-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--orange-500);
  }
}

.gr-button-red {
  color: var(--gray-white);
  background: var(--red-500);
  &:hover {
    color: var(--gray-white);
    background: var(--red-400);
  }
  &:focus {
    background: var(--red-600);
  }
  &:disabled {
    background: var(--red-500);
  }
}

.gr-button-outline-red {
  color: var(--red-500);
  background: var(--gray-white);
  border: 1.5px solid var(--red-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--red-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--red-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--red-500);
  }
}

.gr-button-green-dark {
  color: var(--gray-white);
  background: var(--green-dark-500);
  &:hover {
    color: var(--gray-white);
    background: var(--green-dark-400);
  }
  &:focus {
    background: var(--green-dark-600);
  }
  &:disabled {
    background: var(--green-dark-500);
  }
}

.gr-button-outline-green-dark {
  color: var(--green-dark-500);
  background: var(--gray-white);
  border: 1.5px solid var(--green-dark-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--green-dark-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--green-dark-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--green-dark-500);
  }
}

.gr-button-gray-dark {
  color: var(--gray-50);
  background: var(--gray-500);
  &:hover {
    color: var(--gray-white);
    background: var(--gray-400);
  }
  &:focus {
    background: var(--gray-400);
  }
  &:disabled {
    background: var(--gray-500);
  }
}

.gr-button-gray-light {
  color: var(--gray-300);
  background: var(--gray-10);
  &:hover {
    color: var(--gray-400);
    background: var(--gray-50);
  }
  &:focus {
    background: var(--gray-50);
  }
  &:disabled {
    background: var(--gray-500);
  }
}

.gr-button-outline-gray-dark {
  color: var(--gray-500);
  background: var(--gray-white);
  border: 1.5px solid var(--gray-500) !important;
  &:hover {
    color: var(--gray-white);
    background: var(--gray-400);
  }
  &:focus {
    color: var(--gray-white);
    background: var(--gray-500);
  }
  &:disabled {
    color: var(--gray-white);
    background: var(--gray-500);
  }
}

.gr-button-gray {
  color: var(--text);
  background: var(--gray-10);
  &:hover {
    color: var(--text);
    background: var(--gray-50);
  }
  &:focus {
    background: var(--gray-100);
  }
  &:disabled {
    background: var(--gray-10);
  }
}

.gr-button-container-spinner {
  position: absolute;
  width: 90%;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-full);
  background-color: transparent !important;
  backdrop-filter: blur(0.3px) !important;
  transition: var(--transition);

  .gr-button-spinner {
    width: var(--w-6);
    height: var(--h-6);
    border-radius: var(--radius-full);
    transition: var(--transition);
    background: radial-gradient(farthest-side, v-bind(colorSpinner()) 94%, #0000)
        top/3.8px 3.8px no-repeat,
      conic-gradient(#0000 30%, v-bind(colorSpinner()));
    -webkit-mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 3.8px),
      v-bind(colorSpinner()) 0
    );
    mask: radial-gradient(
      farthest-side,
      #0000 calc(100% - 3.8px),
      v-bind(colorSpinner()) 0
    );
    animation: spinner 0.6s infinite linear;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
