<template>
  <div class="mt-3">
    <p class="mb-3" style="font-weight:600;">{{ $t('views.buyer.dashboard.text_123') }}</p>
    <b-row class="mb-4">
      <b-col
        v-for="(purchase,index) in purchases"
        :key="`${purchase.id + index}`"
        cols="12" sm="12" md="6" lg="6" xl="4"
      >
        <div class="card_purchase my-2 p-3">
          <div class="mb-2 d-flex align-items-center">
            <div class="mr-2">
              <img class="rounded" :src="purchase.image" height="70" width="70" alt="product_image" >
            </div>
            <div class="my-2">
              <p class="text1" :class="getStatus(purchase.status).class">
                {{ getStatus(purchase.status).text || '-' }}
              </p>
              <p class="text1 mb-0">{{ $t('views.buyer.dashboard.text_124') }} {{ formatDateWithBrowserTimeZoneCreated(purchase.created_at,'DD MMMM') }}</p>
            </div>
          </div>
          <div>
            <p v-if="purchase.warranty" class="text2">
              <span v-if="purchase.paid_at">{{ $t('views.buyer.dashboard.text_125') }}</span> {{ formatDateWithBrowserTimeZone(purchase.paid_at, 'dddd, DD [de] MMMM [de] YYYY', purchase.warranty_days) }}
            </p>
            <p class="text3">{{ purchase.product_name }}</p>
            <p class="text3" :class="getStatus(purchase.status).class">{{ purchase.installments }}x {{ purchase.currency_symbol }} {{ calcBalance(purchase.balance, purchase.installments) }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <div class="mr-2">
              <p class="text3">{{ purchase.seller_name }}</p>
              <p
                v-if="purchase.support_telephone"
                class="text1 text-blue cursor-pointer"
                @click="openLink(formatTel(purchase.support_telephone))"
              >
                {{ $t('views.buyer.dashboard.text_126') }}
              </p>
              <p v-else class="text1 text-gray">{{ $t('views.buyer.dashboard.text_126') }}</p>
            </div>
            <div>
              <BaseButton
                variant="info2"
                :class="{'heaven': isHeaven}"
                style="height: 40px !important;"
                @click="openPurchasePreview(purchase)"
              >
                {{ $t('views.buyer.dashboard.text_127') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-if="!loading && purchases.length" class="d-flex justify-content-end mb-5">
      <p
        v-if="showSeeMore"
        class="text1 text-blue cursor-pointer see_more"
        @click="seeMore"
      >
        {{ $t('views.buyer.dashboard.text_128') }}
      </p>
    </div>
    <!-- Loading -->
    <div v-if="loading && purchases.length" class="d-flex justify-content-end mb-5" alt="see_more">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <!-- Feedback Message -->
    <b-row
      v-if="!loading && !purchases.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">{{ $t('views.buyer.dashboard.text_129') }}</p>
    </b-row>

    <!-- Modals -->
    <PurchasePreview
      ref="purchasePreview"
      :updateQueryParams="setQueryParams"
    />
  </div>
</template>

<script>
import PurchasePreview from '@/components/Buyer/purchases/PurchasePreview'
import formatMoney from '@/mixins/money'
import moment from '@/plugins/moment'

import DashboardService from '@/services/resources/DashboardService'

const serviceDashboard = DashboardService.build()

export default {
  name: 'RecentPurchases',
  components: { PurchasePreview },
  mixins: [formatMoney],
  data() {
    return {
      current_page: 1,
      purchases: [],
      loading: false,
      showSeeMore: true
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    moment() {
      if(this.$i18n.locale === 'pt') {
        moment.locale('pt-br')
      } else if(this.$i18n.locale === 'es') {
        moment.locale('es')
      } else moment.locale('en-in')
      return moment
    }
  },
  methods: {
    calcBalance(value, installment) {
      const totalValue = this.formatMoney(value);
      const valueValidated = value ? parseFloat(totalValue) : 0;
      const installmentValidated = installment ? parseInt(installment) : 1
      const res = this.formatMoney(valueValidated / installmentValidated);
      return res;
    },
    formatDateWithBrowserTimeZone(date, format, addDays = null) {
      if(!date){
        return this.$t('views.buyer.my_purchases.text_3051')
      }
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const momentObj = moment.tz(date, browserTimeZone);
     
      // Adiciona dias apenas se addDays for fornecido
      if (addDays !== null) {
        momentObj.add(addDays, 'days');
      }

      return momentObj.format(format);
    },
    formatDateWithBrowserTimeZoneCreated(date, format) {
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const momentObj = moment.tz(date, browserTimeZone);

      momentObj.subtract(3, 'hours');

      return momentObj.format(format);
    },
    seeMore() {
      this.current_page += 1
      this.fetchPurchases()
    },
    fetchPurchases() {
      this.loading = true

      var data = { current_page: this.current_page }

      serviceDashboard
        .search({url:'purchases', ...data})
        .then((response) => {
          if(response.data.length < 3) this.showSeeMore = false
          response.data.forEach(x => this.purchases.push(x))
        })
        .catch((err) => console.error(err))
        .finally(() => { this.loading = false })
    },
    getStatus(status) {
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": return { text: this.$t('shared.text_2114'), class: "icon-blue" }
          case "ended": return { text: this.$t("seller.recurrence_subscriptions.text_2459"), class: "icon-blue" }
          case "authorized": return { text: this.$t('shared.text_2115'), class: "icon-blue" }
          case "pending_approbation": return { text: this.$t('shared.text_2116'), class: "icon-blue" }
          case "paid": return { text: this.$t('shared.text_2117'), class: "icon-green" };
          case "refunded": return { text: this.$t('shared.text_2118'), class: "icon-red" }
          case "waiting_payment": return { text: this.$t('shared.text_2119'), class: "icon-warning" }
          case "pending_payment": return { text: this.$t('seller.recurrence_subscriptions.right_preview.text_1814'), class: "icon-warning" }
          case "trialing": return { text: this.$t("seller.recurrence_subscriptions.text_2481"), class: "icon-warning" }
          case "pending_refund":
          case "refund_pending": return { text: this.$t('shared.text_2120'), class: "icon-warning" }
          case "unpaid": return { text: this.$t('seller.recurrence_subscriptions.text_2457'), class: "icon-red" }
          case 'canceled': return { text: this.$t('seller.recurrence_subscriptions.text_2458'), class: 'icon-red' }
          case "refused": return { text: this.$t('shared.text_2121'), class: "icon-red" }
          case "chargedback": return { text: this.$t('shared.text_2122'), class: "icon-warning" }
          case "delayed": return { text: this.$t('shared.text_2123'), class: "icon-warning" }
          default: return { text: status, class: "icon-black" }
        }
      } else return { text: status, class: "icon-black" }
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    openPurchasePreview(purchase) {
      this.$router.push({ query: { pur_id: purchase.id, tab: purchase.type } })

      setTimeout(() => {
        this.$refs.purchasePreview.openPanel(purchase.id)
        this.loading = false
      })
    },
    setQueryParams(isNotToRefresh) {
      if (isNotToRefresh) {
        this.$router.replace({ query: {} })
      } else {
        this.fetchPurchases()
      }
    },
    formatTel(tel) {
      if(tel) {
        if(tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "")
          if(tell[0] === '+') tell = tell.substring(1)
          return `https://api.whatsapp.com/send?phone=${tell}`
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(/[() -]/g, "")}`
        }
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
  created() {
    this.fetchPurchases()
  }
}
</script>

<style scoped>
.card_purchase {
  background-color: #F7F7F7;
  border-radius: 5px;
}
.text1 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #333333;
}
.text2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #333333;
}
.text3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #81858E;
}
.text-gray {
  color: #81858E;
}
.text-blue {
  color: #2133D2;
}
.see_more:hover {
  transform: scale(1.1);
}
</style>