<template>
  <BaseModal
    name="modal-permission-group"
    size="lg"
    :title="$t('seller.settings.modal_permission_group.text_754')"
    @shown="openModal"
    @hidden="reset"
  >
    <b-row>
      <b-col cols="12">
        <b-form novalidate v-if="!loading">
          <!-- Name -->
          <b-row class="mb-3">
            <b-col cols="6">
              <b-form-group
                :label="$t('seller.settings.modal_permission_group.text_755')"
                label-for="date-in"
              >
                <b-form-input
                  id="name"
                  name="name"
                  v-model="name"
                  type="text"
                  v-validate="'required'"
                  :placeholder="
                    $t('seller.settings.modal_permission_group.text_2657')
                  "
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('name')">
                  {{ $t("seller.settings.modal_permission_group.text_2658") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Permissions List -->
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.settings.modal_permission_group.text_2659')"
                label-for="date-in"
              >
                <section class="permissions">
                  <div
                    class="grid-list-itens"
                    v-for="(permission, index) in permissions"
                    :key="permission.id"
                  >
                    <div>
                      <div class="d-flex itens">
                        <img
                          v-if="permission.type === 'store'"
                          src="@/assets/img/permissions/store.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'view'"
                          src="@/assets/img/permissions/view.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'delete'"
                          src="@/assets/img/permissions/delete.svg"
                          alt="img"
                        />
                        <img
                          v-else-if="permission.type === 'edit'"
                          src="@/assets/img/permissions/edit.svg"
                          alt="img"
                        />
                        <div>
                          <p>{{ transalePermissionsTitle(permission.title) }}</p>
                          <span>{{ transalePermissionsDescription(permission.description) }}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <b-form-checkbox
                        v-model="permission.check"
                        @change="changeCheck(permission, index)"
                        name="check-button"
                        switch
                        size="lg"
                        class="ml-switch"
                      ></b-form-checkbox>
                    </div>
                  </div>
                </section>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <!-- Loading -->
        <div v-if="loading" class="Table-body py-4 d-flex justify-content-center align-items-center">
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="info2" :disabled="loading" @click="onSubmit">
        Salvar
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import SubUsersPermissionsService from '@/services/resources/SubUsersPermissionsService'
import SubUsersGroupService from '@/services/resources/SubUsersGroupService'
import gtagSend from '@/utils/gtagSend';

const serviceSubUsersPermissions = SubUsersPermissionsService.build()
const serviceSubGroup = SubUsersGroupService.build()


export default {
  name: 'ModalPermissionGroup',
  props: ['allData'],
  data() {
    return {
      loading: true,
      name: '',
      permission: [],
      permissions: []
    }
  },
  methods: {
    changeCheck(data, index) {
      var permissions = this.permissions;
      if (
        data.name === "CouponEdit" ||
        data.name === "CouponDelete" ||
        data.name === "CouponCreate"
      ) {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "CouponRead" ||
              element.name === "LinkRead" ||
              element.name === "ProductRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (
              (element.name === "CouponDelete" && element.check) ||
              (element.name === "CouponCreate" && element.check) ||
              (element.name === "CouponEdit" && element.check)
            ) {
            } else {
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (
                  (element.name === "CouponDelete" && element.check) ||
                  (element.name === "CouponCreate" && element.check) ||
                  (element.name === "CouponEdit" && element.check)
                ) {
                  if (
                    element2.name === "CouponRead" ||
                    element2.name === "LinkRead" ||
                    element2.name === "ProductRead"
                  ) {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "CouponRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "ProductRead" || element.name === "LinkRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (element.name === "CouponRead" && element.check) {
            } else {
              if (
                (element.name === "CouponDelete" && element.check) ||
                (element.name === "CouponCreate" && element.check) ||
                (element.name === "CouponEdit" && element.check) ||
                element.name === "LinkRead"
              ) {
                permissions[i].check = false;
              }
              if (element.name === "SaleRead") {
                for (let x = 0; x < permissions.length; x++) {
                  const element2 = permissions[x];
                  if (element2.name === "ProductRead" && element.check) {
                    permissions[x].check = true;
                  }
                  if (element2.name === "ProductRead" && !element.check) {
                    permissions[x].check = false;
                  }
                }
              }
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (element.name === "CouponRead" && element.check) {
                  if (
                    element2.name === "ProductRead" ||
                    element2.name === "LinkRead" ||
                    element2.name === "CouponDelete" ||
                    element2.name === "CouponCreate" ||
                    element2.name === "CouponEdit"
                  ) {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "LinkRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "ProductRead" || element.name === "CouponRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (
              (element.name === "CouponDelete" && element.check) ||
              (element.name === "CouponCreate" && element.check) ||
              (element.name === "CouponEdit" && element.check) ||
              (element.name === "LinkCreate" && element.check) ||
              (element.name === "LinkEdit" && element.check) ||
              (element.name === "LinkDelete" && element.check) ||
              (element.name === "CheckoutRead" && element.check) ||
              (element.name === "AffiliateRead" && element.check) ||
              element.name === "ProductRead" ||
              element.name === "CouponRead"
            ) {
              permissions[i].check = false;
            }
            for (let x = 0; x < permissions.length; x++) {
              const element2 = permissions[x];
              if (element.name === "LinkRead" && element.check) {
                if (
                  element2.name === "ProductRead" ||
                  element2.name === "CouponRead" ||
                  element2.name === "CouponDelete" ||
                  element2.name === "CouponCreate" ||
                  element2.name === "CouponEdit"
                ) {
                  permissions[i].check = false;
                }
              }
            }
          }
          if (element.name === "SaleRead") {
            let data = permissions.filter(
              (x) =>
                (x.check === true && x.name === "CouponRead") ||
                (x.check === true && x.name === "ProductRead")
            );
            permissions[i].check = false;
            if (data.length === 2) {
              permissions[i].check = true;
            } else {
              permissions[i].check = false;
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "ProductRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "SaleRead" ||
              element.name === "CouponRead" ||
              element.name === "LinkRead" ||
              element.name === "CouponDelete" ||
              element.name === "CouponCreate" ||
              element.name === "CouponEdit" ||
              element.name === "SaleEdit") &&
            data.check === false
          ) {
            permissions[i].check = false;
          }
          if (
            (element.name === "EditGeneralProduct" ||
              element.name === "EditProductOffers" ||
              element.name === "EditProductPartners" ||
              element.name === "EditProductAffiliates" ||
              element.name === "EditProductIntegrations" ||
              element.name === "EditProductTracking" ||
              element.name === "EditProductTaxSetup" ||
              element.name === "ProductCreate" ||
              element.name === "ProductEdit") &&
            data.check === false
          ) {
            permissions[i].check = false;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "SaleRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (element.name === "ProductRead" && data.check === true) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (element.name === "ProductRead") {
              let data = permissions.filter(
                (x) =>
                  (x.check === true && x.name === "CouponRead") ||
                  (x.check === true && x.name === "LinkRead")
              );
              permissions[i].check = false;
              if (data.length === 2) {
                permissions[i].check = true;
              } else {
                permissions[i].check = false;
              }
            }

            if (element.name === "CouponRead" && element.check) {
              permissions[i].check = true;
            } else if (element.name === "CouponRead" && !element.check) {
              permissions[i].check = false;
            }

            if (element.name === "LinkRead" && !element.check) {
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (element2.name === "CouponRead") {
                  permissions[x].check = false;
                }
              }
            }

            if (
              (element.name === "SaleEdit" || element.name === "SaleClientEmailEdit") &&
              !data.check
            ) {
              permissions[i].check = false;
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "SaleEdit") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "ProductRead" || element.name === "SaleRead") &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "LeadEdit") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if ((element.name === "LeadRead" || element.name === "ProductRead") && data.check === true) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return
      }
      if (data.name === "LeadRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if ((element.name === "ProductRead") && data.check === true) {
            permissions[i].check = true;
          }
          if ((element.name === "LeadEdit") && !data.check) {
            permissions[i].check = false;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return
      }
      if (data.name === "ContractEdit" || data.name === "ContractDelete" || data.name === "ContractCreate") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (element.name === "ContractRead" && data.check === true) {
            permissions[i].check = true;
          } else if (data.check === false) {
            if (
              (element.name === "ContractDelete" && element.check) ||
              (element.name === "ContractCreate" && element.check) ||
              (element.name === "ContractEdit" && element.check)
            ) {
            } else {
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (
                  (element.name === "ContractDelete" && element.check) ||
                  (element.name === "ContractCreate" && element.check) ||
                  (element.name === "ContractEdit" && element.check)
                ) {
                  if (element2.name === "ContractRead") {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "ContractRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (data.check === false) {
            if (element.name === "ContractRead" && element.check) {
            } else {
              if (
                (element.name === "ContractDelete" && element.check) ||
                (element.name === "ContractCreate" && element.check) ||
                (element.name === "ContractEdit" && element.check)
              ) {
                permissions[i].check = false;
              }
              for (let x = 0; x < permissions.length; x++) {
                const element2 = permissions[x];
                if (element.name === "ContractRead" && element.check) {
                  if (
                    element2.name === "ContractDelete" ||
                    element2.name === "ContractCreate" ||
                    element2.name === "ContractEdit"
                  ) {
                    permissions[i].check = false;
                  }
                }
              }
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (
        data.name === "EditGeneralProduct" ||
        data.name === "EditProductOffers" ||
        data.name === "EditProductPartners" ||
        data.name === "EditProductAffiliates" ||
        data.name === "EditProductIntegrations" ||
        data.name === "EditProductTracking" ||
        data.name === "EditProductTaxSetup"
      ) {
        this.loading = true;
        if (data.check) {
          for (let i = 0; i < permissions.length; i++) {
            const element = permissions[i];
            if (
              element.name === "ProductRead" ||
              element.name === "ProductEdit"
            ) {
              permissions[i].check = true;
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "ProductCreate" || data.name === "ProductEdit") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (data.check) {
            if (element.name === "ProductRead") {
              element.check = true;
            }
          } else if (!data.check && data.name === "ProductEdit") {
            if (
              element.name === "EditGeneralProduct" ||
              element.name === "EditProductOffers" ||
              element.name === "EditProductPartners" ||
              element.name === "EditProductAffiliates" ||
              element.name === "EditProductIntegrations" ||
              element.name === "EditProductTracking" ||
              element.name === "EditProductTaxSetup"
            ) {
              element.check = false
            }
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }

      if (["CenterNotasCreate", "CenterNotasEdit"].includes(data.name)) {
        this.loading = true;
        const item = this.permissions.filter(el => el.name === "CenterNotasRead").pop();
        const index = this.permissions.map(el => el.name).indexOf("CenterNotasRead");
        if (data.check && !item.check) {
          item.check = !item.check;
        }
        this.permissions[index] = item;
        this.loading = false;
        return;
      }
      if (["CenterNotasRead"].includes(data.name)) {
        this.loading = true;

        // Edit
        const editItem = this.permissions.filter(el => el.name === "CenterNotasEdit").pop();
        const editIndex = this.permissions.map(el => el.name).indexOf("CenterNotasEdit");
        // Create
        const createItem = this.permissions.filter(el => el.name === "CenterNotasCreate").pop();
        const createIndex = this.permissions.map(el => el.name).indexOf("CenterNotasCreate");
        if (!data.check) {
          editItem.check = false;
          createItem.check = false;
        }

        this.permissions[editIndex] = editItem;
        this.permissions[createIndex] = createItem;
        this.loading = false;
        return;
      }
      
      if (data.name === "SaleClientEmailEdit") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            element.name === "SaleRead" &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "LinkCreate" || data.name === "LinkEdit" || data.name === "LinkDelete") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            element.name === "LinkRead" &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
          if(data.name === "LinkCreate" && data.check === true && element.name === "CheckoutRead") {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "CheckoutRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            (element.name === "LinkRead" || element.name === "LinkCreate") &&
            !permissions[i].check &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "CustomCheckoutCreate" || data.name === "CustomCheckoutEdit" || data.name === "CustomCheckoutDelete") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            element.name === "CustomCheckoutRead" &&
            !permissions[i].check &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
      if (data.name === "AffiliateRead") {
        this.loading = true;
        for (let i = 0; i < permissions.length; i++) {
          const element = permissions[i];
          if (
            element.name === "LinkRead" &&
            !permissions[i].check &&
            data.check === true
          ) {
            permissions[i].check = true;
          }
        }
        this.permissions = permissions;
        this.loading = false;
        return;
      }
    },
    reset() {
      this.name = "";
      this.permission = [];
      this.permissions = [];
    },
    fetchPermissions() {
      this.loading = true

      serviceSubUsersPermissions
        .read('')
        .then(res => {
          const order = {
            CouponRead: 1,
            CouponEdit: 2,
            CouponDelete: 3,
            CouponCreate: 4,
            SaleRead: 5,
            SaleEdit: 6,
            SaleClientEmailEdit: 7,
            ContractRead: 8,
            ContractEdit: 9,
            ContractDelete: 10,
            ProductCreate: 11,
            ProductEdit: 12,
            EditGeneralProduct: 13,
            EditProductOffers: 14,
            EditProductPartners: 15,
            EditProductAffiliates: 16,
            EditProductIntegrations: 17,
            EditProductTracking: 18,
            EditProductTaxSetup: 19,
            LeadRead: 20,
            LeadEdit: 21,
            LinkRead: 22,
            LinkCreate: 23,
            LinkEdit: 24,
            LinkDelete: 25,
            CheckoutRead: 26,
            CustomCheckoutCreate: 27,
            CustomCheckoutRead: 28,
            CustomCheckoutEdit: 29,
            CustomCheckoutDelete: 30,
            AffiliateRead: 31,
            CenterNotasRead: 32,
            CenterNotasCreate: 33,
            CenterNotasEdit: 34,
            OpportunityProduct: 35
          }
          res.sort((a, b) => order[a.name] - order[b.name])
          this.permissions = res

          // Remove view checkouts
          const length = this.permissions.length;
          this.permissions.splice(length - 2, 1);

          if (this.allData.length !== 0) {
            this.name = this.allData.name
            var permissions = this.permissions
            var permissionsData = this.allData.sub_user_role_permission

            for (let i = 0; i < permissionsData.length; i++) {
              const myPermissions = permissionsData[i]
              
              for (let x = 0; x < permissions.length; x++) {
                const element = permissions[x]
                if (myPermissions.permission_id === element.id) {
                  permissions[x].check = true
                }
              }
            }

            this.permission = permissions
          }
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let permissions = this.permissions;
          let permission = permissions.filter((x) => x.check === true);
          if (permission.length) {
            var id_permissions = [];
            permission.forEach((element, index) => {
              id_permissions.push(element.id);
            });

            let data = {
              name: this.name,
              permission: id_permissions,
            };

            if (this.allData.length !== 0) {
              data.id = this.allData.id;
              serviceSubGroup.update(data).then((response) => {
                this.resolveResponse();
              });

              return;
            }

            serviceSubGroup.create(data).then((response) => {
              gtagSend('grupo_permissoes_criado', {
                  ID_Cliente: JSON.parse(localStorage.user).id || this.$store.getters.getProfile.id,
              });
              this.resolveResponse();
            });
          } else {
            this.$bvToast.toast(
              this.$t("seller.settings.modal_permission_group.text_2661"),
              {
                title: this.$t(
                  "seller.settings.modal_permission_group.text_2660"
                ),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      });
    },
    resolveResponse() {
      this.$bvToast.toast(
        this.$t("seller.settings.modal_permission_group.text_2662"),
        {
          title: this.$t("seller.settings.modal_permission_group.text_2660"),
          variant: "success",
          autoHideDelay: 5000,
          appendToast: true,
        }
      );
      this.$emit("close");
      this.reset();
      this.$root.$emit("bv::hide::modal", "modal-permission-group", "#btnShow");
    },
    openModal() {
      this.fetchPermissions()
    },
    transalePermissionsTitle(title) {
      switch (title) {
        case 'Visualizar vendas': return this.$t('seller.settings.modal_permission_group.text_758')
        case 'Visualizar Dashboard': return this.$t('seller.settings.modal_permission_group.text_759')
        case 'Listar produtos': return this.$t('seller.settings.modal_permission_group.text_760')
        case 'Visualizar links': return this.$t('seller.settings.modal_permission_group.text_761')
        case 'Visualizar cupons': return this.$t('seller.settings.modal_permission_group.text_762')
        case 'Editar cupons': return this.$t('seller.settings.modal_permission_group.text_763')
        case 'Deletar cupons': return this.$t('seller.settings.modal_permission_group.text_764')
        case 'Criar cupons': return this.$t('seller.settings.modal_permission_group.text_765')
        case 'Criar produtos': return this.$t('seller.settings.modal_permission_group.text_766')
        case 'Editar produtos': return this.$t('seller.settings.modal_permission_group.text_767')
        case 'Acesso a aba geral': return this.$t('seller.settings.modal_permission_group.text_768')
        case 'Acesso a aba cobranças': return this.$t('seller.settings.modal_permission_group.text_769')
        case 'Acesso a aba parceiros': return this.$t('seller.settings.modal_permission_group.text_770')
        case 'Acesso a aba de afiliados': return this.$t('seller.settings.modal_permission_group.text_771')
        case 'Acesso a aba de integrações': return this.$t('seller.settings.modal_permission_group.text_772')
        case 'Acesso a aba trackeamento': return this.$t('seller.settings.modal_permission_group.text_773')
        case 'Acesso a aba fiscal': return this.$t('seller.settings.modal_permission_group.text_774')
        case 'Visualizar Leads': return this.$t('seller.settings.modal_permission_group.text_775')
        case 'Editar Leads': return this.$t('seller.settings.modal_permission_group.text_776')
        case 'Pedir Reembolso': return this.$t('seller.settings.modal_permission_group.text_796')
        case 'Visualizar Contratos': return this.$t('seller.settings.modal_permission_group.text_797')
        case 'Editar Contratos': return this.$t('seller.settings.modal_permission_group.text_798')
        case 'Deletar Contratos': return this.$t('seller.settings.modal_permission_group.text_804')
        case 'Editar Central de Notas': return this.$t('seller.settings.modal_permission_group.text_808')
        case 'Adicionar Central de Notas': return this.$t('seller.settings.modal_permission_group.text_814')
        case 'Visualizar central de notas': return this.$t('seller.settings.modal_permission_group.text_806')
        case 'Criar na central de notas': return this.$t('seller.settings.modal_permission_group.text_807')
        case 'Editar na central de notas': return this.$t('seller.settings.modal_permission_group.text_808')
        case 'Deletar na central de notas': return this.$t('seller.settings.modal_permission_group.text_809')
        case 'Editar e-mail em detalhes da venda': return this.$t('seller.settings.modal_permission_group.text_810')
        case 'Criar link': return this.$t('seller.settings.modal_permission_group.text_819')
        case 'Editar link': return this.$t('seller.settings.modal_permission_group.text_820')
        case 'Deletar link': return this.$t('seller.settings.modal_permission_group.text_821')
        case 'Visualizar Checkouts': return this.$t('seller.settings.modal_permission_group.text_826')
        case 'Criar Checkouts Personalizados': return this.$t('seller.settings.modal_permission_group.text_843')
        case 'Visualizar Checkouts Personalizados': return this.$t('seller.settings.modal_permission_group.text_844')
        case 'Editar Checkouts Personalizados': return this.$t('seller.settings.modal_permission_group.text_845')
        case 'Deletar Checkouts Personalizados': return this.$t('seller.settings.modal_permission_group.text_846')
        case 'Visualizar Afiliados': return this.$t('seller.settings.modal_permission_group.text_829')
        default: return title
      }
    },
    transalePermissionsDescription(description) {
      switch (description) {
        case 'Visualizar Vendas': return this.$t('seller.settings.modal_permission_group.text_777')
        case 'Visualizar Dashboard': return this.$t('seller.settings.modal_permission_group.text_778')
        case 'Permissão para listar/filtrar produtos em outras telas': return this.$t('seller.settings.modal_permission_group.text_779')
        case 'Sub-usuário terá acesso a gerar novos links na tela de cupom': return this.$t('seller.settings.modal_permission_group.text_780')
        case 'Sub-usuário terá acesso a visualizar os cupons cadastrados': return this.$t('seller.settings.modal_permission_group.text_781')
        case 'Sub-usuário poderá editar todos os cupons de desconto e também ativar e desativar os cupons': return this.$t('seller.settings.modal_permission_group.text_782')
        case 'Sub-usuário poderá excluir qualquer um dos cupons de desconto': return this.$t('seller.settings.modal_permission_group.text_783')
        case 'Sub-usuário poderá criar novos cupons de desconto': return this.$t('seller.settings.modal_permission_group.text_784')
        case 'Sub-usuário terá permissão para criar um produto': return this.$t('seller.settings.modal_permission_group.text_785')
        case 'Sub-usuário terá permissão para editar um produto': return this.$t('seller.settings.modal_permission_group.text_786')
        case 'Sub-usuário terá permissão para acessar aba geral dentro da tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_787')
        case 'Sub-usuário terá permissão para acessar aba de cobranças dentro da tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_788')
        case 'Sub-usuário terá permissão para acessar aba de parceiros dentro da tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_789')
        case 'Sub-usuário terá permissão para acessar aba afiliados dentro da tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_790')
        case 'Sub-usuário terá permissão para acessar as abas entregas, GreennClub, Gdigital e Greenn Envios dentro da tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_791')
        case 'Sub-usuário terá permissão para acessar aba trackeamento dentro de avançado na tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_792')
        case 'Sub-usuário terá permissão para acessar aba fiscal dentro de avançado na tela de edição do produto': return this.$t('seller.settings.modal_permission_group.text_793')
        case 'Visualizar Leads': return this.$t('seller.settings.modal_permission_group.text_794')
        case 'Editar Leads': return this.$t('seller.settings.modal_permission_group.text_795')
        case 'Sub-usuário terá permissão para solicitar um reembolso': return this.$t('seller.settings.modal_permission_group.text_799')
        case 'Sub-usuário terá acesso a visualizar os contratos cadastrados': return this.$t('seller.settings.modal_permission_group.text_800')
        case 'Sub-usuário poderá editar todos os contratos e também ativar e desativar os contratos': return  this.$t('seller.settings.modal_permission_group.text_801')
        case 'Ler Sub-User': return this.$t('seller.settings.modal_permission_group.text_802')
        case 'Editar Sub-User': return this.$t('seller.settings.modal_permission_group.text_803')
        case 'Sub-usuário poderá excluir qualquer um dos contratos.': return this.$t('seller.settings.modal_permission_group.text_805')
        case 'Sub-usuário terá permissão para ver na central de notas': return this.$t('seller.settings.modal_permission_group.text_811')
        case 'Sub-usuário terá permissão para criar na central de notas': return this.$t('seller.settings.modal_permission_group.text_812')
        case 'Sub-usuário terá permissão para editar na central de notas': return this.$t('seller.settings.modal_permission_group.text_813')
        case 'Sub-usuário terá permissão para deletar na central de notas': return this.$t('seller.settings.modal_permission_group.text_814')
        case 'Sub-usuário terá permissão para editar email do cliente nos detalhes da venda': return this.$t('seller.settings.modal_permission_group.text_815')
        case 'Sub-usuário terá permissão para criar links': return this.$t('seller.settings.modal_permission_group.text_823')
        case 'Sub-usuário terá permissão para editar links': return this.$t('seller.settings.modal_permission_group.text_824')
        case 'Sub-usuário terá permissão para deletar links': return this.$t('seller.settings.modal_permission_group.text_825')
        case 'Sub-usuário terá permissão para visualizar checkouts personalizados': return this.$t('seller.settings.modal_permission_group.text_827')
        case 'Sub-usuário terá permissão para criar checkout personalizado': return this.$t('seller.settings.modal_permission_group.text_839')
        case 'Sub-usuário terá permissão para visualizar checkouts personalizados na tela de checkout': return this.$t('seller.settings.modal_permission_group.text_840')
        case 'Sub-usuário terá permissão para editar checkout personalizado': return this.$t('seller.settings.modal_permission_group.text_841')
        case 'Sub-usuário terá permissão para deletar checkout personalizado': return this.$t('seller.settings.modal_permission_group.text_842')
        case 'Sub-usuário terá permissão para visualizar produtos afiliados': return this.$t('seller.settings.modal_permission_group.text_828')
        default: return description;
      }
    }    
  }
}
</script>

<style lang="scss" scoped>
.grid-list-itens {
  display: grid;
  grid-template-columns: calc(100% - 150px) 150px;
  gap: 20px;
  margin-top: 30px;

  .itens {
    gap: 20px;
    align-items: flex-start;
    border-bottom: solid 1px #ededf0;
    padding-bottom: 10px;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #333333;
      margin-bottom: 5px;
      line-height: 1;
    }

    span {
      font-weight: 400;
      font-size: 13px;
      color: #81858e;
    }
  }
}

.ml-switch {
  margin-left: 35px;
}

h3 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
  font-weight: 300;
}

.grid-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-input {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 3px;

  .copy {
    border: none;
    color: #333;
    background: #f7f7f7;
    outline: none !important;
    font-weight: 600;
    height: auto;
    padding: 0 42px !important;
    border-radius: 5px;
    font-size: 14px;

    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
  }
}

.code {
  background: rgb(27, 26, 29);
  color: white;
  height: auto;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  border-radius: 3px;
}

.permissions {
  width: 100%;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
