<script setup>
import Multiselect from "vue-multiselect";
import { GrTag, GrSwitch } from "@/components/GrComponents";
import { ref, getCurrentInstance, toRefs, computed, onMounted, watch } from "vue";
import debounce from "lodash/debounce";

// Define propriedades recebidas pelo componente
const props = defineProps({
  value: {
    type: Object,
    default: () => ({
      scale: "",
      recovery_time: 0,
      active: 0,
      type: "",
    }),
  },
});

// Define eventos emitidos pelo componente
const emit = defineEmits(["update:value"]);

// Instância atual da aplicação para acessar métodos globais como `$t`
const vm = getCurrentInstance();
const { value } = toRefs(props);

// Estado local para controlar se está ativo
const isActive = ref(value.value.active === 1);

// Sincroniza mudanças em `props.value.active` com `isActive`
watch(
  () => value.value.active,
  (newVal) => {
    isActive.value = newVal === 1;
  }
);

// Sincroniza mudanças em `isActive` com `props.value.active`
watch(isActive, (newVal) => {
  value.value.active = newVal ? 1 : 0;
  emit("update:value", value.value);
});

// Opções de escala com traduções dinâmicas
const scaleOptions = ref([
  { label: vm.proxy.$t("opportunities.scales.HOURS"), value: "Horas", scale: "HOURS" },
  { label: vm.proxy.$t("opportunities.scales.MINUTES"), value: "Minutos", scale: "MINUTES" },
  { label: vm.proxy.$t("opportunities.scales.DAYS"), value: "Dias", scale: "DAYS" },
]);

// Estado local para escala e tempo de recuperação
const selectedScale = ref({});
const recoveryTime = ref(0);

// Atualiza a escala com debounce
const updateScale = debounce((scale) => {
  props.value.scale = scale.scale;
  emit("updateSettings");
}, 500);

// Atualiza o estado ativo com debounce
const updateActive = debounce((active) => {
  props.value.active = active ? 1 : 0;
  emit("updateSettings");
}, 500);

// Atualiza o tempo de recuperação com debounce
const updateRecoveryTime = debounce(() => {
  props.value.recovery_time = recoveryTime.value;
  emit("updateSettings");
}, 500);

// Mapeia tipos para informações
const getInformationType = (type) => {
  const typeMap = {
    PIX_GENERATED: vm.proxy.$t("opportunities.timekitten.after.pix"),
    BOLETO_GENERATED: vm.proxy.$t("opportunities.timekitten.after.boleto"),
    CARD_DECLINED: vm.proxy.$t("opportunities.timekitten.after.cartao"),
    CHECKOUT_ABANDONED: vm.proxy.$t("opportunities.timekitten.after.carrinho"),
  };
  return typeMap[type] || "";
};

// Mapeia tipos para nomes
const getOpportunityType = (type) => {
  const typeMap = {
    PIX_GENERATED: vm.proxy.$t("opportunities.timekitten.types.pix"),
    BOLETO_GENERATED: vm.proxy.$t("opportunities.timekitten.types.ticket"),
    CARD_DECLINED: vm.proxy.$t("opportunities.timekitten.types.card"),
    CHECKOUT_ABANDONED: vm.proxy.$t("opportunities.timekitten.types.checkout"),
  };
  return typeMap[type] || "";
};

// Configuração inicial ao montar o componente
onMounted(() => {
  selectedScale.value = scaleOptions.value.find((option) => option.scale === props.value.scale);
  recoveryTime.value = props.value.recovery_time;
});
</script>

<template>
  <div class="config-kitten border-bottom mt-4 pb-4">
    <div class="d-flex">
      <GrTag
        :customBackground="'var(--primary-10)'"
        class="tag-method"
        :class="!isActive ? 'inactive-kitten' : ''"
        theme="alternate"
      >
        {{ getOpportunityType(value.type) }}
      </GrTag>

      <div>
        <input
          type="number"
          class="input-number"
          v-model="recoveryTime"
          @input="updateRecoveryTime"
          :disabled="!isActive"
          :class="!isActive ? 'inactive-kitten' : ''"
        />
        <Multiselect
          class="select-kitten-product"
          :options="scaleOptions"
          v-model="selectedScale"
          @input="updateScale"
          track-by="scale"
          :allow-empty="false"
          :disabled="!isActive"
          :class="!isActive ? 'inactive-kitten' : ''"
          placeholder=""
          label="label"
          value="scale"
        />
      </div>
    </div>

    <div class="d-flex-switch">
      <span :class="isActive ? 'active-kitten' : 'inactive-kitten'">
        {{ getInformationType(value.type) }}
      </span>
      <b-form-checkbox
        size="lg"
        v-model="isActive"
        @change="updateActive"
        switch
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.config-kitten {
  width: 100%;
  display: flex;
  gap: var(--spacing-5);

  .d-flex {
    display: flex;
    gap: var(--spacing-8);

    div {
      display: flex;
      gap: var(--spacing-8);
    }
  }

  .d-flex-switch {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  span {
    font-size: var(--font-base);
    font-weight: var(--weight-semibold);
    line-height: var(--spacing-6);
    color: var(--gray-200);
    text-align: left;
  }
}

.input-number {
  width: var(--w-15) !important;
  height: var(--h-11) !important;
  background-color: var(--gray-10);
  font-weight: var(--weight-semibold);
  margin: 0 !important;
  padding: 10px !important;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :focus {
    outline: none !important;
    border: none !important;
  }
}

.tag-method {
  width: var(--w-32);
  height: var(--h-11);
  font-weight: var(--weight-semibold);
  display: flex;
  color: var(--primary) !important;
  align-items: center;
  justify-content: center;
}
.inactive-kitten {
  opacity: 0.4;
}
</style>
<style lang="scss">
.select-kitten-product {
  height: var(--h-11) !important;
  width: var(--w-30) !important;

  .multiselect__content {
    left: 0 !important;
    top: 47px !important;

    border-radius: 10px !important;
    border: 1px solid #ced4da !important;
  }

  .multiselect__option--selected {
    font-weight: 500 !important;
    &::after {
      display: none !important;
    }
  }

  .multiselect__input {
    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }

  .multiselect__option {
    &::after {
      display: none !important;
    }
  }

  .multiselect__tags {
    display: flex;
    min-width: var(--w-30) !important;
    background-color: var(--gray-10);
    overflow: hidden;
    text-align: center;
    padding: 12px 15px !important;
    height: var(--h-11) !important;
    .multiselect__single {
      font-weight: var(--weight-semibold) !important;
    }
  }
  .multiselect__select {
  }
}
</style>
