<script setup>
import { computed } from 'vue';

const props = defineProps({
  borderFocus: {
    type: String,
    default: '#00b07f',
  },
  borderHover: {
    type: String,
    default: '#00b07f',
  },
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Nome',
  },
  name: {
    type: String,
    default: 'name',
  },
  size: {
    type: String,
    default: 'md',
  },
  required: {
    type: Boolean,
    default: false,
  },
})

const internalValue = computed({
  get() {
    return props.modelValue
  },
  set(text) {
    emit('update:modelValue', text)
  },
})

const emit = defineEmits(['update:modelValue']);

</script>
<template>
  <div :data-requireq="props.required" :class="{ 'gr-required': props.required, [`gr-size-${props.size}`]: props.size }"
    class="gr-control">
    <textarea class="gr-input" :class="`gr-input-${props.size}`" v-model="internalValue" placeholder="none" required
      :name="props.name">
    </textarea>
    <label v-if="props.placeholder" for="name" class="gr-label">{{ props.placeholder }}</label>
  </div>
</template>

<style lang="scss" scoped>
.gr {
  &-control {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-bottom: 1.5rem;
  }

  &-required {
    .gr-input {
      border: 1px solid #a9100660 !important;

      &:focus-visible {
        outline: none !important;
        border: 1px solid var(--red-500) !important;
      }

      &:focus {
        &~.gr-label {
          color: var(--red-500) !important;
        }
      }

      &~.gr-label {
        color: #a9100660 !important;
      }

      &:hover {
        border: 1px solid var(--red-500) !important;
      }
    }
  }

  &-size {
    &-sm {
      height: var(--h-20);
    }

    &-md {
      height: var(--h-32);
    }

    &-lg {
      height: var(--h-44);
    }

    &-xl {
      height: var(--h-56);
    }
  }

  &-label {
    position: absolute;
    font-family: Plus Jakarta Sans;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px;
    color: #b2b6b6;
    background: white;
    transition: all 0.3s ease;
    //
    left: 1rem;
    top: 1rem;
    padding: 0 0.25rem !important;
    margin: 0 !important;
  }

  &-input {

    &-sm {
      height: var(--h-20) !important;
    }

    &-md {
      height: var(--h-32) !important;
    }

    &-lg {
      height: var(--h-44) !important;
    }

    &-xl {
      height: var(--h-56) !important;
    }

    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #e5e7e7;
    color: #000c0b;
    background: transparent;
    transition: all 0.3s ease-in-out;
    caret-color: #00b07f !important;
    //
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    resize: none;
    width: 100%;
    height: 48px;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;

    &::placeholder {
      opacity: 0;
      visibility: hidden;
      color: transparent;
    }

    &:hover {
        border-color: v-bind('borderHover || "#00b07f"') !important;
    }

    &:focus {
      outline: none !important;
      border-color: v-bind('borderFocus || "#009488"') !important; 

      &~.gr-label {
        font-family: Plus Jakarta Sans;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 16px;
        color: v-bind('borderFocus || "#00b07f"');
        transition: all 0.2s ease-in-out;
        //
        top: -0.55rem;
        left: 1rem;
        z-index: 5;
      }
    }

    &:not(:placeholder-shown).gr-input {
      &:not(:focus) {
        &~.gr-label {
          font-size: 12px !important;
          color: #000c0b;
          font-weight: 600 !important;
          transition: all 0.2s ease-in-out;
          //
          top: -0.75rem;
          left: 1rem;
          z-index: 9;
        }
      }
    }
  }
}
</style>