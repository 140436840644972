<script setup>
import { computed } from "vue";
import { ExportTitle } from "../base-components";
import { useI18n } from "@/utils/useI18n";

const i18n = useI18n();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const columnsMap = {
  lead: i18n.t("opportunities.export.exportReview.customers"),
  product: i18n.t("opportunities.export.exportReview.product"),
};

const columns = computed(() => {
  return props.data.relations.map((relation) => columnsMap[relation] || relation);
});
</script>

<template>
  <div>
    <ExportTitle> {{ $t("opportunities.export.exportReview.title") }} </ExportTitle>

    <section class="options">
      <h2>{{ $t("opportunities.export.exportReview.subTitle") }}</h2>

      <div>
        <p>{{ $t("opportunities.export.exportReview.email") }}</p>
        <span> {{ props.data.toEmail }} </span>
      </div>

      <div>
        <p>{{ $t("opportunities.export.exportReview.columns") }}</p>
        <span>
          {{ columns.join(", ") }}
        </span>
      </div>

      <div>
        <p>{{ $t("opportunities.export.exportReview.typeFile") }}</p>
        <span> .CSV </span>
      </div>
    </section>

    <div class="separator" />
  </div>
</template>

<style lang="scss" scoped>
h2 {
  font-size: 15px;
  font-weight: 600;
  text-decoration-skip-ink: none;
  margin-bottom: 20px;
  color: var(--text);  
}

.options {
  display: grid;
  gap: 15px;
  margin-top: 15px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--font-sm);
      font-weight: var(--weight-normal);
      color: var(--gray-200);
    }
    span {
      font-size: var(--font-sm);
      font-weight: var(--weight-medium);
      color: var(--text);
    }
  }
}

.separator {
  height: 1px;
  width: 100%;
  margin: 20px 0 10px 0;

  width: 100%;
  background-color: var(--gray-50);
}
</style>
