<script setup>
import { useStore } from "@/utils/useStore";
import { computed } from "vue";
import { money } from "@/utils/money";

const store = useStore();

const getOpportunitySelected = computed(
  () => store.getters["opportunities/getOpportunitySelected"]
);
</script>

<template>
  <section>
    <div>
      <p>{{ $t("opportunities.details.client") }}</p>
      <span>{{ getOpportunitySelected?.name }}</span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.emailClient") }}</p>
      <span>{{ getOpportunitySelected?.email }}</span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.phoneClient") }}</p>
      <span>{{ getOpportunitySelected?.cellphone }}</span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.productName") }}</p>
      <span>{{ getOpportunitySelected?.productName }}</span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.valueOpportunity") }}</p>
      <span>{{ money(getOpportunitySelected?.amount, "R$") }}</span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  display: grid;
  gap: 15px;
  margin: 20px 0 40px 0;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--font-sm);
      font-weight: var(--weight-normal);
      color: var(--gray-200);
    }
    span {
      font-size: var(--font-sm);
      font-weight: var(--weight-semibold);
      color: var(--text);
    }
  }
}
</style>
