<template>
  <div>
    <slot name="button" :openModal="openModal"> </slot>
    <b-modal
      :id="`affiliate-atribuition-${id}`"
      size="md"
      :title="$t('shared.text_1979')"
    >
      <b-container v-if="!loading">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="$t('shared.text_1980')" label-for="search">
              <b-form-input
                id="search"
                v-model="search"
                v-on:input="debounce"
                type="text"
                :class="{'heaven': isHeaven}"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="header-afili">
          <b-col cols="4">
            <b>{{ $t('shared.text_1981') }}</b>
          </b-col>

          <b-col cols="8">
            <b>{{ $t('shared.text_1982') }}</b>
          </b-col>
        </div>

        <div
          v-for="(item, index) in users"
          :key="item.id"
          v-show="index < 10"
          class="Affiliate-item"
          :class="{ 'active': active == index, 'heaven': isHeaven }"
          @click="select(item.id, index)"
        >
          <b-col cols="4">
            {{ nomeAfiliado(item.name) }}
          </b-col>

          <b-col cols="8">
            {{ item.email }}
          </b-col>
        </div>

        <b-row
          v-if="!loading && users.length == 0"
          class="table-body mt-4 justify-content-center"
        >
          <p class="nao-encontrado">
            {{ $t('shared.text_1983') }}
          </p>
        </b-row>
      </b-container>

      <div
        v-if="loading"
        class="table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>

      <template v-slot:modal-footer="{ cancel }">
        <BaseButton
          variant="link-info"
          @click="cancel()"
          class="mr-4"
          :disabled="loading"
        >
          {{ $t('shared.text_1984') }}
        </BaseButton>

        <div id="btn-atribuir">
          <BaseButton
            variant="black"
            @click="onSubmit"
            :disabled="!users.length || loading || active < 0"
          >
            {{ $t('shared.text_1985') }}
          </BaseButton>
        </div>

        <b-tooltip
          v-if="active < 0 && users.length"
          target="btn-atribuir"
          :title="$t('shared.text_1986')"
          placement="top"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>
import ProductAfilliate from "@/services/resources/ProductAfilliate";
import SaleService from "@/services/resources/SaleService";
import RecurrenceSubscriptionService from "@/services/resources/RecurrenceSubscriptionService";
import _ from "lodash";

const serviceUser = ProductAfilliate.build();
const serviceSale = SaleService.build();
const serviceRecurrenceSubscription = RecurrenceSubscriptionService.build();

export default {
  data() {
    return {
      search: "",
      loading: true,
      id: parseInt(Math.random() * 1000),
      usersGlobal: [],
      users: [],
      active: -1,
      id_submit: -1,
      user_id: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
    };
  },
  props: {
    product_id: {
      type: Number,
      default: -1,
    },
    from: {
      type: String,
      default: "",
    },
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    debounce: _.debounce(function () {
      this.users = this.usersGlobal.filter((user) => {
        const name = user.name.toLowerCase();
        const email = user.email.toLowerCase();
        if (name.includes(this.search) || email.includes(this.search)) {
          return user;
        }
      });
    }, 500),
    nomeAfiliado(nome) {
      const name = nome.split(" ");
      if (name.length > 1) {
        return `${name[0]} ${name[1]}`;
      } else {
        return name[0];
      }
    },
    openModal(sale_id) {
      this.id_submit = sale_id;
      this.$bvModal.show(`affiliate-atribuition-${this.id}`);
      this.fetchUsers();
    },
    select(id, index) {
      this.user_id = id;
      this.active = index;
    },

    fetchUsers() {
      this.loading = true;
      let data = {
        id: this.product_id,
      };

      this.usersGlobal = [];
      this.users = [];

      serviceUser
        .read(data)
        .then((response) => {
          [...response].forEach((item) => {
            if (item.affiliate_id) {
              this.usersGlobal.push(item.affiliate);
              this.users.push(item.affiliate);
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      if (this.from == "sale") {
        this.updateSale();
      } else if (this.from == "contract") {
        this.updateContract();
      }
    },
    updateSale() {
      this.loading = true;

      let data = {
        id: this.id_submit,
        affiliate_id: this.user_id,
      };

      serviceSale
        .update(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(
              this.$t('shared.text_1987'),{
                title: this.$t('shared.text_1989'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.$bvModal.hide(`affiliate-atribuition-${this.id}`);
            this.$emit("relist-sales");
            this.$emit("close");
          } else if (!response.success) {
            this.$bvToast.toast(this.$t('shared.text_1988'), {
              title: this.$t('shared.text_1989'),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide(`affiliate-atribuition-${this.id}`);
          }
          this.active = -1;
        })
        .catch((err) => {
          console.log(err);
          this.$bvModal.hide(`affiliate-atribuition-${this.id}`);
          this.active = -1;
        });
    },
    updateContract() {
      this.loading = true;

      let data = {
        id: this.id_submit,
        affiliate_id: this.user_id,
      };

      serviceRecurrenceSubscription
        .update(data)
        .then((response) => {
          this.$bvToast.toast(
            this.$t('shared.text_1987'),
            {
              title: this.$t('shared.text_1989'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.$bvModal.hide(`affiliate-atribuition-${this.id}`);
          this.$emit("relist-contracts");
          this.$emit("close");

          this.active = -1;
        })
        .catch((err) => {
          console.log(err);
          this.$bvModal.hide(`affiliate-atribuition-${this.id}`);
          this.active = -1;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.Affiliate-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;
  color: #81858e;
  font-size: 14px;

  &:hover {
    background: #f7f7f7;
  }
}
.Affiliate-item + .Affiliate-item {
  margin-top: 10px;
}

.active {
  font-weight: 600;
  background: #ededf0;
}
.header-afili {
  display: flex;
  margin: 30px 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>