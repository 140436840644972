<template>
  <div class="container">
    <div class="d-flex justify-content-between align-items-end">
      <BaseHeader :title="$t('views.seller.sales.text_1333')" />
    </div>

    <div class="container-table">
      <!-- Total Sales -->
      <div class="box-total" :class="{'heaven': isHeaven}" :style="loading2 ? 'display: block;' : ''" >
        <p class="total-vendas info" v-if="!loading2">
          ⚡️ {{ itens }} {{ $t("views.seller.sales.text_1334") }}
        </p>
        <div v-if="!loading2">
          <p class="total-vendas" :class="{'heaven': isHeaven}">
            {{ $t("views.seller.sales.text_1335") }}
            <span>
              {{ currency.currency_symbol }}
              {{ total ? formatMoney(total) : "0,00" }}
            </span>
          </p>
        </div>
        <!-- Loading -->
        <div v-if="!loading2"></div>
        <div v-if="loading2" class="d-flex justify-content-center">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>
      <!-- Filters -->
      <div class="header-table">
        <div class="flex-sale">
          <div class="d-flex align-items-center">
            <!-- Filter -->
            <b-tooltip
              target="filtro-1"
              :title="$t('views.seller.sales.text_1415')"
              placement="topright"
            />
            <b-button
              id="filtro-1"
              class="btn-table"
              @click="openFilters"
              :title="$t('views.seller.sales.text_1416')"
              :class="{'heaven': isHeaven}"
            >
              <img v-if="!isHeaven" src="@/assets/img/icons/filtro.svg" />
              <img v-else src="@/assets/img/icons-heaven/filtro.svg" />
            </b-button>
            <!-- Download -->
            <b-tooltip
              target="exportar-1"
              :title="$t('views.seller.sales.text_1417')"
              placement="topright"
            />
            <b-button
              @click="exportData"
              class="btn-table"
              :title="$t('views.seller.sales.text_1417')"
              id="exportar-1"
              ><img src="@/assets/img/icons/download.svg" alt=""
            /></b-button>
            <button
              class="notification-greenn-proft"
              v-if="client.width && client.width <= 768"
              @click="updateStatusAndFetchReports"
              :class="`button button--${buttonReportStatus}`"
            >
            <GrTag
              v-if="item.opportunity_status == 'WON' &&  flagOpportunities"
              customBackground="var(--yellow-500)"
              class="card-opportunities"
              theme="alternate"
            >
              Novidade
            </GrTag>
              {{ $t("views.seller.sales.text_1420") }}
              <img
                src="@/assets/img/icons/report-white.png"
                :class="`inverted--${buttonReportStatus}`"
                class="Pointer"
                style="margin-left: 29px; height: 16px; width: 12px"
              />
            </button>
          </div>
          <div class="d-flex align-items-center flex-acoes">
            <!-- Date Range Filter -->
            <div class="datas">
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                opens="right"
                :locale-data="localeDate"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="false"
                :ranges="dateRanges"
                :class="{'heaven': isHeaven}"
                @update="fetchSales(search, false, null, 'dataRange')"
              >
                <template
                  v-if="dateRange.startDate && dateRange.endDate"
                  v-slot:input="picker"
                  style="min-width: 350px"
                >
                  {{ picker.startDate | date }} - {{ picker.endDate | date }}
                </template>
                <template
                  v-else
                  v-slot:input
                  class="btn-table"
                  :title="$t('views.seller.sales.text_1418')"
                >
                  {{ $t("views.seller.sales.text_1419") }}
                </template>
              </date-range-picker>
            </div>
            <!-- Date Type Filter -->
            <div>
              <b-form-group label-for="type">
                <b-form-select
                  id="type"
                  v-model="type_date"
                  :options="types_list"
                  :class="{'heaven': isHeaven}"
                  @change="changedType"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <!-- Statistics -->
        <button 
          class="statistically-button"
          v-if="client.width && client.width > 768"
          @click="updateStatusAndFetchReports"
          :class="`button button--${buttonReportStatus}`"
        >
        <GrTag
          v-if="flagOpportunities"
          customBackground="var(--yellow-500)"
          class="card-opportunities"
          theme="alternate"
        >
          {{$t('opportunities.sales.new')}}
        </GrTag>
          {{ $t("views.seller.sales.text_1420") }}
          <img
            src="@/assets/img/icons/report-white.png"
            :class="`inverted--${buttonReportStatus}`"
            class="Pointer"
            style="margin-left: 29px; height: 16px; width: 12px"
          />
        </button>
        <!-- Search -->
        <div class="d-flex justify-content-center">
          <b-spinner v-show="loading3" label="Loading..."></b-spinner>
          <g-filter
            v-show="!loading3"
            v-model="search"
            :activeFields="[
              'sale_id',
              'client_name',
              'client_email',
              'client_document',
              'client_cellphone',
            ]"
            @input="clearSearch"
          />
        </div>
      </div>
      <!-- Revenues -->
      <div v-if="buttonReportStatus === 'black'" data-anima="right">
        <b-col cols="12">
          <h3 class="title-report" style="margin-bottom: 20px">
            {{ $t("views.seller.sales.text_1421") }}
          </h3>
        </b-col>
        <div class="grid-report">
          <div v-for="report in earnReport" :key="report.title">
            <report-item
              :payload="report"
              :loading="loading_reports"
              :error="errorReport"
              :key="report.name"
            />
          </div>
        </div>
      </div>
      <!-- Invoices -->
      <div v-if="buttonReportStatus === 'black'" data-anima="left" class="mb-3">
        <b-col cols="12">
          <h3
            class="title-report title-report-margin-top"
            style="margin-bottom: 20px"
          >
            {{ $t("views.seller.sales.text_1337") }}
          </h3>
        </b-col>
        <div class="grid-report">
          <div v-for="report in invoiceReport" :key="report.title">
            <report-item
              :payload="report"
              :loading="loading_reports"
              :error="errorReport"
              :key="report.name"
            />
          </div>
        </div>
      </div>

      <!-- Table Header -->
      <b-row class="Table-header mb-1">
        <b-col cols="1" class="d-none d-md-block">
          # {{ $t("views.seller.sales.text_1338") }}
        </b-col>

        <b-col cols="5" md="2" class="">
          {{ $t("views.seller.sales.text_1422") }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t("views.seller.sales.text_1423") }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block"
          >{{ $t("views.seller.sales.text_1339") }}
        </b-col>

        <b-col cols="2" class="d-none d-md-block">
          {{ $t("views.seller.sales.text_1340") }}
        </b-col>

        <b-col cols="4" md="2" class="">
          {{ $t("views.seller.sales.text_1341") }}
        </b-col>

        <b-col cols="3" md="1" class="">
          {{ $t("views.seller.sales.text_1424") }}
        </b-col>
      </b-row>

      <!-- Table Body -->
      <template v-if="!loading && sales.length > 0">
        <b-row
          class="Table-body"
          v-for="item in sales"
          :key="item.id"
          :class="getStatus(item.status, item.opportunity_status).class"
        >
          <!-- Id -->
          <b-col
            cols="1"
            class="d-none d-md-block"
            id="id_venda"
            v-b-tooltip="{
              title: getStatus(item.status).text,
              placement: 'bottom',
            }"
          >
            # {{ item.id }}
          </b-col>
          <!-- Product Name -->
          <template v-if="!item.proposal">
            <b-col cols="5" md="2" class="">
              <!-- Offer Name -->
              <div class="sale-title">
                <div 
                    v-if="item.is_smart_sale === 1" 
                    class="hover-smart-icon"
                    v-b-tooltip="{
                      title: $t('views.contracts.text_2205'),
                      placement: 'left'
                    }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg" 
                      width="18" height="18" 
                      viewBox="0 0 256 256"
                    >
                      <path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.55,87.55,0,0,1-33.64,69.21A16.24,16.24,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16,16,0,0,0-6.23-12.66A87.59,87.59,0,0,1,40,104.49C39.74,56.83,78.26,17.14,125.88,16A88,88,0,0,1,216,104Zm-16,0a72,72,0,0,0-73.74-72c-39,.92-70.47,33.39-70.26,72.39a71.65,71.65,0,0,0,27.64,56.3A32,32,0,0,1,96,186v6h64v-6a32.15,32.15,0,0,1,12.47-25.35A71.65,71.65,0,0,0,200,104Zm-16.11-9.34a57.6,57.6,0,0,0-46.56-46.55,8,8,0,0,0-2.66,15.78c16.57,2.79,30.63,16.85,33.44,33.45A8,8,0,0,0,176,104a9,9,0,0,0,1.35-.11A8,8,0,0,0,183.89,94.66Z">
                      </path>
                    </svg>
                  </div>
                <div
                  class="Table-body-title"
                  v-b-tooltip="{
                    title: setName(item),
                    placement: 'top',
                  }"
                >
                  {{
                    setName(item) && setName(item).length > maxLength
                      ? setName(item).substr(0, maxLength) + "..."
                      : setName(item)
                  }}
                </div>
              </div>
              <div class="offer-name">
                {{ item.offer ? $t('views.contracts.text_2203')+": " + item.offer.name : "" }}
              </div>
            </b-col>
          </template>
          <!-- Proposal Name -->
          <template v-if="item.proposal">
            <b-col cols="3" md="2" class="">
              <div
                class="Table-body-title"
                v-b-tooltip="{
                  title: item.proposal.name,
                  placement: 'top',
                }"
              >
                {{
                  item.proposal.name && item.proposal.name.length > maxLength
                    ? item.proposal.name.substr(0, maxLength) + "..."
                    : item.proposal.name
                }}
              </div>
            </b-col>
          </template>
          <!-- Client -->
          <b-col v-if="item.client"
            cols="2"
            class="d-none d-md-block dados-cliente"
          >
            <!-- Name -->
            <div>
              {{
                item.client.name && item.client.name.length > maxLength
                  ? item.client.name.substr(0, maxLength) + "..."
                  : item.client.name
              }}
            </div>
            <!-- Email -->
            <div
              v-if="item.client"
              :id="item.client.email"
              v-b-tooltip="{
                title: item.client.email,
                placement: 'top',
              }"
            >
              {{
                item.client.email && item.client.email.length > 20
                  ? item.client.email.substr(0, 20) + "..."
                  : item.client.email
              }}
            </div>
            <!-- Cellphone -->
            <div>
              <a
                :href="formatTel(item.client.cellphone)"
                target="_blank"
                class="whats-cliente"
                :class="{'heaven': isHeaven}"
                ><font-awesome-icon :icon="['fab', 'whatsapp']" />{{
                  item.client.cellphone
                }}</a
              >
            </div>
          </b-col>
          <b-col v-if="!item.client"
            cols="2"
            class="d-none d-md-block dados-cliente"
          >
          </b-col>
          <!-- Creation Data -->
          <b-col v-if="item.created_at" cols="2" class="d-none d-md-block">
            {{ item.created_at | datetime }}
          </b-col>
          <!-- Payment Data -->
          <b-col v-if="item.paid_at" cols="2" class="d-none d-md-block">
            {{ item.paid_at | datetime }}
          </b-col>
          <b-col v-if="!item.paid_at"
            cols="2"
            class="d-none d-md-block"
          ></b-col>
          <!-- Method / Amount -->
          <b-col cols="4" md="2">
            <template v-if="item.method === 'FREE'">
              <span>{{ $t('seller.sales.text_2659') }}</span>
            </template>
            <template v-else>
              <!-- Method -->
              <span class="flex">
                <font-awesome-icon v-if="item.method != 'TWO_CREDIT_CARDS'"
                  :icon="getIconMethod(item.method)"
                  class="icon-gray"
                  :id="`method-icon-${item.id}`"
                />
                <b-tooltip
                  :target="`method-icon-${item.id}`"
                  :title="getMethod(item.method)"
                  placement="left"
                />
                <!-- Two Credit Cards -->
                <font-awesome-icon v-if="item.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray"
                  :id="`method-icon-${item.id}`"
                />
                <font-awesome-icon v-if="item.method == 'TWO_CREDIT_CARDS'"
                  icon="credit-card"
                  class="icon-gray TWO_CREDIT_CARDS"
                />
                <!-- Bump -->
                <font-awesome-icon v-if="item.bump_id"
                  icon="donate"
                  class="icon-gray ml-2"
                  :id="`bump-sale-${item.id}`"
                />
                <b-tooltip v-if="item.bump_id"
                  :target="`bump-sale-${item.id}`"
                  title="ORDER BUMP"
                  placement="left"
                />
                <!-- Coupom -->
                <font-awesome-icon v-if="item.coupon_id"
                  icon="receipt"
                  class="icon-gray ml-2"
                  :id="`cupom-sale-${item.id}`"
                />
                <b-tooltip v-if="item.coupon_id"
                  :target="`cupom-sale-${item.id}`"
                  :title="$t('views.seller.sales.text_1425')"
                  placement="left"
                />
              </span>
              <!-- Amount -->
               <!-- Falta o if da oportunidade recuperada  -->
              <GrTag v-if="item.opportunity_status && item.opportunity_status == 'WON' && item.status == 'paid'" customBackground="var(--yellow-500)" class="card-converted">
                <p>
                {{$t('opportunities.sales.converted')}}
                </p>
              </GrTag>
              <span v-if="item.balance" class="valor-venda">
                {{ currency.currency_symbol }} {{ formatMoney(+item.balance) }}
              </span>
              <span v-else class="valor-venda">
                {{ currency.currency_symbol }} 0,00
              </span>
            </template>
          </b-col>
          <!-- View -->
          <b-col cols="3" md="1">
            <div class="Table-body-action" @click="openPanel(item.id)">
              <img src="@/assets/img/icons/eye.svg" />
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Pagination -->
      <b-row>
        <b-col>
          <Paginate
            v-if="sales.length > 0 && !initExport"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            :items-per-page="pagination.perPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </b-col>
      </b-row>
      <!-- Loading -->
      <div
        v-if="(loading && !sales.length) || (loading && initExport)"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>
      <!-- Feedback Message -->
      <b-row
        v-if="!loading && !sales.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">{{ $t("views.seller.sales.text_1342") }}</p>
      </b-row>

      <!-- Modals -->
      <FiltersModal @filter="filter" />
      <ExportModal :filters="exportFilter" />
      <SalePreview  :updateQueryParams="setQueryParams" ref="salePreview" @update-sales="fetchSales"/>
    </div>
    <CallEvaluation />
  </div>
</template>

<script>
import SaleService from "@/services/resources/SaleService"
import SaleTotalService from "@/services/resources/SaleTotalService"
import Paginate from "@/components/shared/Paginate"
import FiltersModal from "@/components/Seller/Sales/modals/filters"
import ExportModal from "@/components/Seller/Sales/modals/export"
import DateRangePicker from "vue2-daterange-picker"
import Money from "@/mixins/money"
import moment from "moment-timezone"
import SalePreview from "@/components/shared/SalePreview"
import _ from "lodash"
import { iconMethod } from "@/helpers.js"
import Cookies from "js-cookie"
import ReportItem from "@/components/Seller/Sales/ReportItem"
import SelectCurrency from "@/components/SelectCurrency.vue"
import { EventBus } from "@/main.js"
import GFilter from "@/components/GFilter.vue"
import gtagSend from '@/utils/gtagSend';
import { GrTag } from "@/components/GrComponents";
const service = SaleService.build()
const totalService = SaleTotalService.build()

export default {
  name: "ListagemVendas",
  mixins: [Money],
  components: {
    Paginate,
    FiltersModal,
    ExportModal,
    DateRangePicker,
    SalePreview,
    ReportItem,
    SelectCurrency,
    GFilter,
    GrTag
  },
  data() {
    return {
      loading2: false,
      loading3: true,
      earnReport: 6,
      invoiceReport: 6,
      errorReport: false,
      buttonReportStatus: "white",
      exportFilter: [],
      initExport: false,
      sales: [],
      sale_id: -1,
      search: {},
      loading: true,
      loading_reports: true,
      total: 0,
      itens: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10
      },
      filters: {},
      type_date: "created_at",
      types_list: [
        { value: "created_at", text: this.$t("views.seller.sales.text_1426") },
        { value: "paid_at", text: this.$t("views.seller.sales.text_1427") },
      ],
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      dateRange: {
        startDate: moment().startOf("day"),
        endDate: moment().endOf("day"),
      },
      columns: [
        { key: "id", label: "Fatura" },
        { key: "product", label: "Produto" },
        { key: "client", label: "Cliente" },
        { key: "created_at", label: "Data de criação" },
        { key: "paid_at", label: "Data de Pagamento" },
        { key: "metas", label: "Metas" },
        { key: "balance", label: "Valor líquido" },
        { key: "_visualize", label: "Visualizar" },
      ],
      client: { width: 0 },
      currency: {
        id: 1,
        name: "Real Brasileiro",
        currency: "BRL",
        currency_symbol: "R$",
      },
      validCoin: true,
      filtroChanged: true,
      user_timezone: moment.tz.guess(),
      settingsProft: null,
      getWinGreennProft: null,
      getLosOfGreennSoft: null,
    }
  },
  watch: {
    dateRange(newDateRange, oldDateRange) {
      if(newDateRange !== oldDateRange) {
        this.filtroChanged = true
      }
    },
    type_date(newTypeDate, oldTypeDate) {
      if(newTypeDate !== oldTypeDate) {
        this.filtroChanged = true
      }
    },
    search(newSearch, oldSearch) {
      if(newSearch !== oldSearch) {
        this.$route.query.search = newSearch
        this.filtroChanged = true
      }
    }
  },
  computed: {
    flagOpportunities ()  {      
      return this.$store.getters['getFlags'].includes('opportunity_settings')
    },
    valueProft() {
      return this.settingsProft ? this.getWinGreennProft : this.getLosOfGreennSoft;
    },
    active() {
      return this.$route.name;
    },
    isSeller() {
      return this.$store.getters.isSeller;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 27;
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t("locale_date.today")]: [today, today],
        [this.$t("locale_date.yesterday")]: [yesterday, yesterday],
        [this.$t("locale_date.last_week")]: [lastWeek, today],
        [this.$t("locale_date.current_month")]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t("locale_date.last_month")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("locale_date.every_period")]: [new Date(2020, 0, 1), new Date(today)],
      };
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    formatObject(object) {
      const newObject = {};
      for (const [key, value] of Object.entries(object)) {
        if (value !== null && value !== undefined && value !== '') {
          newObject[key] = value;
        }
      }
      return newObject;
    },
    clearSearch(newFields) {
      if (!newFields.sale_id) {
        this.search.sale_id = "";
        let sales_filters = JSON.parse(localStorage.sales_filters);
        delete sales_filters.sale_id;
        localStorage.sales_filters = JSON.stringify(sales_filters);
      }
      if (!newFields.client_name) {
        this.search.client_name = "";
        let sales_filters = JSON.parse(localStorage.sales_filters);
        delete sales_filters.client_name;
        localStorage.sales_filters = JSON.stringify(sales_filters);
      }
      if (!newFields.client_email) {
        this.search.client_email = "";
        let sales_filters = JSON.parse(localStorage.sales_filters);
        delete sales_filters.client_email;
        localStorage.sales_filters = JSON.stringify(sales_filters);
      }
      if (!newFields.client_document) {
        this.search.client_document = "";
        let sales_filters = JSON.parse(localStorage.sales_filters);
        delete sales_filters.client_document;
        localStorage.sales_filters = JSON.stringify(sales_filters);
      }
      this.fetchSales(this.search, false, null, 'search');
    },
    resolveSearch() {
      if (this.$route.query.sale_id) {
        this.search.sale_id = this.$route.query.sale_id;
      }
      if (this.$route.query.client_name) {
        this.search.client_name = this.$route.query.client_name;
      }
      if (this.$route.query.client_email) {
        this.search.client_email = this.$route.query.client_email;
      }
      if (this.$route.query.client_document) {
        this.search.client_document = this.$route.query.client_document;
      }
    },
    setName(item) {
      return item.product ? item.product.name : item.invoices[0].product.name;
    },
    updateStatusAndFetchReports() {
      if (this.buttonReportStatus === "white") {
        this.buttonReportStatus = "black";
        this.fetchReports(this.search);
      } else if (this.buttonReportStatus === "black") {
        this.buttonReportStatus = "white";
      }
    },
    fetchReports(search = null, exportData = false, dados_local = null) {
      this.loading_reports = true;
      search = this.search;

      let data = {};
      if (dados_local) {
        data = dados_local;
      } else {
        data = {
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ["paid", "waiting_payment"],
          currency_id: this.isHeaven ? 2 : 1,
        };
      }


      if (search) {
        Object.keys(search)
        .forEach((key) => {
          if (search[key].length > 0) {
            data[key] = search[key];
          }
        })
      }
      delete data.search;

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment(this.dateRange.startDate)
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate)
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach((key) => {
        (this.filters[key] == null || this.filters[key].length === 0) &&
          delete this.filters[key];
      });
      data = Object.assign(data, this.filters);

      this.exportFilter = { ...data, ...this.search };

      data.date_start = moment(this.dateRange.startDate)
        .format("YYYY-MM-DDT00:00:00-03:00");
      data.date_end = moment(this.dateRange.endDate)
        .format("YYYY-MM-DDT23:59:59-03:00");

      const objectDataCleared = this.formatObject(data);

      service
        .search({ ...objectDataCleared, url: "/report" })
        .then((response) => {
          const typeEarns = [
            {
              name: this.$t("type_earns.total_sales"),
              slug_income: "total_sales",
              icon: "moneyReport",
              icon_color: "blue",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
            {
              name: this.$t("type_earns.total_balance"),
              slug_income: "total_balance",
              icon: "earnUp",
              icon_color: "green",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
            {
              name: this.$t("type_earns.total_refund"),
              slug_income: "total_refund",
              icon: "earnDown",
              icon_color: "red",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
            {
              name: this.$t("type_earns.chargedback"),
              slug_income: "total_chargedback",
              icon: "cardReport",
              icon_color: "grey",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
            {
              name: this.$t("type_earns.total_coproducer"),
              slug_income: "total_coseller",
              icon: "co-produtor",
              icon_color: "grey",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
            {
              name: this.$t("type_earns.total_affiliate"),
              slug_income: "total_affiliate",
              icon: "affiliate",
              icon_color: "grey",
              coin: this.isHeaven ? '$' + ' ' : 'R$' + ' ',
            },
          ];

          if(this.flagOpportunities){
            typeEarns[typeEarns.length - 1] = {
              name: "greenn-proft",
            }
          }
          const typeInvoices = [
            {
              name: this.$t("type_invoices.total"),
              slug_invoice: "total",
              slug_percent: false,
              icon: "documentReport",
              icon_color: "blue",
            },
            {
              name: this.$t("type_invoices.paid"),
              slug_invoice: "paid",
              slug_percent: "paid",
              icon: "checkReport",
              icon_color: "green",
            },
            {
              name: this.$t("type_invoices.open"),
              slug_invoice: "open",
              slug_percent: "open",
              icon: "lessReport",
              icon_color: "yellow",
            },
            {
              name: this.$t("type_invoices.unpaid"),
              slug_invoice: "unpaid",
              slug_percent: "unpaid",
              icon: "lessReport",
              icon_color: "yellow",
            },
            {
              name: this.$t("type_invoices.refunded"),
              slug_invoice: "refunded",
              slug_percent: "refunded",
              icon: "earnDown",
              icon_color: "red",
            },
            {
              name: this.$t("type_invoices.chargedback"),
              slug_invoice: "chargedback",
              slug_percent: "chargedback",
              icon: "cardReport",
              icon_color: "grey",
            },
          ];
          let earns = [];
          let invoices = [];
          typeEarns.map((type) => {
            earns.push({
              ...type,
              value: response.incomes[type.slug_income]
                ? response.incomes[type.slug_income]
                : 0,
            });
          });
          typeInvoices.map((type) => {
            invoices.push({
              ...type,
              value: response.invoices.count[type.slug_invoice],
              percent: type.slug_percent
                ? response.invoices.percent[type.slug_invoice]
                : false,
            });
          });
          this.earnReport = earns;
          this.invoiceReport = invoices;
          this.errorReport = false;
        })
        .catch((err) => {
          console.error(err);
          this.errorReport = true;
        })
        .finally(() => {
          this.getProfit();
        });
    },
    async getProfit(){
      if (!this.settingsProft) {
        const settingsRequest = await this.$store.dispatch(
          'opportunities/getProftSettings',
          this.$store.getters['getProfile'].id
        );
        if (typeof settingsRequest == 'object' && settingsRequest.hasOwnProperty('value')) {
          await this.$store.dispatch('opportunities/getWinOfGreennSofyValue');
        }
      } else if (this.getLosOfGreennSoft === '') {
        await this.$store.dispatch('opportunities/getLossOfGreennSofyValue');
      }

      this.loading_reports = false;
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    formatTel(tel) {
      if (tel) {
        if (tel.toString().indexOf("+55") > -1) {
          var tell = tel.replace(/[() -]/g, "");
          if (tell[0] === "+") {
            tell = tell.substring(1);
          }
          return `https://api.whatsapp.com/send?phone=${tell}`;
        } else {
          return `https://api.whatsapp.com/send?phone=55${tel.replace(
            /[() -]/g,
            ""
          )}`;
        }
      }
    },
    getStatus(status, recovered) {      
      if(status) {
        if(status.toLowerCase() === 'paid' && recovered == "WON" && this.flagOpportunities) {
          return { text: this.$t("status_sales.paid"), class: 'is-star' }
        }
        switch (status.toLowerCase()) {
          case "processing":
            return { text: this.$t("status_sales.processing"), class: "is-light-blue" }
          case "authorized":
            return { text: this.$t("status_sales.authorized"), class: "is-light-blue" }
          case "paid":
            return { text: this.$t("status_sales.paid"), class: this.isHeaven ? "is-green heaven" : "is-green" }
          case "refunded":
            return { text: this.$t("status_sales.refunded"), class: "is-red" }
          case "waiting_payment":
            return { text: this.$t("status_sales.waiting_payment"), class: "is-warning" }
          case "pending_approbation":
            return { text: this.this.$t("status_sales.pending_approbation"), class: "is-light-blue" }
          case "pending_refund":
          case "refund_pending":
            return { text: this.$t("status_sales.refund_pending"), class: "is-warning" }
          case "refused":
            return { text: this.$t("status_sales.refused"), class: "is-red" }
          case "chargedback":
            return { text: this.$t("status_sales.chargedback"), class: "is-warning" }
          case "delayed":
            return { text: this.$t("status_sales.delayed"), class: "is-warning" }
          default:
            return { text: status, class: "is-black" }
        }
      } else return { text: status, class: "is-black" }
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    changedType() {
      // chamando dessa forma para nao
      // passar o event como parametro
      this.filtroChanged = true
      this.fetchSales(this.search, false, null, 'changedType')
    },
    getCoSellerAmount(item) {
      if (item.product.co_sellers) {
        for (let i = 0; i < item.product.co_sellers.length; i++) {
          if (item.product.co_sellers[i].user_id == this.userID) {
            const percentage = item.product.co_sellers[i].percentage;
            return ((item.amount - item.fee) * percentage) / 100;
          }
        }
      }

      return 0;
    },
    openPanel(sale_id) {
      this.sale_id = sale_id;
      this.$router.push({ query: { s_id: this.sale_id } }).catch(() => {});

      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id);
        this.loading = false;
      });
    },
    openFilters() {
      this.$bvModal.show("sales-filters");
    },
    exportData() {
      this.$bvModal.show("sales-export");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.$route.query.page = page
      this.fetchSales(this.search, false, null, 'paginate');
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchSales(this.search);
    },
    filter(filters) {
      delete filters.date_end
      delete filters.date_start
      this.filters = filters
      this.pagination.currentPage = 1
      this.filtroChanged = true
      this.fetchSales(this.search, false, null)
    },
    fetchSales: _.debounce(function (search = null, exportData = false, dados_local = null, filterType = null) {
      search = this.search;
      if(typeof this.filters.method === 'string' && this.filters.method != '') this.filters.method = [this.filters.method]
      if(typeof this.filters.products === 'string' && this.filters.products != '') this.filters.products = [this.filters.products]
      if(typeof this.filters.status === 'string' && this.filters.status != '') this.filters.status = [this.filters.status]

      if (typeof this.$route.query.s_id == "undefined") {
        this.$router.replace({
          query: {
            date_start: moment(this.dateRange.startDate, ["MM-DD-YYYY", moment.ISO_8601]).format("MM-DD-YYYY"),
            date_end: moment(this.dateRange.endDate, ["MM-DD-YYYY", moment.ISO_8601]).format("MM-DD-YYYY"),
            ...this.filters,
            ...this.search
          },
        }).catch(() => {})
      }

      this.loading = true
      this.loading3 = true
      this.pagination.totalPages = 1

      if (this.buttonReportStatus === "black") {
        this.fetchReports(search);
      }

      if (!this.filters.date_start) {
        this.filters.date_start = moment(this.dateRange.startDate)
          .tz(this.user_timezone)
          .format("YYYY-MM-DDTHH:mm:ssZ");
      }
      if (!this.filters.date_end) {
        this.filters.date_end = moment(this.dateRange.endDate)
          .tz(this.user_timezone)
          .format("YYYY-MM-DDTHH:mm:ssZ");
      }

      let data = {}
      if (dados_local) {
        data = {
          date_start: dados_local.date_start,
          page: dados_local.page,
          per_page: dados_local.per_page,
          status: dados_local.status,
          type: dados_local.type,
          currency_id: this.isHeaven ? 2 : 1,
        }
      } else {
        data = Object.assign({
          page: this.pagination.currentPage,
          per_page: this.pagination.perPage,
          type: this.type_date,
          status: ["paid", "waiting_payment"],
          currency_id: this.isHeaven ? 2 : 1,
        },{});
      }     

      if (!exportData) {
        this.pagination.totalPages = 1
        this.sales = []
        if(this.filtroChanged) this.total = 0
        this.itens = 0
      }

      if (search) {
        Object.keys(search)
        .forEach((key) => {
          if (search[key].length > 0) {
            data[key] = search[key];
          }
        })
      }
      delete data.search;

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz(this.user_timezone)
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
          .tz(this.user_timezone)
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters).forEach(
        (key) =>
        (this.filters[key] == null || this.filters[key].length === 0) &&
        delete this.filters[key]
        );
        data = Object.assign(data, this.filters)

      this.exportFilter = { ...data, ...this.search }

      if(this.dateRange){

        this.exportFilter.date_start = moment(this.dateRange.startDate).tz(this.user_timezone)          
          .format("YYYY-MM-DD 00:00:00Z");
        this.exportFilter.date_end = moment(this.dateRange.endDate).tz(this.user_timezone)          
          .format("YYYY-MM-DD 23:59:59Z");
      }

      data.date_start = moment(this.dateRange.startDate).tz(this.user_timezone).format("YYYY-MM-DDT00:00:00-03:00");
      data.date_end = moment(this.dateRange.endDate).tz(this.user_timezone).format("YYYY-MM-DDT23:59:59-03:00");
      data.per_page = this.pagination.perPage;

      if(filterType === 'changedType' || filterType === 'dataRange') {
        data.page = 1
        this.pagination.currentPage = 1
      }
      if(filterType === 'changedType') {
        data.type = this.type_date
        this.$route.query.type_date = this.type_date
        this.filters.type_date = this.type_date
        this.filters.type = this.type_date
      }
      if(filterType === 'paginate') {
        data.page = this.pagination.currentPage
        data.type = this.type_date
      }
      if(filterType === 'search') {
        if(!search) {
          delete data.search
          delete this.$route.query.search
          delete this.filters.search
        } else {
          Object.keys(search)
            .forEach((key) => {
              if (search[key].length > 0) {
                data[key] = search[key];
              }
            })
        }
        data.page = 1
        this.pagination.currentPage = 1
      }

      service
        .search(data)
        .then(response => {
          // salvando pesquisa no localStorage
          this.saveSearch(data);
          this.sales = response.data.data
          this.pagination.totalPages = response.data.last_page
          this.itens = response.data.total
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loading = false
          this.loading3 = false
          this.fetchTotal(data)
        })
    }, 500),
    getMetas(metas) {
      var result = [];
      if (metas.length) {
        metas.forEach((element) => {
          result.push(element.meta_key + "=" + element.meta_value);
        });
      }

      return result.join("&");
    },
    searchByFilter(dados_filter) {
      this.type_date = dados_filter.type;
      this.dateRange.startDate = this.decodeDateFilter(dados_filter.date_start);
      this.dateRange.endDate = this.decodeDateFilter(dados_filter.date_end);
      this.fetchSales(null, false, dados_filter);
    },
    decodeDateFilter(date) {
      switch (date) {
        // hoje
        case this.$t("locale_date.today_lowercase"): {
          return moment();
        }
        // ontem
        case this.$t("locale_date.yesterday_lowercase"): {
          return moment().subtract(1, "days");
        }
        // uma semana atras
        case this.$t("locale_date.one_week"): {
          return moment().subtract(7, "days");
        }
        // primeiro dia do mes atual
        case this.$t("locale_date.first_day_current_month"): {
          return moment().startOf("month");
        }
        // ultimo dia do mes atual
        case this.$t("locale_date.last_day_current_month"): {
          return moment().endOf("month");
        }
        // primeiro dia do ANO atual
        case this.$t("locale_date.first_day_year"): {
          return moment().startOf("year");
        }
        // ultimo dia do ANO atual
        case this.$t("locale_date.last_day_year"): {
          return moment().endOf("year");
        }
        // primeiro dia do mes passado
        case this.$t("locale_date.first_day_last_month"): {
          return moment().startOf("month").subtract(1, "month");
        }
        // ultimo dia do mes passado
        case this.$t("locale_date.last_day_last_month"):
          return moment().endOf("month").subtract(1, "month").add(1, "days");
        default: {
          return date;
        }
      }
    },
    saveSearch(dados_pesquisa) {
      delete dados_pesquisa.sale_id;
      delete dados_pesquisa.client_name;
      delete dados_pesquisa.client_email;
      delete dados_pesquisa.client_document;
      dados_pesquisa.date_start = this.formatDateFilter(dados_pesquisa.date_start);
      dados_pesquisa.date_end = this.formatDateFilter(dados_pesquisa.date_end);
      window.localStorage.sales_filters = JSON.stringify(dados_pesquisa);
    },
    formatDateFilter(date) {
      switch (date) {
        // hoje
        case moment().format("YYYY-MM-DD"): {
          return this.$t("locale_date.today_lowercase");
        }
        // ontem
        case moment().subtract(1, "days").format("YYYY-MM-DD"): {
          return this.$t("locale_date.yesterday_lowercase");
        }
        // uma semana atras
        case moment().subtract(7, "days").format("YYYY-MM-DD"): {
          return this.$t("locale_date.one_week");
        }
        // primeiro dia do mes atual
        case moment().startOf("month").format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_current_month");
        }
        // ultimo dia do mes atual
        case moment().endOf("month").format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_current_month");
        }
        // primeiro dia do ANO atual
        case moment().startOf("year").format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_year");
        }
        // ultimo dia do ANO atual
        case moment().endOf("year").format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_year");
        }

        // primeiro dia do mes passado
        case moment()
          .startOf("month")
          .subtract(1, "month")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.first_day_last_month");
        }
        // ultimo dia do mes passado
        case moment().endOf("month").subtract(1, "month").format("YYYY-MM-DD"):
        case moment()
          .endOf("month")
          .subtract(1, "month")
          .add(1, "days")
          .format("YYYY-MM-DD"): {
          return this.$t("locale_date.last_day_last_month");
        }
        default: {
          return date;
        }
      }
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (!dados.find((x) => x.name === "DashboardRead")) {
            if (!dados.find((x) => x.name === "SaleRead")) {
              this.$router.push("/cupom");
            }
          }
          if (dados.find((x) => x.name === "DashboardRead")) {
            if (!dados.find((x) => x.name === "SaleRead")) {
              this.$router.push("/dashboard");
            }
          }
        }
      }
    },
    updateCoin(coin) {
      if (this.validCoin) {
        // this.currency = coin;
        this.pagination.currentPage = 1;
        this.pagination.perPage = 10;
        this.filtroChanged = true
        this.fetchSales();
        this.validCoin = false;
      } else this.validCoin = true;
    },
    setQueryParams(isNotToRefresh) {
      this.dateRange.startDate = this.$route.query.date_start || this.dateRange.startDate || moment().format("MM-DD-YYYY");
      this.dateRange.endDate = this.$route.query.date_end || this.dateRange.endDate || moment().format("MM-DD-YYYY");
      this.filters.status = this.$route.query.status || this.filters.status || "";
      this.filters.method = this.$route.query.method || this.filters.method || "";
      this.filters.meta_key = this.$route.query.meta_key || this.filters.meta_key || "";
      this.filters.meta_value = this.$route.query.meta_value || this.filters.meta_value || "";
      this.filters.products = this.$route.query.products || this.filters.products || "";
      this.filters.contracts = this.$route.query.contracts || this.filters.contracts || "";
      this.filters.affiliates = this.$route.query.affiliates || this.filters.affiliates || "";
      if (isNotToRefresh) {
        this.$router
          .replace({
            query: {
              date_start: moment(this.dateRange.startDate).format("MM-DD-YYYY"),
              date_end: moment(this.dateRange.endDate).format("MM-DD-YYYY"),
              ...this.filters,
              ...this.search
            }
          })
      } else {
        this.fetchSales(this.search, false, null, 'setQueryParams');
      }
    },
    async fetchTotal(filters = {}) {

      let objectDataCleare = this.formatObject({ ...filters, ...this.search });

      if(this.filtroChanged) {
        this.loading3 = true
        this.loading2 = true
        await totalService
          .search(objectDataCleare)
          .then(res => { this.total = res.total })
          .catch(err => console.error(err))
          .finally(() => { 
            this.loading2 = false
            this.loading3 = false
          })
        this.filtroChanged = false
      }
    },
    textMethod(method) {
      if(method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    sendGtag(){
      gtagSend('pagina_vendas_acessada', {
          ID_Cliente: this.userID,
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
    this.settingsProft = this.$store.getters['opportunities/getActiveProft'];
    this.getWinGreennProft = this.$store.getters['opportunities/getWinOfGreennSofy'];
    this.getLosOfGreennSoft = this.$store.getters['opportunities/getLosOfGreennSoft'];
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  },

  mounted() {
    // if (JSON.parse(localStorage.user).country)
    //   if (JSON.parse(localStorage.user).country.base_currency) {
    //     this.currency = JSON.parse(localStorage.user).country.base_currency;
    //   }    
    this.$store.dispatch('fetchFlags')
    if(this.isHeaven) {
      this.currency = {
        id: 2,
        name: "Dólar Americano",
        currency: "USD",
        currency_symbol: "$",
      }
    }
    this.getPermissions();
    this.loading = true;
    if (window.localStorage.sales_filters) {
      let filtersParams = JSON.parse(window.localStorage.sales_filters);
      this.$route.query.date_start = filtersParams.date_start;
      this.$route.query.date_end = filtersParams.date_end;
      delete filtersParams.search;
      this.filters = filtersParams;
      this.pagination.currentPage = filtersParams.page || this.$route.query.page || 1
    }

    // abrindo venda unica
    if (typeof this.$route.query.s_id != "undefined") {
      this.sale_id = this.$route.query.s_id;
      setTimeout(() => {
        this.$refs.salePreview.openPanel(this.sale_id);
        this.loading = false;
      }, 1000);
    } else {
      this.setQueryParams();
    }

    // filtros salvos
    if (window.localStorage.sales_filters) {
      let filters = JSON.parse(window.localStorage.sales_filters);
      delete filters.search;
      filters.date_start = this.$route.query.date_start || moment().format("MM-DD-YYYY");
      filters.date_end = this.$route.query.date_end || moment().format("MM-DD-YYYY");
      this.searchByFilter(filters);
    } else {
      this.dateRange.startDate = this.$route.query.date_start || moment().format("MM-DD-YYYY");
      this.dateRange.endDate = this.$route.query.date_end || moment().format("MM-DD-YYYY");
      this.fetchSales();
    }
    this.resolveSearch();

    EventBus.$on("relist-sales", () => {
      this.fetchSales();
    });
    this.sendGtag();
  },
};
</script>

<style lang="scss" scoped>
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.statistically-button{
  position:relative;
  &:has(.inverted--black){
    .card-opportunities{
      opacity: 0;
    }
  }

}
.is-star{
  #id_venda{
    &::before{
      background-image: url('../../assets/img/icons/opportunities/star.svg');
      background-repeat: no-repeat;
      background-position: ce nter;
    }
  }
}
.card-converted{
  @extend .card-opportunities;
  top:-30px !important;
  right:40% !important;
  p{
    font-size: var(--font-xxs) !important;
    color: var(--yellow-50) !important;
    max-height: var(--h-4);
    text-align: center;
    display: flex;
    align-items: center;
  }
  animation: pulse 1.5s infinite ease-in-out;
  transition: none;
}
@keyframes pulse {
  0% {
    box-shadow: none;
  }
  5% {
    box-shadow: 0px 0px 15px 1px #E6AF0C;
  }
  60% {
    box-shadow: none;
  }
}
.card-opportunities{
  padding: var(--spacing-1) var(--spacing-2);
  color: var(--yellow-50) !important;
  font-size: var(--font-xs) !important;
  position: absolute;
  transition: opacity 0.3s ease-in;
  top: -15px;
  right: -10px;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table.heaven:first-child {
  background: $base-color-heaven-light;
}
.btn-table.heaven:first-child:active {
  background: $base-color-heaven-light;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  padding-top: 20px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 60px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
.sale-title {
  display: flex;
  gap: 5px; 
  display: flex;
  align-items: center;
}
.hover-smart-icon {
  position: relative;
  display: inline-block;
  display: flex;
  background-color: var(--primary-50);
  border-radius: 25%;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
}
.hover-smart-icon svg {
  fill: var(--primary-500);
}
.search {
  position: absolute;
  left: 52px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: $base-color;
}
.whats-cliente.heaven {
  color: $base-color-heaven;
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.Table-body.heaven::before {
  left: 0;
}
.button {
  width: 179px;
  height: 55px;
  border-radius: 5px;
  background: #f7f7f7;
  color: #333;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button--black {
  background: #333;
  color: #fff;
}
.button:hover {
  transform: scale(1);
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>

<style lang="scss">
.heaven .reportrange-text:hover{
  border-color: $base-color-heaven !important;
}
</style>
