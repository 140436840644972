<template>
  <BaseModal name="contracts-filters" size="lg" :title="$t('client.text_1649')">
    <b-row>
      <b-col cols="12">
        <b-form novalidate>
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.recurrence_subscriptions.right_preview.text_1794')">
                <multiselect
                  v-model="filter.products"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.recurrence_subscriptions.text_2439')"
                  :selectLabel="$t('seller.recurrence_subscriptions.text_2440')"
                  :deselectLabel="$t('seller.recurrence_subscriptions.text_2441')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="products"
                  :multiple="true"
                  :searchable="true"
                  :loading="loading"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :class="{'heaven': isHeaven}"
                  @search-change="aux_product_debounce"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t('seller.recurrence_subscriptions.text_2442') }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t('seller.recurrence_subscriptions.text_2443') }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Offers -->
          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.sales.text_2655')">
                <multiselect
                  v-model="filter.offers"
                  label="name"
                  track-by="id"
                  :placeholder="$t('seller.sales.text_2656')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :deselectLabel="$t('seller.sales.text_2619')"
                  selectedLabel="✔️"
                  open-direction="bottom"
                  :options="offers"
                  :multiple="true"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  :options-limit="10"
                  :show-no-results="true"
                  :hide-selected="true"
                  :disabled="offers_disabled"
                  :class="{'heaven': isHeaven}"
                >
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2656") }}</span>
                  </template> 
                  <template v-slot:noResult>
                    <span>{{ $t("seller.sales.text_2657") }}</span>
                  </template> 
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group :label="$t('seller.recurrence_subscriptions.text_2444')">
                <multiselect
                  v-model="filter.affiliates"
                  :placeholder="$t('seller.recurrence_subscriptions.text_2445')"
                  label="name"
                  track-by="id"
                  :options="affiliates"
                  :multiple="true"
                  :taggable="false"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  :class="{'heaven': isHeaven}"
                  >
                    <template v-slot:noResult>
                      <span>{{ $t('seller.recurrence_subscriptions.text_2446') }}</span>
                    </template> 
                    </multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('seller.recurrence_subscriptions.text_2447')"
                label-for="method"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('metodos')"
                  class="btn-todos"
                  >{{ $t('seller.recurrence_subscriptions.text_2448') }}</a
                >
                <multiselect
                  v-model="filter.method"
                  :placeholder="$t('seller.recurrence_subscriptions.text_2447')"
                  label="name"
                  track-by="id"
                  :options="method_list"
                  :multiple="true"
                  :taggable="false"
                  :clear-on-select="false"
                  :close-on-select="false"
                  class=""
                  :selectLabel="$t('seller.recurrence_subscriptions.text_2440')"
                  :deselectLabel="$t('seller.recurrence_subscriptions.text_2441')"
                  selectedLabel="✔️"
                  :class="{'heaven': isHeaven}"
                  >
                    <template v-slot:noResult>
                        <span>{{ $t('seller.recurrence_subscriptions.text_2449') }}</span>
                      </template> 
                  </multiselect>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Status"
                label-for="status"
                class="form-relative"
              >
                <a
                  href="#"
                  @click.prevent="selecionarTodos('status')"
                  class="btn-todos"
                  >{{ $t('seller.recurrence_subscriptions.text_2451') }}</a
                >
                <multiselect
                  v-model="filter.status"
                  :placeholder="$t('seller.recurrence_subscriptions.text_2450')"
                  label="text"
                  track-by="value"
                  :options="status_list"
                  :multiple="true"
                  :taggable="false"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  class="multi-select-input"
                  :class="{'heaven': isHeaven}"
                  >
                    <template v-slot:noResult>
                      <span>{{ $t('seller.recurrence_subscriptions.text_2452') }}</span>
                    </template> 
                  </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        {{ $t('seller.recurrence_subscriptions.text_2453') }}
      </BaseButton>
      <BaseButton :class="{'heaven': isHeaven}" variant="primary" @click="onSubmit">
        {{ $t('seller.recurrence_subscriptions.text_2462') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";
import gtagSend from '@/utils/gtagSend';


import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import RecurrenceSubscriptionService from "@/services/resources/RecurrenceSubscriptionService";
const serviceRecurrenceSubscription = RecurrenceSubscriptionService.build();

import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      search: "",
      products: [],
      offers: [],
      affiliates: [],
      filter: {
        products: [],
        offers: [],
        affiliates: [],
        type: "created_at",
        status: [
          { value: "trialing", text: this.$t('seller.recurrence_subscriptions.text_2454') },
          { value: "paid", text: this.$t('seller.recurrence_subscriptions.text_2455') },
          { value: "pending_payment", text: this.$t('seller.recurrence_subscriptions.text_2456') },
          { value: "unpaid", text: this.$t('seller.recurrence_subscriptions.text_2457') },
          { value: "canceled", text: this.$t('seller.recurrence_subscriptions.text_2458') },
          { value: "ended", text: this.$t('seller.recurrence_subscriptions.text_2459') },
          { value: "processing", text: this.$t('status_sales.processing') },
        ],
        method: !this.isHeaven ? [
          { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
          { id: "BOLETO", name: this.$t('seller.recurrence_subscriptions.text_2461') },
          { id: "PIX", name: this.$t('seller.recurrence_subscriptions.text_2482') },
        ] : [
          { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
          { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
          { id: "OXXO", name: "OXXO" },
          { id: "EFECTY", name: "EFECTY" },
          { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
          { id: "EFT", name: "EFT" },
          { id: "SPEI", name: "SPEI" },
          { id: "SENCILLITO", name: "SENCILLITO" }
        ],
      },

      status_list: [
        { value: "trialing", text: this.$t('seller.recurrence_subscriptions.text_2454') },
        { value: "paid", text: this.$t('seller.recurrence_subscriptions.text_2455') },
        { value: "pending_payment", text: this.$t('seller.recurrence_subscriptions.text_2456') },
        { value: "unpaid", text: this.$t('seller.recurrence_subscriptions.text_2457') },
        { value: "canceled", text: this.$t('seller.recurrence_subscriptions.text_2458') },
        { value: "ended", text: this.$t('seller.recurrence_subscriptions.text_2459') },
        { value: "processing", text: this.$t('status_sales.processing') },
      ],

      method_list: !this.isHeaven ? [
        { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
        { id: "BOLETO", name: this.$t('seller.recurrence_subscriptions.text_2461') },
        { id: "PIX", name: this.$t('seller.recurrence_subscriptions.text_2482') },
      ] : [
        { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
        { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
        { id: "OXXO", name: "OXXO" },
        { id: "EFECTY", name: "EFECTY" },
        { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
        { id: "EFT", name: "EFT" },
        { id: "SPEI", name: "SPEI" },
        { id: "SENCILLITO", name: "SENCILLITO" }
      ]
    }
  },
  created() {
    this.addNewMethods();
  },
  computed: {
    offers_disabled() {
      return !this.products.length || !this.offers.length;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    addNewMethods() {
      if(this.isHeaven) {
        this.method_list = [
          { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
          { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
          { id: "OXXO", name: "OXXO" },
          { id: "EFECTY", name: "EFECTY" },
          { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
          { id: "EFT", name: "EFT" },
          { id: "SPEI", name: "SPEI" },
          { id: "SENCILLITO", name: "SENCILLITO" }
        ]
      }
    },
    selecionarTodos(type) {
      switch (type) {
        case "metodos": {
          this.filter.method = !this.isHeaven ? [
            { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
            { id: "BOLETO", name: this.$t('seller.recurrence_subscriptions.text_2461') },
            { id: "PIX", name: this.$t('seller.recurrence_subscriptions.text_2482') },
          ] : [
            { id: "CREDIT_CARD", name: this.$t('seller.recurrence_subscriptions.text_2460') },
            { id: "SAFETYPAY-CASH", name: "SAFETYPAY-CASH" },
            { id: "OXXO", name: "OXXO" },
            { id: "EFECTY", name: "EFECTY" },
            { id: "DEBITCARD", name: this.$t('seller.recurrence_subscriptions.text_2460_2') },
            { id: "EFT", name: "EFT" },
            { id: "SPEI", name: "SPEI" },
            { id: "SENCILLITO", name: "SENCILLITO" }
          ]
          break;
        }
        case "status": {
          this.filter.status = [
            { value: "trialing", text: this.$t('seller.recurrence_subscriptions.text_2454') },
            { value: "paid", text: this.$t('seller.recurrence_subscriptions.text_2455') },
            { value: "pending_payment", text: this.$t('seller.recurrence_subscriptions.text_2456') },
            { value: "unpaid", text: this.$t('seller.recurrence_subscriptions.text_2457') },
            { value: "canceled", text: this.$t('seller.recurrence_subscriptions.text_2458') },
            { value: "ended", text: this.$t('seller.recurrence_subscriptions.text_2459') },
            { value: "processing", text: this.$t('status_sales.processing') },
          ];
          break;
        }
      }
    },
    // produto
    aux_product_debounce(query) {
      this.loading = true;
      this.search = query;
      this.debounce_product();
    },
    debounce_product: _.debounce(function () {
      this.fetchProducts(this.search);
    }, 500),
    debounce_offers: _.debounce(function () {
      this.fetchOffers();
    }, 500),
    onSubmit() {
      let data = {
        page: 1,
        type: this.filter.type,
        status: this.filter.status.length
          ? this.filter.status.map((item) => item.value)
          : null,
        method: this.filter.method.length
          ? this.filter.method.map((item) => item.id)
          : null,
      };

      if (this.filter.affiliates.length) {
        data.affiliates = this.filter.affiliates.map((item) => item.id);
      }

      let products = [];
      if (this.filter.products.length) {
        this.filter.products.forEach((item) => {
          products.push({ "contracts[*][product_id]": item.id });
        });

        const newProducts = [];
        Object.keys(products).forEach((key) => {
          products[key] == null && delete products[key];
          let aux = JSON.stringify(products[key]);
          if (aux.indexOf("*")) {
            let arr = aux.split("");
            arr.splice(arr.indexOf("*"), 1, key);
            newProducts.push(JSON.parse(arr.join().replaceAll(",", "")));
          }
        });

        data = Object.assign(data, ...newProducts);
      }

      if (this.filter.offers.length) {
        data.offer = this.filter.offers.map((item) => item.id);
      }

      data = {...data, reset: true};
      this.$root.$emit("updateDataFilter", data);
      gtagSend('filtro_contratos_aplicado',{
        ID_Cliente: this.$store.getters.getProfile.id,
      })
      this.$bvModal.hide("contracts-filters");
    },
    fetchProducts(search = null) {
      let data = {
        list: true,
      };

      if (search) data.search = search;

      this.products = [];

      serviceProduct
        .search(data)
        .then((response) => {
          response.forEach((element) => {
            this.products.push({ id: element.id, name: element.name });
          });
        })
        .catch((err) => {
          console.log(err);
          this.products = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchOffers(search = null) {
      const offers = [];
      this.filter.products.forEach(async (el) => {
        let data = { list: true, url: `${el.id}/offers` };
        if (search) data.search = search;

        offers.push(
          serviceProduct.search({ ...data }).then((response) => {
            return response;
          })
        );
      });

      await Promise.all(offers)
        .then((values) => {
          values.forEach((item) => {
            let offers_map = item.map((offer) => {
              if (this.filter.products.map(item => item.id).includes(offer.product_id)) {
                return { id: offer.id, name: offer.name };
              }
            });
            this.offers.push(...offers_map);
          });
        })
        .catch(() => {
          this.offers = [];
        })
        .finally(() => {
          let filtered = this.filter.offers;
          let offers_id = this.offers.map((item) => item.id);

          this.filter.offers = filtered.filter((el) => {
            return offers_id.includes(el.id);
          });
        });
    },

    fetchAffiliates() {
      let data = {
        id: "/affiliate",
      };

      this.affiliates = [];

      serviceRecurrenceSubscription
        .read(data)
        .then((response) => {
          response.forEach((element) => {
            this.affiliates.push({
              id: element.id,
              name: `${element.name}  - ${element.email}`,
            });
          });
        })
        .catch(() => {
          this.affiliates = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    "filter.products": {
      deep: true,
      handler(products) {
        this.offers = [];
        if (products.length) {
          this.debounce_offers();
        } else {
          this.filter.offers = [];
        }
      },
    },
    "filter.affiliates": {
      handler(value) {
        console.log(value);
      }
    }
  },
  mounted() {
    this.fetchAffiliates();
  },
};
</script>

<style lang="scss">
.w-100 {
  width: 100%;
}
.daterangepicker {
  top: 100% !important;
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}
</style>
