<script setup>
import { computed, provide, ref } from "vue";
import DropdownFilter from "./DropDownFilter"
import DropDownSeatch from "./DropDownSearch.vue";
import DropDownStatus from "./DropDownStatus.vue";

const props = defineProps({
  filters: {
    type: Array,
    Required: true,
  }
})

const showSearchOrStatus = ref(false)

const filterSelected = ref({})

const dropDownValue = ref(false)

const showDopDonw = computed({
  get() {
    return dropDownValue.value
  },
  set(value) {
    dropDownValue.value = value
  }
})

function setDropDownValue(value) {
  showDopDonw.value = value
}

function setSearchOrStatus(type, item) {
  showSearchOrStatus.value = type
  filterSelected.value = item
}

function setSearch(item) {
  setDropDownValue(false)
  setSearchOrStatus(item.type, item)
}

function emitSearch(searchValue, searchSelected) {
  emits('search', searchValue, searchSelected)
  setSearchOrStatus(false, {})
}

const emits = defineEmits(['search'])

provide('setSearch', setSearch)
provide('emitSearch', emitSearch)
provide('setSearchOrStatus', setSearchOrStatus)

</script>

<template>
  <div>
    <div @click="setDropDownValue(!showDopDonw)" class="slot-button-open">
      <slot />
      <div @click.stop @mouseleave="setDropDownValue(false)" class="not-show" :class="{ 'show': showDopDonw }">
        <DropdownFilter :filters="props.filters" />
      </div>
      <div class="not-show" :class="{ 'show': showSearchOrStatus }">
        <DropDownSeatch :selected="filterSelected"
          v-if="showSearchOrStatus == 'search'" />
        <DropDownStatus :selected="filterSelected"
          v-if="showSearchOrStatus == 'status'" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slot-button-open {
  position: relative;
}


.not-show {
  display: none;
  min-width: 273px;
  position: absolute;
  background-color: #FFFFFF;
  z-index: 500;
  right: 0;
  border-radius: var(--radius-lg);
  box-shadow: 0 0 8px var(--gray-10);
  animation: showTop 0.3s forwards;
}

.show {
  display: block !important;
}


@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>