<script setup>
import { useRouter } from "vue-router/composables";
import { useStore } from "@/utils/useStore";
import { computed, onMounted } from 'vue';
import { money } from '@/utils/money';
import { GrTag } from '@/components/GrComponents'

const store = useStore();
const router = useRouter();

function redirect() {

  router.push({ path: '/oportunidades' })
}

const getLosOfGreennSoft = computed({
  get() {
    return store.getters['opportunities/getLosOfGreennSoft']
  }
})
const getWinGreennProft = computed({
  get() {
    return store.getters['opportunities/getWinOfGreennSofy']
  }
})

const valueProft = computed({
  get() {        
    return settingsProft.value ? getWinGreennProft.value : getLosOfGreennSoft.value
  }
})

const settingsProft = computed({
  get: () => {
    return store.getters['opportunities/getActiveProft'];
  },
})

</script>
<template>
  <div class="mini-card-feature">
    <GrTag
      class="new-notification"
      customBackground="var(--yellow-500)"
      theme="alternate"
    >
      {{ $t('opportunities.notification') }}
    </GrTag>
    <img src="@/assets/img/icons/opportunities/feature.svg" alt="">
    
    <span class="value">{{ money(valueProft, "R$") }}</span>
    <p class="description" v-if="settingsProft">{{ $t('opportunities.dashbord.actived') }}</p>
    <p class="description" v-else>{{ $t('opportunities.sales.description') }}</p>
    <div @click="redirect()" class="footer">
      <p>{{ $t('opportunities.sales.footer') }}</p>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.56576 6.8661V10.1994C9.56576 10.4941 9.44869 10.7767 9.24032 10.9851C9.03194 11.1935 8.74933 11.3105 8.45464 11.3105H2.34353C2.04885 11.3105 1.76623 11.1935 1.55786 10.9851C1.34948 10.7767 1.23242 10.4941 1.23242 10.1994V4.08832C1.23242 3.79364 1.34948 3.51102 1.55786 3.30265C1.76623 3.09428 2.04885 2.97721 2.34353 2.97721H5.67687M7.89909 1.31055H11.2324M11.2324 1.31055V4.64388M11.2324 1.31055L5.12131 7.42166"
          stroke="#E6AF0C" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.new-notification{
  padding: var(--spacing-1) var(--spacing-2);
  color: var(--yellow-50) !important;
  position: absolute;
  top: -15px;
  right: -10px;
}
.mini-card-feature {
  display: grid;
  gap: var(--spacing-2);

  .value {
    color: var(--primary-800);
    font-weight: var(--weight-bold);
    font-size: var(--font-base);
  }
}

.description {
  color: var(--gray-200);
  font-weight: var(--weight-normal);
  font-size: var(--font-sm);
}

.footer {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  cursor: pointer;

  p {
    color: var(--yellow-500);
    font-weight: var(--weight-semibold);
  }
}
</style>