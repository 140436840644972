<script setup>
import { computed } from "vue";

const props = defineProps({
  variant: {
    type: String,
    default: "success",
  },
  customBackground: {
    type: String,
    default: "",
  },
  outline: {
    type: Boolean,
    default: false,
  },
  margin: {
    type: String,
    default: "",
  },
  theme: {
    type: String,
    default: "default",
    validator: (value) => ["default", "alternate"].includes(value),
  },
});

const cssClasses = computed(() => {
  let classes = [];

  if (props.customBackground.length) {
    classes.push("custom");
  } else {
    if (props.outline) {
      classes.push("outline");
    }
    classes.push(props.variant);
    classes.push(props.theme);
  }

  return classes;
});

const customBg = computed(() => {
  return props.customBackground;
});

const customMargin = computed(() => {
  return props.margin;
});
</script>

<template>
  <div :class="['gr-tag', ...cssClasses]" @click="$emit('click')">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.gr-tag {
  font-size: 13px;
  font-weight: 600;
  border-radius: 10px;
  width: fit-content;
  flex-wrap: wrap;
  margin-left: v-bind(customMargin);
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  padding: 0 10px;
  height: 34px;

  &.custom {
    color: var(--grenn-secondary);
    background: v-bind(customBg);
  }

  &.success {
    &.default {
      color: var(--accent-50);
      background: var(--accent-600);
    }
    &.alternate {
      color: var(--accent-600);
      background: var(--accent-50);
    }
  }
  &.success-light {
    color: var(--primary);
    background: var(--primary-light);
  }

  &.warning {
    color: var(--orange-50);
    background: var(--orange-600);
  }
  &.warning-light {
    &.default {
      color: var(--orange);
      background: var(--orange-light);
    }
    &.alternate {
      color: var(--orange-600);
      background: var(--orange-50);
    }
  }

  &.info {
    &.default {
      color: var(--secondary-50);
      background: var(--secondary-500);
    }
    &.alternate {
      color: var(--secondary-500);
      background: var(--secondary-50);
    }
  }
  &.info-light {
    color: var(--blue);
    background: var(--blue-light);
  }

  &.danger {
    &.default {
      color: var(--red-50);
      background: var(--red-500);
    }
    &.alternate {
      color: var(--red-500);
      background: var(--red-50);
    }
  }
  
  &.danger-light {
    color: var(--red);
    background: var(--red-light);
  }

  &.sucess-dark {
    color: var(--green-dark-50);
    background: var(--green-dark-500);
  }
  &.sucess-dark-light {
    color: var(--green-dark-500);
    background: var(--green-dark-50);
  }

  &.black {
    color: #f7f7f7;
    background: var(--green-dark-900);
  }
  &.black-light {
    color: var(--green-dark-900);
    background: #f7f7f7;
  }

  &.outline {
    border: 1px solid;
    background: transparent;

    &.success {
      color: var(--accent-600);
      border-color: var(--accent-600);
    }
    &.success-light {
      color: var(--accent-600);
      border-color: var(--accent-600);
    }

    &.warning {
      color: var(--orange-600);
      border-color: var(--orange-600);
    }
    &.warning-light {
      color: var(--orange-600);
      border-color: var(--orange-600);
    }

    &.info {
      color: var(--secondary-500);
      border-color: var(--secondary-500);
    }
    &.info-light {
      color: var(--secondary-500);
      border-color: var(--secondary-500);
    }

    &.danger {
      color: var(--red-500);
      border-color: var(--red-500);
    }
    &.danger-light {
      color: var(--red-500);
    }
  }
}
</style>
