<template>
  <div class="inputSearch">
    <img src="@/assets/img/icons/search.svg" class="Pointer search" />
    <input
      type="text"
      :placeholder="$t('seller.recurrence_subscriptions.text_2463')"
      v-on:input="debounce"
      v-model="search"
      class="input-busca"
    />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      search: null,
    };
  },
  methods: {
    debounce: _.debounce(function () {
      this.$root.$emit("updateDataFilter", { search: this.search, page: 1 });
    }, 300),
  },
};
</script>

<style scoped>
.input-busca {
  width: 350px;
  height: 55px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  margin-bottom: 0 !important;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
@media screen and (max-width: 768px) {
  .input-busca {
    /* width: 100%; */
    width: 300px;
  }
  .input-busca:hover,
  .input-busca:focus {
    /* width: 100%; */
    width: 300px;
  }
  .search {
    left: 20px !important;
  }
}

.search {
  position: absolute;
  left: 35px;
  top: 20px;
  width: 14.7px;
  height: 14.7px;
  z-index: 1;
}
</style>