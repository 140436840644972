<template>
  <b-form novalidate>
    <b-row class="w-100 justify-content-between">
      <b-row class="mt-2" v-if="period !== 30" v-show="acceptCard">
        <!-- Fixed Installments -->
        <b-col cols="12" md="6" data-anima="top" class="pr-3">
          <b-form-group id="group-fixed-installments" :label="$t('views.cobranca.text_2153')" label-for="fixed_installments">
            <b-form-select id="fixed_installments" name="fixed_installments" v-model="fixed_installments" :disabled="custom_charges_is_available ? true : false" :options="option_fixed_installments" :class="{ 'heaven': isHeaven }"></b-form-select>

            <div class="mark-down" v-if="fixed_installments">
              <p class="info-mark">
                {{ $t('views.cobranca.text_903') }}
                {{ fixed_installments }}x
              </p>
            </div>
          </b-form-group>
          <b-tooltip v-if="custom_charges_is_available" target="group-fixed-installments" triggers="hover">
            {{ $t('views.cobranca.not_possible_with_payment_plan_on') }}
          </b-tooltip>
        </b-col>
        <!-- Max Installments -->
        <b-col v-if="fixed_installments === null" cols="12" md="6" data-anima="top">
          <b-form-group :label="$t('views.cobranca.text_904')" label-for="max_installments">
            <b-form-select id="max_installments" name="max_installments" v-model="max_installments" v-validate="'required'" :options="option_max_installments" :class="{ 'heaven': isHeaven }" @change="changeParcelasCard()"></b-form-select>
            <b-form-invalid-feedback :state="!errors.has('max_installments') || !max_installments">
              {{ $t('views.cobranca.text_905') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <!-- Pre Selected Installments -->
        <b-col v-if="fixed_installments === null" cols="12" md="6" data-anima="top" class="mt-4 mb-3">
          <b-form-group id="tooltip_installment" :label="$t('views.cobranca.text_2156')" label-for="pre_selected_installment">
            <b-form-select id="pre_selected_installment"
             name="pre_selected_installment" v-model="pre_selected_installment" 
             :options="option_pre_selected_installment" :class="{ 'heaven': isHeaven }" 
             :disabled="disabledPreSelect">
            </b-form-select>
            <div class="mark-down" v-if="pre_selected_installment">
              <p class="info-mark">
                {{ $t('views.cobranca.text_2157') }}
                {{ pre_selected_installment }}x
              </p>
            </div>
          </b-form-group>
          <b-tooltip v-if="!custom_charges_is_available" target="tooltip_installment" triggers="hover">
            {{ $t('views.cobranca.text_2158') }}
          </b-tooltip>
          <b-tooltip v-else target="tooltip_installment" triggers="hover">
            {{ $t('views.cobranca.not_possible_with_payment_plan_on') }}
          </b-tooltip>
        </b-col>
      </b-row>
    </b-row>
    <div class="mt-4" data-anima="top">
         <!-- Allow Coupom -->
         <div id="coupon_group" class="type mt-4">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5146 10.5249L17.2218 11.2319L17.2239 11.2298L16.5146 10.5249ZM10.5308 16.5103L9.82357 15.8033L9.82318 15.8037L10.5308 16.5103ZM8.16894 16.5103L8.87654 15.8037L8.87573 15.8029L8.16894 16.5103ZM1 9.34787H0C0 9.61324 0.105481 9.86773 0.293213 10.0553L1 9.34787ZM1 1V0C0.447715 0 0 0.447715 0 1L1 1ZM9.34568 1L10.0529 0.292986C9.86534 0.105393 9.61095 0 9.34568 0V1ZM16.5146 8.17082L17.2239 7.46591L17.2218 7.4638L16.5146 8.17082ZM5.17284 4.17393C4.62056 4.17393 4.17284 4.62165 4.17284 5.17393C4.17284 5.72622 4.62056 6.17393 5.17284 6.17393V4.17393ZM5.18119 6.17393C5.73347 6.17393 6.18119 5.72622 6.18119 5.17393C6.18119 4.62165 5.73347 4.17393 5.18119 4.17393V6.17393ZM15.8074 9.8179L9.82357 15.8033L11.238 17.2174L17.2218 11.2319L15.8074 9.8179ZM9.82318 15.8037C9.76102 15.866 9.68722 15.9153 9.60601 15.949L10.3721 17.7965C10.6961 17.6621 10.9905 17.4652 11.2384 17.217L9.82318 15.8037ZM9.60601 15.949C9.5248 15.9827 9.43776 16 9.34986 16V18C9.70066 18 10.048 17.9308 10.3721 17.7965L9.60601 15.949ZM9.34986 16C9.26196 16 9.17491 15.9827 9.0937 15.949L8.32765 17.7965C8.6517 17.9308 8.99906 18 9.34986 18V16ZM9.0937 15.949C9.01249 15.9153 8.93869 15.866 8.87654 15.8037L7.46135 17.217C7.70923 17.4652 8.0036 17.6621 8.32765 17.7965L9.0937 15.949ZM8.87573 15.8029L1.70679 8.64044L0.293213 10.0553L7.46216 17.2178L8.87573 15.8029ZM2 9.34787V1H0V9.34787H2ZM1 2H9.34568V0H1V2ZM8.63848 1.70701L15.8074 8.87783L17.2218 7.4638L10.0529 0.292986L8.63848 1.70701ZM15.8053 8.87573C15.93 9.00116 16 9.17089 16 9.34787H18C18 8.64269 17.721 7.96611 17.2239 7.46591L15.8053 8.87573ZM16 9.34787C16 9.52484 15.93 9.69458 15.8053 9.82001L17.2239 11.2298C17.721 10.7296 18 10.053 18 9.34787H16ZM5.17284 6.17393H5.18119V4.17393H5.17284V6.17393Z"
                fill="#81858E"
              />
            </svg>

            <div>
              <h6>{{ $t("views.cobranca.text_2163") }}</h6>
              <p>
                {{ $t("views.cobranca.text_2164") }}
              </p>
            </div>

            <b-form-checkbox
              v-model="allowed_coupon"
              name="allowed_coupon"
              size="lg"
              :disabled="(editItem && custom_charges_is_available) || custom_charges_is_available || (product.type !== 'SUBSCRIPTION' && amount === 0)"
              switch
            >
            </b-form-checkbox>
            <b-tooltip
              v-if="custom_charges_is_available"
              target="coupon_group"
              :title="$t('views.cobranca.item_cant_be_edited')"
              placement="top"
            />
          </div>
      <!-- No interest in Installments -->
      <div class="type" id="no_interest_installments_group">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8946 2.51961C14.2851 2.12908 14.2851 1.49592 13.8946 1.10539C13.5041 0.714869 12.8709 0.714869 12.4804 1.10539L13.8946 2.51961ZM1.10539 12.4804C0.714869 12.8709 0.714869 13.5041 1.10539 13.8946C1.49592 14.2851 2.12908 14.2851 2.51961 13.8946L1.10539 12.4804ZM12.4804 1.10539L1.10539 12.4804L2.51961 13.8946L13.8946 2.51961L12.4804 1.10539ZM4.0625 3.03125C4.0625 3.60079 3.60079 4.0625 3.03125 4.0625V6.0625C4.70536 6.0625 6.0625 4.70536 6.0625 3.03125H4.0625ZM3.03125 4.0625C2.46171 4.0625 2 3.60079 2 3.03125H0C0 4.70536 1.35714 6.0625 3.03125 6.0625V4.0625ZM2 3.03125C2 2.46171 2.46171 2 3.03125 2V0C1.35714 0 0 1.35714 0 3.03125H2ZM3.03125 2C3.60079 2 4.0625 2.46171 4.0625 3.03125H6.0625C6.0625 1.35714 4.70536 0 3.03125 0V2ZM13 11.9688C13 12.5383 12.5383 13 11.9688 13V15C13.6429 15 15 13.6429 15 11.9688H13ZM11.9688 13C11.3992 13 10.9375 12.5383 10.9375 11.9688H8.9375C8.9375 13.6429 10.2946 15 11.9688 15V13ZM10.9375 11.9688C10.9375 11.3992 11.3992 10.9375 11.9688 10.9375V8.9375C10.2946 8.9375 8.9375 10.2946 8.9375 11.9688H10.9375ZM11.9688 10.9375C12.5383 10.9375 13 11.3992 13 11.9688H15C15 10.2946 13.6429 8.9375 11.9688 8.9375V10.9375Z" fill="#81858E" />
        </svg>

        <div>
          <h6>{{ $t('views.cobranca.text_2165') }}</h6>
          <p>
            {{ $t('views.cobranca.text_2166') }}
          </p>
        </div>
        <b-form-checkbox v-model="no_interest_installments" name="no_interest_installments" size="lg" switch id="no_interest_installments" :disabled="editItem && custom_charges_is_available || custom_charges_is_available ? true : false">
        </b-form-checkbox>
      </div>
      <b-tooltip v-if="custom_charges_is_available" target="no_interest_installments_group" :title="$t('views.cobranca.item_cant_be_edited')" placement="top" />
      <!-- Affiliate has view -->
      <div class="type">
        <svg width="15" height="15" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.34896 11.227C8.73766 11.7466 9.23358 12.1766 9.80306 12.4878C10.3726 12.7989 11.0023 12.9839 11.6496 13.0303C12.2969 13.0766 12.9465 12.9832 13.5546 12.7564C14.1626 12.5296 14.7147 12.1747 15.1735 11.7157L17.8888 9.00042C18.7132 8.1469 19.1693 7.00373 19.159 5.81715C19.1487 4.63057 18.6728 3.49551 17.8337 2.65643C16.9946 1.81736 15.8595 1.34141 14.673 1.3311C13.4864 1.32079 12.3432 1.77694 11.4897 2.60131L9.9329 4.14904M11.9694 9.41677C11.5807 8.89712 11.0848 8.46715 10.5153 8.15601C9.94581 7.84487 9.31606 7.65985 8.66878 7.61349C8.0215 7.56714 7.37182 7.66053 6.7638 7.88734C6.15579 8.11414 5.60366 8.46906 5.14488 8.92801L2.42955 11.6433C1.60519 12.4969 1.14904 13.64 1.15935 14.8266C1.16966 16.0132 1.64561 17.1483 2.48468 17.9873C3.32375 18.8264 4.45882 19.3023 5.6454 19.3127C6.83198 19.323 7.97514 18.8668 8.82867 18.0425L10.3764 16.4947" stroke="#81858E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <div>
          <h6>{{ $t('views.cobranca.text_2170') }}</h6>
          <p>
            {{ $t('views.cobranca.text_2171_1') }}
          </p>
        </div>
        <b-form-checkbox v-model="allow_offer_link" name="allow_offer_link" size="lg" switch>
        </b-form-checkbox>
      </div>
      <!-- Installments personalized -->
      <div class="charge-container" v-if="custom_charges_is_available && !fixed_installments && !allowed_coupon">
        <b-row>
          <b-col cols="12" md="1"></b-col>
          <b-col cols="12" md="7" data-anima="top">
            <div class="charge-row" style="margin-bottom: 7px;">
              <div class="pt-3 pr-3" style="min-width: 120px;">
                <span class="font-header-charge">
                  {{ $t('views.cobranca.order') }}
                </span>
              </div>
              <div class="pr-3 pt-3" style="min-width: 200px;">
                <span class="font-header-charge">
                  {{ $t('views.cobranca.amount_charge') }}
                </span>
              </div>
              <div class="pt-3 pl-3 pr-3" style="min-width: 70px;">
                <span v-if="editItem ? false : true" class="font-header-charge">
                  {{ $t('views.cobranca.remove') }}
                </span>
              </div>
            </div>
            <div :key="`custom-charge-${index}`" v-for="(customCharge, index) in custom_charges" class="charge-row">
              <div class="pt-3 pr-3" style="min-width: 100px;">
                {{ customCharge.sequence }}ª {{ $t('views.cobranca.charge') }}
              </div>
              <div class="pr-3 pl-3 pt-2" style="min-width: 220px;">
                <money :id="`amount-custom-charge-${index}`" v-model="custom_charges[index].amount" v-bind="money" style="max-height: 40px;" class="w-100" :disabled="editItem ? true : false" :class="{ 'heaven': isHeaven }"></money>
              </div>
              <div class="pt-3 pl-3" style="min-width: 70px;">
                <button v-if="editItem ? false : true" @click.prevent="removeCustomCharge(index)" style="border: none; background: none;">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.9375 1.23779L1.9375 13.2378M1.9375 1.23779L13.9375 13.2378" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
            </div>
          </b-col>
          <b-col style="border-left: 1px solid #EDEDF0; padding-top: 18px; padding-left: 27px;" cols="12" md="4" data-anima="top">
            <b-row>
              <BaseButton variant="outline-info" :disabled="editItem ? true : false" @click="addNewCustomCharge">
                {{ $t('views.cobranca.new_charge') }}
              </BaseButton>
            </b-row>
            <b-row>
              <div class="charge-info">
                <span class="charge-info-text">
                  {{ $t('views.cobranca.charge_info') }} (R$ {{ amount }})
                </span>
              </div>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-tooltip v-if="(!!fixed_installments || allowed_coupon) && !editOfferIsDisable" target="custom_charges" :title="$t('views.cobranca.dont_accept_installments_coupons_and_first_charge')" placement="top" />
      <b-tooltip v-if="editOfferIsDisable && custom_charges_is_available" target="custom_charges" :title="$t('views.cobranca.cant_edit_plan_of_payment')" placement="top" />
      <b-tooltip v-if="editOfferIsDisable && !custom_charges_is_available" target="custom_charges" :title="$t('views.cobranca.create_new_plan_to_use')" placement="top" />

    </div>
  </b-form>
</template>

<script>
import Vue from 'vue'
import { Money } from 'v-money'
import Multiselect from 'vue-multiselect'
import Money2 from '@/mixins/money'
import BatchModal from '@/views/Seller/Event/BatchModal.vue'
import { textMethodType } from '@/helpers.js'
import { symbolCurrency } from '@/helpers.js'
import draggable from 'vuedraggable'
import { mapGetters } from "vuex";

import ProductService from '@/services/resources/ProductService'
import CurrencyService from '@/services/resources/CurrencyService'
import PresentialEventService from '@/services/resources/PresentialEventService'

const serviceProduct = ProductService.build()
const serviceCurrency = CurrencyService.build()
const servicePresentialEvent = PresentialEventService.build()

export default {
  name: 'Event',
  components: { Money, Multiselect, draggable, BatchModal },
  mixins: [Money2],
  props: [
    'isMobile',
    'warranty',
    'id_product',
    'methods',
    'co_sellers_list',
    'getCurrencyPayment'
  ],
  data() {
    return {
      trial: "",
      renderComponent: true,
      option_fixed_installments: [
        { value: null, text: this.$t("views.seller.edit_product.text_2292") },
        { value: 12, text: this.$t("views.seller.edit_product.text_2293") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2294") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2295") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2296") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2297") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2298") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2299") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2300") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2301") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2302") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2303") },
      ],
      charges: null,
      max_subscription_installments: null,
      amount: "",
      method: [],
      methods_: [],
      currencie_id: 1,
      currencies: [],
      amount_first_charge: 0,
      custom_charges_bkp: false,
      custom_charges_is_available: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false
      },
      name: "",
      make: false,
      offers: [],
      loading: false,
      submit: false,
      defaultValue: 0,
      defaultValueCurrency: "R$",
      editItem: null,
      period: 365,
      subscription_installments: [
        { value: null, text: this.$t('views.seller.edit_product.text_2289'), disabled: true },
        { value: 1, text: this.$t('views.seller.edit_product.text_2290') },
      ],
      option_pre_selected_installment: [
        { value: null, text: this.$t('views.seller.edit_product.text_2304'), disabled: true },
        { value: 12, text: this.$t('views.seller.edit_product.text_2305') },
        { value: 11, text: this.$t('views.seller.edit_product.text_2306') },
        { value: 10, text: this.$t('views.seller.edit_product.text_2307') },
        { value: 9, text: this.$t('views.seller.edit_product.text_2308') },
        { value: 8, text: this.$t('views.seller.edit_product.text_2309') },
        { value: 7, text: this.$t('views.seller.edit_product.text_2310') },
        { value: 6, text: this.$t('views.seller.edit_product.text_2311') },
        { value: 5, text: this.$t('views.seller.edit_product.text_2312') },
        { value: 4, text: this.$t('views.seller.edit_product.text_2313') },
        { value: 3, text: this.$t('views.seller.edit_product.text_2314') },
        { value: 2, text: this.$t('views.seller.edit_product.text_2315') },
        { value: 1, text: this.$t('views.seller.edit_product.text_2316') },
        { value: 0, text: this.$t('views.seller.edit_product.text_2290') },
      ],
      periods: [
        { value: 30, text: this.$t('views.seller.edit_product.text_2284') },
        { value: 90, text: this.$t('views.seller.edit_product.text_2285') },
        { value: 180, text: this.$t('views.seller.edit_product.text_2286') },
        { value: 365, text: this.$t('views.seller.edit_product.text_2287') }
      ],
      drag: false,
      dragGroup: false,
      batch_groups: [],
      batchGroup: null,
      groupBatch: [],
      option_max_installments: [
        { value: null, text: this.$t("views.seller.edit_product.text_2289"), disabled: true },
        { value: 12, text: this.$t("views.seller.edit_product.text_2317") },
        { value: 11, text: this.$t("views.seller.edit_product.text_2318") },
        { value: 10, text: this.$t("views.seller.edit_product.text_2319") },
        { value: 9, text: this.$t("views.seller.edit_product.text_2320") },
        { value: 8, text: this.$t("views.seller.edit_product.text_2321") },
        { value: 7, text: this.$t("views.seller.edit_product.text_2322") },
        { value: 6, text: this.$t("views.seller.edit_product.text_2323") },
        { value: 5, text: this.$t("views.seller.edit_product.text_2324") },
        { value: 4, text: this.$t("views.seller.edit_product.text_2325") },
        { value: 3, text: this.$t("views.seller.edit_product.text_2326") },
        { value: 2, text: this.$t("views.seller.edit_product.text_2327") },
        { value: 1, text: this.$t("views.seller.edit_product.text_2290") },
      ],
    }
  },
  computed: {
    ...mapGetters(["product", 'defaultOffer', 'callValidateFields', 'getAllowFreeOffers']),
    disabledPreSelect(){
      if(this.custom_charges_is_available || !this.defaultOffer.max_installments || this.defaultOffer.max_installments == 1){
        return true;
      }
      return false
    },
    acceptCard() {
      const acceptedPaymentMethods = ["CREDIT_CARD", "TWO_CREDIT_CARDS"];
      if (this.getCurrencyPayment.some(method => acceptedPaymentMethods.includes(method.value))) {
        return true;
      }
      return false;
    },
    acceptInvoice() {
      const acceptedPaymentMethods = ["BOLETO"];
      if (this.getCurrencyPayment.some(method => acceptedPaymentMethods.includes(method.value))) {
        return true;
      }
      return false;
    },
    fixed_installments: {
      get() {
        return this.defaultOffer.fixed_installments;
      },
      set(fixed_installments) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, fixed_installments });
      },
    },
    max_installments: {
      get() {
        return this.defaultOffer.max_installments;
      },
      set(max_installments) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, max_installments });
      },
    },
    pre_selected_installment: {
      get() {
        return this.defaultOffer.pre_selected_installment;
      },
      set(pre_selected_installment) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, pre_selected_installment });
      },
    },
    editOfferIsDisable() {
      return this.editItem && this.editItem.id ? true : false
    },
    max_boleto_installments: {
      get() {
        return this.defaultOffer.max_boleto_installments;;
      },
      set(max_boleto_installments) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, max_boleto_installments });
      },
    },
    allowed_coupon: {
      get() {
        return !!this.defaultOffer.allowed_coupon;;
      },
      set(allowed_coupon) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, allowed_coupon });
      },
    },
    no_interest_installments: {
      get() {
        return !!this.defaultOffer.no_interest_installments;;
      },
      set(no_interest_installments) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, no_interest_installments });
      },
    },
    allow_offer_link: {
      get() {
        return !!this.defaultOffer.allow_offer_link;;
      },
      set(allow_offer_link) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, allow_offer_link });
      },
    },
    custom_charges: {
      get() {
        return this.defaultOffer.custom_charges;;
      },
      set(custom_charges) {
        this.$store.commit("setDefaultOffer", { ...this.defaultOffer, custom_charges });
      },
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    dragOptionsGroups() {
      return {
        animation: 200,
        group: "groups",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    defaultOffer() {
      this.changeParcelasCard();
      if (this.defaultOffer?.custom_charges?.lenght > 0) {
        this.custom_charges_is_available = true;
      }
    },
    custom_charges_is_available(val) {
      if (val && this.custom_charges.length === 0) {
        this.addNewCustomCharge(true);
      }
    },
    max_subscription_installments(value) {
      this.changeParcelasCard(value)
    },
    amount_first_charge(val) {
      if (parseInt(val) < 0) {
        this.resetFirstCharge();
      }
    },
    fixed_installments(val) {
      if (val && parseInt(val) > 0) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    allowed_coupon(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
    no_interest_installments(val) {
      if (val) {
        this.resetFirstCharge();
      } else {
        this.restoreCustomCharge();
      }
    },
  },
  methods: {
    ticketsAssigned(group) {
      const numberOfTickets = group.tickets.length;
      return numberOfTickets > 1 ? this.$t('views.cobranca.text_30195_1') : this.$t('views.cobranca.text_30195')
    },
    countTickets(group) {
      const numberOfTickets = group.tickets.length;
      return numberOfTickets < 10 ? `${numberOfTickets === 0 ? '' : 0}${numberOfTickets}` : numberOfTickets;
    },
    dragOptions(i) {
      return {
        animation: 200,
        group: "tickets" + i,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    closeBatchModal() {
      this.fetchBatchs()
      this.batchGroup = null
    },
    openBatchModal(batch_group) {
      this.batchGroup = batch_group
      this.$bvModal.show("batch-modal")
    },
    showCount(group, i) {
      const count = document.getElementById('count-' + i)
      if (group.expanded) count.style.display = 'none'
      else count.style.display = 'block'
    },
    expandedGroup(group, i) {
      group.expanded = !group.expanded
      const exp = document.getElementById('exp-' + i)
      if (group.expanded) exp.style.transform = 'rotate(180deg)'
      else exp.style.transform = ''
      this.updateHeightCards()
      this.showCount(group, i)
    },
    updateHeightCards() {
      this.batch_groups.forEach(x => {
        const groupBody = document.getElementsByClassName('group-body_' + x.id)[0]
        const h = x.tickets.length * (50.65 + 16) + 68
        if (x.expanded) {
          if (groupBody) {
            groupBody.style.height = h + 'px'
            groupBody.style.paddingTop = '6px'
          }
        } else {
          if (groupBody) {
            groupBody.style.height = '0px'
            groupBody.style.paddingTop = '0px'
          }
        }
      })
    },
    filterCurrency(id) {
      return id ? symbolCurrency(id) : 'R$'
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toUpperCase()) {
          case "APPROVED":
            if (this.isHeaven) {
              r = { text: this.$t('views.cobranca.text_2410'), class: "is-green heaven" }
            } else {
              r = { text: this.$t('views.cobranca.text_2410'), class: "is-green" }
            }
            break
          case "PENDING":
            r = { text: this.$t('views.cobranca.text_2411'), class: "is-warning" }
            break
          case "DISAPPROVED":
            r = { text: this.$t('views.cobranca.text_2412'), class: "is-red" }
            break
          default:
            r = { text: status, class: "is-black" }
            break
        }
        return r
      } else return { text: status, class: "is-black" }
    },
    validStatusNew() {
      let offers = this.offers;
      let result = offers.filter((x) => x.status === 'PENDING');
      let validateSatus = this.product.status === 'PENDING' || this.product.status === 'DISAPPROVED' || this.product.status === 'REQUESTED'
      return validateSatus
        ? this.$t('views.cobranca.text_2408')
        : JSON.stringify(result) === '[]' ? false : this.$t('views.cobranca.text_2409')
    },
    validStatus(status) {
      var offers = this.offers
      var result = offers.filter(x => x.status === 'PENDING' && x.default === 1)
      return JSON.stringify(result) === '[]'
        ? (status === 'PENDING' || status === 'DISAPPROVED' ? false : true)
        : false
    },
    voltar() {
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
      this.reset()
    },
    changeParcelasCard(value) {
      let totalParcelas = this.max_installments

      if (totalParcelas < this.pre_selected_installment) this.pre_selected_installment = null

      this.option_pre_selected_installment = [
        { value: null, text: this.$t('views.cobranca.text_2407'), disabled: true }
      ]

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
        }
      }
    },
    changeParcelasSub() {
      var period = this.period
      this.fixed_installments = null

      period = 365
      if (period === 30) {
        this.pre_selected_installment = null
        return
      }

      let totalParcelas = 0
      switch (period) {
        case 90:
          totalParcelas = 3
          break
        case 180:
          totalParcelas = 6
          break
        case 365:
          totalParcelas = 12
          break
      }

      this.option_fixed_installments = [
        { value: null, text: this.$t('views.cobranca.text_2404') }
      ]
      this.subscription_installments = [
        { value: null, text: this.$t('views.cobranca.text_2405'), disabled: true },
        { value: 1, text: this.$t('views.cobranca.text_2406') }
      ]
      this.option_pre_selected_installment = [
        { value: null, text: this.$t('views.cobranca.text_2407'), disabled: true }
      ]

      for (let i = 1; i <= totalParcelas; i++) {
        if (i > 1) {
          this.option_fixed_installments_.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
          this.option_pre_selected_installment.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
          this.subscription_installments.push({
            value: i,
            text: `${this.$t('views.cobranca.text_2403')} ${i}x`
          })
        }
      }
    },
    makeNew(item) {
      this.$emit('changeValue', true)
      this.name = this.product.name
    },
    newOffer(item) {
      this.changeParcelasSub()
      this.groupBatch = item;
      this.make = true;
    },
    filterDefault() {
      const offer = this.offers.find(o => o.default === 1)
      this.defaultValue = offer.amount
      this.defaultValueCurrency = offer.currency_id
    },
    changeDefault(item) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t('views.cobranca.text_2168'), {
          title: this.$t('views.cobranca.text_2169'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true
        })
        return
      }
      item.id = `${this.$route.params.id_product}/offers/${item.id}`
      delete item.created_at
      delete item.updated_at
      item.default = 1

      this.loading = true
      serviceProduct
        .update(item)
        .then(() => {
          this.resolveResponse()
          this.filterDefault()
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    deleteOffer(item, index, group, indexGroup) {
      if (item.default === 1) {
        this.$bvToast.toast(this.$t('views.cobranca.text_911'), {
          title: this.$t('views.cobranca.text_2169'),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      Vue.swal({
        title: this.$t('views.cobranca.text_897'),
        text: `${this.$t('views.cobranca.text_912')} ${item.name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('views.cobranca.text_2395'),
        cancelButtonText: this.$t('views.cobranca.text_2167'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true

          serviceProduct
            .destroy({ id: `${this.$route.params.id_product}/offers/${item.id}` })
            .then(() => {
              this.$bvToast.toast(this.$t('views.cobranca.text_913'), {
                title: this.$t('views.cobranca.text_2169'),
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true
              })
              group.tickets.splice(index, 1)
              if (group.length === 0) {
                this.batch_groups.splice(indexGroup, 1)
              }
              this.fetchBatchs()
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false })
        }
      });
    },
    getMethod(method) {
      if (method === 'ALL') return 'Todos'
      if (method === null) return ''

      method = method.split(',')
      var array = []
      for (let i = 0; i < method.length; i++) {
        const element = this.textMethod(method[i])
        array.push(element)
      }
      return array.toString()
    },
    reset() {
      this.editItem = null
      this.max_boleto_installments = 0
      this.no_interest_installments = false
      this.allowed_coupon = false
      this.amount = ''
      this.name = ''
      this.currencie_id = 1
      this.method = []
      this.max_installments = 12
      this.fixed_installments = null
      this.custom_charges = []
      this.custom_charges_is_available = false
      this.custom_charges_bkp = false
    },
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    cancel() {
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
      this.fetchOffers()
      this.reset()
    },
    resetFirstCharge() {
      if (this.custom_charges_is_available) {
        this.custom_charges_bkp = this.custom_charges;
        this.custom_charges = [];
        this.custom_charges_is_available = false;
        this.$bvToast.toast(this.$t('views.cobranca.plan_of_payment_has_unactivated'), {
          title: this.$t('views.cobranca.plan_of_payment'),
          variant: "warning",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    resetOptionsPlanOfPayment() {
      this.max_subscription_installments = 1
      this.fixed_installments = null
      this.pre_selected_installment = null
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          var method = this.method;
          var array_method = [];
          for (let i = 0; i < method.length; i++) {
            const element = method[i];
            if (this.product.format == "PHYSICALPRODUCT" && element.value == "PAYPAL") {
              this.$bvToast.toast(
                this.$t("views.seller.edit_product.text_3000"),
                {
                  title: "Paypal",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              array_method = [];
              return
            } else {
              array_method.push(element.value);
            }
          }

          // if (!array_method.join().length) {
          //   this.$bvToast.toast(this.$t('views.cobranca.text_2402'), {
          //     title: this.$t('views.cobranca.text_2169'),
          //     variant: "danger",
          //     autoHideDelay: 5000,
          //     appendToast: true,
          //   });
          //   this.submit = false;
          //   this.loading = false;
          //   return;
          // }

          if (this.co_sellers_list && this.co_sellers_list.length) {
            if (this.amount < 15 && this.offers[0].method != "FREE") {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 15,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
          } else {
            if (this.amount < 9 && this.offers[0].method != "FREE") {
              this.$bvToast.toast(`${this.$t("views.cobranca.text_2400")} 9,00`, {
                title: this.$t('views.cobranca.text_2169'),
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.submit = false;
              this.loading = false;
              return;
            }
          }
          let data = {
            id: `${this.$route.params.id_product}/offers/`,
            method: array_method.join(),
            name: this.name,
            currency_id: this.isHeaven ? 2 : 1,
            amount: this.amount,
            fixed_installments: this.fixed_installments,
            no_interest_installments: this.no_interest_installments,
            allowed_coupon: this.allowed_coupon,
            default: 0,
            pre_selected_installment: this.pre_selected_installment,
            allow_offer_link: this.allow_offer_link,
            offer_group_id: this.groupBatch.id
          };

          if (this.custom_charges) {
            if (this.custom_charges.length > 0 && this.custom_charges_is_available) {
              let invalidCustomFind = false;
              this.custom_charges.map(custom_charge => {
                if (parseFloat(custom_charge.amount || 0) < 1) {
                  invalidCustomFind = true;
                }
              })
              if (invalidCustomFind) {
                this.$bvToast.toast(
                  this.$t("views.cobranca.min_charge_amount"),
                  {
                    title: this.$t("views.cobranca.plan_of_payment"),
                    variant: "warning",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                this.submit = false;
                this.loading = false;
                return;
              }
              data.custom_charges = this.custom_charges;
            }
          }

          if (this.fixed_installments) {
            data.pre_selected_installment = null;
          }

          data.max_installments = this.max_installments || 12;

          // validação parcelas de assinatura
          this.max_subscription_installments = 1;

          data.max_boleto_installments = 0;

          if (this.editItem) {
            delete data.offer_group_id

            data.id = `${this.$route.params.id_product}/offers/${this.editItem.id}`
            serviceProduct
              .update(data)
              .then(() => this.resolveResponse())
              .catch(err => console.error(err))
              .finally(() => { this.submit = false; this.loading = false; })
            return
          }

          serviceProduct
            .createId(data)
            .then(() => this.resolveResponse())
            .catch(err => console.error(err))
            .finally(() => { this.submit = false; this.loading = false; })
        }
      });
    },
    async resolveResponse() {
      this.$bvToast.toast(this.$t('views.cobranca.text_914'), {
        title: this.$t('views.cobranca.text_2169'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true
      })
      this.reset()
      await this.fetchOffers()
      await this.fetchBatchs()
      this.$validator.pause()
      this.make = false
      this.$emit("changeValue", false)
    },
    async changeCurrency(value, mounted) {
      await this.forceRerender()
      this.money.prefix = symbolCurrency(value)

      serviceCurrency
        .read({ id: '/payment_methods/' + value })
        .then((response) => {
          if (mounted === undefined) {
            this.method = [];
          }
          this.methods_ = [];
          response.forEach((element) => {
            this.methods_.push({
              value: textMethodType(element.payment_methods.name).toUpperCase(),
              name: this.textMethod(element.payment_methods.name).toUpperCase()
            })
          })
          this.forceRerender()
        })
        .catch(err => console.error(err))
    },
    fetchCurrency() {
      this.currencies = []

      serviceCurrency
        .read({ id: '/currencies' })
        .then((response) => {
          response.forEach(({ id, name }) => this.currencies.push({ id, name }))
        })
        .catch(err => console.error(err))
    },
    async fetchOffers() {
      this.loading = true

      await serviceProduct
        .read({ id: this.$route.params.id_product + '/offers' })
        .then(response => {
          this.offers = response
          this.filterDefault()
        })
        .catch(err => console.error(err))
        .finally(() => { this.loading = false })
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD": return this.$t('views.seller.edit_product.text_1112')
          case "CREDIT-CARD": return this.$t('views.seller.edit_product.text_1112')
          case "BANKTRANSFER": return this.$t('views.seller.edit_product.text_1113')
          case "CREDITCARD": return this.$t('views.seller.edit_product.text_1112')
          case "DEBITCARD": return this.$t('views.seller.edit_product.text_1114')
          case "debitcard": return this.$t('views.seller.edit_product.text_1114')
          case "TWO_CREDIT_CARDS": return this.$t('views.seller.edit_product.text_1115')
          case "DEBITCARD": return "CARTÃO DE DÉBITO"
          case "EFT": return "EFT"
          case "SPEI": return "SPEI"
          case "SENCILLITO": return "SENCILLITO"
          case "OXXO": return "OXXO"
          default: return method
        }
      } else return method;
    },
    addNewCustomCharge(firstAdd) {
      if (this.custom_charges.length === 4) {
        this.$bvToast.toast(
          this.$t('views.cobranca.limitCustomCharges'),
          {
            title: this.$t('views.cobranca.plan_of_payment'),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        return;
      }
      if (firstAdd) {
        this.custom_charges = [
          {
            sequence: 1,
            amount: '0'
          }
        ]
      } else {
        this.custom_charges.push({
          sequence: this.custom_charges.length + 1,
          amount: '0'
        })
      }

    },
    removeCustomCharge(index) {
      let newCustomCharges = [];
      this.custom_charges.map((c, i) => {
        let c_charge = c;
        if (i !== index) {
          newCustomCharges.push({
            ...c_charge,
            sequence: newCustomCharges.length + 1
          })
        }
      })
      this.custom_charges = newCustomCharges;
    },
    restoreCustomCharge() {
      if (this.custom_charges_bkp) {
        this.custom_charges = this.custom_charges_bkp;
      }
    },
    updateBatchOrderGroup() {
      const updateOrderGroup = this.batch_groups.map((item, index) => {
        item.order = index + 1
        return {
          id: item.id,
          old_order: item.order,
          new_order: index + 1
        }
      });
      this.dragGroup = false
      servicePresentialEvent.create(updateOrderGroup, '/offer-groups/order')
        .catch(err => console.error(err))
    },
    updateTicketsOrder(group) {
      const updateTickets = group.tickets.map((item, index) => {
        item.batch_order = index + 1
        return {
          ...item,
          new_order: index + 1
        }
      });
      this.drop = false
      servicePresentialEvent.create(updateTickets, '/order')
        .catch(err => console.error(err))
    },
    async fetchBatchs() {
      return await servicePresentialEvent.read({ id: `/offer-groups/${this.$route.params.id_product}` })
        .then(res => {
          if (this.product.product_type_id === 3) {
            res.sort((a, b) => {
              if (a.order < b.order) return -1
              if (a.order > b.order) return 1
              return 0
            })
            res.forEach(x => {
              x.tickets.sort((a, b) => {
                if (a.batch_order < b.batch_order) return -1
                if (a.batch_order > b.batch_order) return 1
                return 0
              })
            })
          }
          this.batch_groups = res
        })
        .catch(err => console.error(err))
    },
  },
  mounted() {
    this.methods_ = this.methods;
    this.fetchCurrency();
    this.fetchBatchs();
    if (this.isHeaven) {
      this.changeCurrency(2, "MOUNTED");
      this.defaultValueCurrency = '$'
    } else {
      this.changeCurrency(1, "MOUNTED");
    }
  },
}
</script>

<style lang="scss" scoped>
.add-ticket-text {
  color: #81858E;
  font-size: 16px;
  font-weight: 400;
}

.add-ticket-text:hover {
  color: #62656d;
}

.btn-plus {
  cursor: pointer;
  width: 255px;
  border: 0 solid transparent;
  border-radius: 50px;
  padding: 3px 0;
}

.overflow-auto {
  overflow: auto;
}

.group-draggdrop {
  height: 88px;
}

.draggdrop-body {
  overflow: hidden;
  height: 0;
}

.custom-efect {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.custom-expanded {
  border: solid 0 transparent;
  border-radius: 10px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.custom-expanded:hover {
  background-color: #F1F2F3;
}

.batch-name {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

.ticket-name {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.ticket-amount {
  color: #058A2A;
  font-size: 15px;
  font-weight: 400;
}

.overflow-table {
  width: 849px;
}

@media (max-width: 1200px) {
  .overflow-table {
    width: 700px;
  }
}

@media (max-width: 1000px) {
  .overflow-table {
    overflow-x: auto;
    width: 600px;
  }
}

@media (max-width: 800px) {
  .overflow-table {
    overflow-x: auto;
    width: 500px;
  }
}

@media (max-width: 600px) {
  .overflow-table {
    overflow-x: auto;
    width: 400px;
  }
}

@media (max-width: 400px) {
  .overflow-table {
    overflow-x: auto;
    width: 300px;
  }
}

h5,
p {
  margin: 0;
}

h5 {
  margin-bottom: 20px;
  color: #2133d2;
  font-weight: 600;
  font-size: 14px;
}

p {
  font-weight: normal;
  font-size: 13px;
  color: #81858e;
}

h2 {
  font-weight: 500;
  font-size: 24px;
  color: #333;
}

#id_venda {
  position: relative;
}

.copy-link {
  width: 15px;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}

.copy-link:hover {
  filter: invert(0);
}

#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}

.is-green::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #00E4A0 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-warning::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #FFCB37 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-black::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #333 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 64px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.is-red::before {
  content: "" !important;
  width: 6px !important;
  height: 6px !important;
  background: #FF0C37 !important;
  border-radius: 50% !important;
  display: block !important;
  position: absolute !important;
  left: 58px !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}


.text-table {
  font-weight: normal !important;
  font-size: 13px !important;
  color: #333333;
}

.default {
  background: rgba(0, 228, 160, 0.1);
  border-radius: 5px;
  height: 27px;
  text-align: center;
  padding: 4px 1px;
  position: relative;
  left: -10px;
  color: $base-color !important;
  font-weight: 500 !important;
  margin-right: 0 !important;
  width: 110px;
}

.default.heaven {
  background: $base-color-heaven-light;
  color: $base-color-heaven !important;
}

.type {
  display: grid;
  grid-template-columns: 60px 4fr 60px;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid 1px #ededf0;

  h6 {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
}

.info-value {
  margin-top: 25px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.arrow {
  position: relative;
  left: -15px;
}

.pointer {
  cursor: pointer;
}

.value-base {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;

  svg {
    margin-left: 10px;
  }
}

.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-footer {
  display: flex;
  justify-content: flex-end;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

.plan-payment-container {
  display: flex;
  width: 100%;
  color: #333333;
}

.charge-container {
  display: flex;
  min-width: 100% !important;
  margin-top: 12px;
  flex-direction: column;
  padding-bottom: 24px;
}

.charge-row {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
}

.charge-info {
  width: 236px;
  margin-top: 35px;
  background: rgba(20, 0, 255, 0.05);
  border-radius: 5px;
  padding: 20px;
}

.charge-info-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  /* or 20px */


  color: #1400FF;
}

.font-header-charge {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 20px */


  color: #333333;
}

.info-checkbox {
  cursor: pointer;
  font-weight: 600;
  color: #333;
}
</style>
