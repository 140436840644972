<script setup>
import { inject, ref, onMounted, nextTick, watch } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const selectedTab = inject("selectedTab");
const setSelectedTab = inject("setSelectedTab");

const isActiveTrigger = ref(true);

const tabRef = ref(null);

async function trigger() {
  if (selectedTab.value === props.value) return;

  isActiveTrigger.value = false;
  setSelectedTab(props.value);
  nextTick(() => updateTabActive());
}

const updateTabActive = async () => {
  setTimeout(()=>{
    const tabActive = document.querySelector(".tabActive");
    const activeTab = tabRef.value;
    if (activeTab && tabActive) {
      
      tabActive.style.width = `${activeTab.offsetWidth}px`;
      tabActive.style.left = `${activeTab.offsetLeft}px`;
  
      if (tabActive.offsetWidth === 0 && isActiveTrigger.value) {
        tabRef.value.classList.add("active-color");
      } else {
        tabRef.value.classList.remove("active-color");
      }
    }
  },200)
};

onMounted(() => {
  if (selectedTab.value === props.value) {
    nextTick(() => updateTabActive());
  }
});

watch(selectedTab, (newValue) => {
  if (newValue === props.value) {
    nextTick(() => updateTabActive());
  }
});
</script>

<template>
  <button ref="tabRef" :class="{ active: selectedTab === props.value }" @click="trigger">
    <slot />
  </button>
</template>

<style scoped lang="scss">
button {
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-0) var(--spacing-2-5);
  height: var(--h-7);

  border: none;
  border-radius: var(--radius-4xl);

  color: #999e9d;
  font-weight: var(--weight-semibold);
  font-size: var(--font-sm);
  text-wrap: nowrap;

  transition: all 0.3s ease;

  &.active {
    color: var(--text);
  }
}
</style>
