<script setup>
import { useStore } from "@/utils/useStore";
import { computed } from "vue";
import { useI18n } from "@/utils/useI18n";
import moment from "moment";

const store = useStore();
const i18n = useI18n();

const getOpportunitySelected = computed(
  () => store.getters["opportunities/getOpportunitySelected"]
);

function getSaleMethod(sale) {
  if (!sale) return "";

  const texts = {
    CREDIT_CARD: i18n.t("opportunities.details.saleMethods.CREDIT_CARD"),
    DEBITCARD: i18n.t("opportunities.details.saleMethods.DEBITCARD"),
    TWO_CREDIT_CARDS: i18n.t("opportunities.details.saleMethods.TWO_CREDIT_CARDS"),
    BOLETO: i18n.t("opportunities.details.saleMethods.BOLETO"),
    PIX: i18n.t("opportunities.details.saleMethods.PIX"),
  };

  return texts[sale];
}

function getOriginText(origin) {
  const texts = {
    PIX_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    BOLETO_NOT_PAID: i18n.t("opportunities.origin.NOT_PAID"),
    CARD_DECLINED: i18n.t("opportunities.origin.CARD_DECLINED"),
    INSUFICIENT_BALANCE: i18n.t("opportunities.origin.INSUFICIENT_BALANCE"),
    ABANDONED_CART: i18n.t("opportunities.origin.CHECKOUT_ABANDONED"),
  };
  return texts[origin] || origin;
}
</script>

<template>
  <section>
    <div v-if="getOpportunitySelected?.payment_method">
      <p>{{ $t("opportunities.details.payment_method") }}</p>
      <span>  {{ getSaleMethod(getOpportunitySelected.payment_method) }} </span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.reasonRefusal") }}</p>
      <span class="reasonRefusal">{{ getOriginText(getOpportunitySelected?.reason) }} </span>
    </div>

    <div>
      <p>{{ $t("opportunities.details.attemptDate") }}</p>

      <span>
        {{
          moment(
            getOpportunitySelected.paymentCreatedAt || getOpportunitySelected.leadUpdated
          ).format($t("locale_date.formats.shortYear"))
        }}
      </span>
    </div>
  </section>
</template>

<style lang="scss" scoped>
section {
  display: grid;
  gap: 15px;
  margin: 20px 0 40px 0;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: var(--font-sm);
      font-weight: var(--weight-normal);
      color: var(--gray-200);
    }
    span {
      font-size: var(--font-sm);
      font-weight: var(--weight-semibold);
      color: var(--text);
    }
    .reasonRefusal {
      color: #ff4820;
    }
  }
}
</style>
