<template>
  <div class="container">
    <BaseHeader :title="$t('views.seller.links.text_1125')" />
    <b-tabs
      class="mt-5 mb-5"
      :class="{'heaven': isHeaven}"
      content-class="mt-3"
      nav-class="TabHeader"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      @activate-tab="tabSelect"
    >
      <!-- Seller -->
      <b-tab :title="$t('views.seller.links.text_1607')" :active="!comingAffiliate" :class="{'heaven': isHeaven}">
        <b-container class="mt-1 p-5 wrapper container-item">
          <!-- Loading -->
          <div class="d-flex justify-content-center" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <!-- Seller Products -->
          <b-row v-if="!loading">
            <b-col>
              <h6 class="mb-3" :class="{'heaven': isHeaven}">{{ $t('views.seller.links.text_1126') }}</h6>
              <b-form-group label="">
                <multiselect
                  v-model="product_id"
                  :placeholder="$t('views.seller.links.text_1127')"
                  label="name"
                  :name="`link-${productsSeller.id}`"
                  track-by="id"
                  :options="productsSeller"
                  :multiple="false"
                  :taggable="false"
                  :class="{'heaven': isHeaven}"
                >
                  <template v-slot:noResult>
                    <span>{{ $t('views.seller.links.text_1128') }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="product_id">
            <!-- Loading -->
            <div class="d-flex justify-content-center" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <!-- Add Link -->
            <b-row class="mt-3" v-if="!loading && (createLink || !$store.getters.setPanel || ($store.getters.setPanel && linkCreate))">
              <b-col>
                <BaseButton
                  variant="info"
                  v-b-modal.links-new
                  class="btn-adicionar"
                  @click="dataEdit = null"
                  >{{ $t('views.seller.links.text_1129') }}</BaseButton
                >
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <!-- Sales Page -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1130')">
                  <a
                    v-if="salesPage"
                    :href="salesPage"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(salesPage) }}</a
                  >
                  <p v-if="!salesPage" class="p-editado">
                    {{ $t('views.seller.links.text_1131') }}
                  </p>
                  <span
                    @click="copyAddress(salesPage)"
                    class="btn p-2"
                    v-if="!load"
                  >
                    <img
                      src="@/assets/img/icons/copy.svg"
                      class="copy-link"
                      v-if="salesPage"
                    />
                  </span>
                </b-form-group>
              </b-col>
              <!-- Checkout Page -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1608')">
                  <a
                    :href="eventTickets
                      ? baseURL + '/pre-checkout/' + product.id
                      : baseURL + '/' + product.id
                    "
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(eventTickets
                      ? baseURL + '/pre-checkout/' + product.id
                      : baseURL + '/' + product.id
                    ) }}</a
                  >
                  <span
                    v-if="!load"
                    @click="copyAddress(baseURL + '/' + product.id)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link"
                  /></span>
                </b-form-group>
              </b-col>
              <!-- Recruitment Link -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1132')">
                  <a
                    data-anima="bottom"
                    v-if="link_afiliado == '---'"
                    href="#"
                    class="link"
                    @click.prevent="affiliateLink"
                    :class="{ carregando: load }"
                    >{{ $t('views.seller.links.text_1133') }}</a
                  >
                  <a
                    data-anima="bottom"
                    v-if="link_afiliado != '---'"
                    :href="link_afiliado"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(link_afiliado) }}</a
                  >
                  <span
                    @click="copyAddress(link_afiliado)"
                    class="btn p-2"
                    v-if="!load && link_afiliado != '---'"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Offer Links -->
            <template v-if="producHasOffers || (producHasOffers == 3)"> 
              <b-row class="my-2 outros">
                <b-col>
                  <h6 class="outros-links">{{ $t('views.seller.links.text_1609') }}</h6>
                </b-col>
              </b-row>
              <b-row class="mb-4" v-if="links.length >= 1 && !isMobile">
                <b-col cols="12" md="6" class="sub-titulo"> {{ $t('views.seller.links.text_1610') }} </b-col>
                <b-col cols="12" md="6" class="sub-titulo"> URL </b-col>
              </b-row>
              <template v-for="item in offers">
                <b-row
                  :key="item.id"
                  class="link-extra"
                >
                  <b-col cols="12" md="6" class="titulo-link">
                    {{ item.name }}
                  </b-col>
                  <b-col cols="12" md="6">
                    <a
                      :href="baseURL +'/'+ item.product_id + '/offer/'+ item.hash"
                      target="_blank"
                      class="link"
                      :class="{ carregando: load }"
                      >{{ cutText(baseURL +'/'+ item.product_id + '/offer/'+ item.hash) }}</a
                    >
                    <span
                      v-if="!load"
                      @click="copyAddress(baseURL +'/'+ item.product_id + '/offer/'+ item.hash)"
                      class="btn p-2"
                    >
                      <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                    </span>
                  </b-col>
                </b-row>
              </template>
            </template>
            <!-- Other Links -->
            <b-row class="my-2 outros">
              <b-col>
                <h6 class="outros-links">{{ $t('views.seller.links.text_1611') }}</h6>
              </b-col>
            </b-row>
            <b-row class="mb-4" v-if="links.length >= 1 && !isMobile">
              <b-col cols="12" md="6" class="sub-titulo"> {{ $t('views.seller.links.text_1612') }} </b-col>
              <b-col cols="12" md="6" class="sub-titulo"> URL </b-col>
            </b-row>
            <template v-for="item in links">
              <b-row
                v-if="item.name != 'MAIN'"
                :key="item.id"
                class="link-extra"
              >
                <b-col cols="12" md="6" class="titulo-link">
                  {{ item.name }}
                </b-col>
                <b-col cols="12" md="6">
                  <a
                    :href="baseURL + '/redirect/' + item.id"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ redirectLink(item) }}</a
                  >
                  <span
                    v-if="!load"
                    @click="copyAddress(baseURL + '/redirect/' + item.id)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                  <span
                    v-if="!load && item.is_bump === 1 && (!$store.getters.setPanel || ($store.getters.setPanel && linkEdit))"
                    @click="editItem(item)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/editar.svg" class="copy-link" />
                  </span>
                  <span
                    v-if="!load && (!$store.getters.setPanel || ($store.getters.setPanel && linkDelete))"
                    class="btn p-2"
                    @click="removeLink(item.id, false)"
                  >
                    <img
                      src="@/assets/img/icons/lixeira.svg"
                      class="copy-link"
                    />
                  </span>
                </b-col>
              </b-row>
            </template>
          </template>
        </b-container>
      </b-tab>
      <!-- Affiliated -->
      <b-tab v-if="(!$store.getters.setPanel || ($store.getters.setPanel && affiliateRead))" :title="$t('views.seller.links.text_1141')" :active="comingAffiliate">
        <b-container class="mt-1 p-5 wrapper container-item">
          <!-- Affiliated Products -->
          <b-row>
            <b-col>
              <h6 class="mb-3" :class="{'heaven': isHeaven}">{{ $t('views.seller.links.text_1135') }}</h6>

              <b-form-group label="">
                <multiselect
                  v-model="product_id_afiliado"
                  id="ajax"
                  label="name"
                  track-by="id"
                  :placeholder="$t('views.seller.links.text_1127')"
                  :selectLabel="$t('seller.sales.text_2618')"
                  :name="`link-${productsAffiliate.id}`"
                  :options="productsAffiliate"
                  :multiple="false"
                  :searchable="true"
                  :taggable="false"
                  :internal-search="false"
                  :clear-on-select="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :class="{'heaven': isHeaven}"
                  :loading="loading"
                  :show-no-results="true"
                  :hide-selected="true"
                  @search-change="aux_affiliate_product_debounce"
                >
                  <template v-slot:afterList>
                    <div v-observe-visibility="reachedEndOfList" />
                  </template>
                  <template v-slot:noOptions>
                    <span>{{ $t("seller.sales.text_2632") }}</span>
                  </template>
                  <template v-slot:noResult>
                    <span>{{ $t('views.seller.links.text_1128') }}</span>
                  </template>
                </multiselect>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="product_id_afiliado">
            <b-row class="mt-4">
              <!-- Sales Page -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1130')">
                  <a v-if="salesPage"
                    :href="salesPage ? salesPage + '/?a_id=' + userID : ''"
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{ cutText(salesPage ? salesPage + "/?a_id=" + userID : "") }}
                  </a>
                  <p v-if="!salesPage" class="p-editado">
                    {{ $t('views.seller.links.text_1131') }}
                  </p>
                  <span
                    v-if="!load && salesPage"
                    @click="copyAddress(salesPage + '/?a_id=' + userID)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
              <!-- Checkout Page -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('views.seller.links.text_1608')">
                  <a
                    :href="eventTickets
                      ? baseURL + '/pre-checkout/' + product.id + '/?a_id=' + userID
                      : baseURL + '/' + product.id + '/?a_id=' + userID
                    "
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{ cutText(eventTickets
                      ? baseURL + '/pre-checkout/' + product.id + '/?a_id=' + userID
                      : baseURL + '/' + product.id + '/?a_id=' + userID) }}
                  </a>
                  <span
                    v-if="!load"
                    @click="copyAddress(baseURL + '/' + product.id + '/?a_id=' + userID)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Offers links -->
            <template v-if="allowOfferLinks">
              <b-row class="my-2 outros">
                <b-col>
                  <h6 class="outros-links">{{ $t('views.seller.links.text_1609') }}</h6>
                </b-col>
              </b-row>
              <b-row class="mb-4">
                <b-col cols="12" md="6" class="sub-titulo"> {{ $t('views.seller.links.text_1610') }} </b-col>
                <b-col cols="12" md="6" class="sub-titulo"> URL </b-col>
              </b-row>
              <b-row
                v-for="item in offers.filter(item => Boolean(item.allow_offer_link))"
                :key="item.id"
                class="link-extra"
              >
                <b-col cols="12" md="6" class="titulo-link">
                  {{ item.name }}
                </b-col>
                <b-col cols="12" md="6">
                  <a
                    :href="baseURL +'/'+ item.product_id + '/offer/'+ item.hash + '/?a_id=' + userID"
                    target="_blank"
                    class="link"
                    :class="{ carregando: load }"
                    >{{ cutText(baseURL +'/'+ item.product_id + '/offer/'+ item.hash + '/?a_id=' + userID) }}</a
                  >
                  <span
                    v-if="!load"
                    @click="copyAddress(baseURL +'/'+ item.product_id + '/offer/'+ item.hash + '/?a_id=' + userID)"
                    class="btn p-2"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-col>
              </b-row>
            </template>
            <!-- Other Links -->
            <b-row class="my-3 outros">
              <b-col>
                <h6 class="outros-links">{{ $t('views.seller.links.text_1611') }}</h6>
              </b-col>
            </b-row>
            <template v-for="item in links">
              <b-row v-if="item.name != 'MAIN'" :key="item.id">
                <b-col cols="12" class="outro-item">
                  <p class="campanha_nome">{{ item.name }}</p>
                  <a :href="baseURL + '/redirect/' + item.id + '/?a_id=' + userID"
                    class="link"
                    target="_blank"
                    :class="{ carregando: load }"
                  >
                    {{ cutText(baseURL + "/redirect/" + item.id + "/?a_id=" + userID) }}
                  </a>
                  <span
                    v-if="!load"
                    class="btn p-2"
                    @click="copyAddress(baseURL + '/redirect/' + item.id + '/?a_id=' + userID)"
                  >
                    <img src="@/assets/img/icons/copy.svg" class="copy-link" />
                  </span>
                </b-col>
              </b-row>
            </template>
          </template>
        </b-container>
      </b-tab>
    </b-tabs>
    
    <NovoLink
      v-if="(!$store.getters.setPanel || ($store.getters.setPanel && linkCreate))"
      :checkouts="checkouts"
      :isPhysical="isPhysical"
      :product_id="product.id"
      :product_type="product.type"
      :product_name="product.name"
      :product_format="product.product_type_id"
      :defaultOfferIsFree="defaultOfferIsFree"
      :product_installments="validItem(product)"
      :productsS="productsSeller"
      :productsA="productsAffiliate"
      :type="comingAffiliate"
      :_cupom="cupom"
      :cupom_name="cupom_name"
      :has_orderbump="has_orderbump"
      :all_edit="dataEdit"
      :linkCreate="linkCreate"
      :linkEdit="linkEdit"
      :linkDelete="linkDelete"
      :checkoutRead="checkoutRead"
      @close="fetchLinks"
    />
    <CallEvaluation />
  </div>
</template>

<script>
import Vue from 'vue'
import NovoLink from '@/components/Seller/Links/modals/new'
import Multiselect from 'vue-multiselect'
import Cookies from "js-cookie"
import _ from "lodash";
import { mapGetters } from "vuex";

import ProductService from '@/services/resources/ProductService'
import CheckoutService from '@/services/resources/CheckoutService'
import AffiliateService from '@/services/resources/AffiliateService'
import LinkService from '@/services/resources/LinkService'

const serviceProducts = ProductService.build()
const serviceCheckouts = CheckoutService.build()
const serviceAffiliate = AffiliateService.build()
const serviceLinks = LinkService.build()

export default {
  components: {
    NovoLink,
    Multiselect,
  },
  data() {
    return {
      dataEdit: null,
      submit: false,
      loading: false,
      comingAffiliate: false,
      has_orderbump: false,
      cupom: null,
      cupom_name: null,
      has_cupom: false,
      product_id: null,
      product_id_afiliado: null,
      links: [],
      offers:[],
      products: [],
      product: {
        sales_page: "",
        slug: "",
      },
      productsSeller: [],
      productsAffiliate: [],
      load: true,
      link_afiliado: "---",
      client: {
        width: 0,
      },
      maxLength: 48,
      checkouts: [],
      createLink: false,
      linkCreate: false,
      linkEdit: false,
      linkDelete: false,
      checkoutRead: false,
      affiliateRead: false,
      searchAfiliateProduct: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 15,
        totalData: 0
      },
      search: "",
    };
  },
  computed: {
    ...mapGetters(["getFlags"]),
    producHasOffers(){
      if(this.getFlags.includes("feature_tickets")){
        if(this.product?.product_type_id == 3) return false;
        return true;
      }
      return true;
    },
    allowOfferLinks(){
      return this.offers.some(item => Boolean(item.allow_offer_link));
    },
    eventTickets() {
      if (this.getFlags.includes("feature_tickets") && this.product.product_type_id == 3) return true;
      return false;
    },
    isMobile() {
      if (this.client.width <= 667) this.maxLength = 33;
      return this.client.width <= 768;
    },
    baseURL() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_FAST_PAGE;
    },
    baseAdmURL() {
      return this.isHeaven ? process.env.VUE_ADM_HEAVEN_PAGE : process.env.VUE_ADM_PAGE;
    },
    username() {
      return this.$store.getters.getProfile.username;
    },
    userID() {
      return this.$store.getters.getProfile.id;
    },
    salesPage() {
      let r = "";
      for (var i = 0; i < this.links.length; i++) {
        if (this.links[i].url && this.links[i].name == "MAIN") {
          r = this.baseURL + "/redirect/" + this.links[i].id;
        }
      }
      return r;
    },
    isPhysical() {
      return this.product.format === 'PHYSICALPRODUCT';
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    defaultOfferIsFree() {
      if(this.offers && this.offers.length) {
        if(this.offers.some(x => x.method === 'FREE' && x.default)) {
          return true
        } else return false
      } else return false
    }
  },
  watch: {
    isMobile(value) {
      if (value) {
        this.maxLength = 33;
      } else this.maxLength = 48;
    },
    product_id() {
      if (!this.has_cupom) {
        this.selectProduct(false);
      } else {
        this.fetchLinks();
        this.fetchOffers();
        this.createLink = true;
        setTimeout(() => {
          this.load = false;
          this.has_cupom = false;
        }, 500);
      }
    },
    product_id_afiliado() {
      this.selectProduct(true);
    },
  },
  methods: {
    async reachedEndOfList(reached) {
      if (reached) {
        if(this.pagination.totalData === this.productsAffiliate.length){
          return;
        }
        if(this.pagination.currentPage < this.pagination.totalPages){
          this.pagination.currentPage++;
        }
       await this.fetchAffiliateProducts(this.search);
      }
    },
    redirectLink(item) {
      let baseUrl = this.baseURL;
      if (!this.isHeaven && !item.url.includes(this.baseURL)) {
        baseUrl = process.env.VUE_SALES_FAST_PAGE;
      }
      return this.cutText(baseUrl + "/redirect/" + item.id)
    },
    validItem(product){
      // if(product.max_boleto_installments !== undefined && product.max_boleto_installments !== 0 && product.max_boleto_installments !== null || product.format === "PHYSICALPRODUCT"){
      //   return false;
      // }
      return true;
    },
    getParameterByName(name, url) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    editItem(item){
      this.fetchSellerProducts2();
      item.bump_id = this.getParameterByName('b_id', item.url);
      var myArray = this.productsSeller;
      // let UrlSplit = item.url.split('b_id');
      // let OrderBumpProductsIds = [];
      // console.log(UrlSplit)
      // UrlSplit.forEach((u, i) => {
      //   if(i > 0 && i < UrlSplit.length - 1) {
      //     console.log(i,u.substring(1, u.search('&')))
      //     OrderBumpProductsIds.push(u.substring(1, u.search('&')))
      //   } else if (i === UrlSplit.length - 1) {
      //     console.log(i,u.substring(1))
      //     OrderBumpProductsIds.push(u.substring(1))
      //   }
      // })
      this.cupom_name = this.getParameterByName('cupom', item.url);
      if(this.cupom_name){
        this.cupom = true;
      }
      var filter = myArray.filter(x => x.id === parseInt(item.bump_id));
      // var filter = myArray.filter(x => OrderBumpProductsIds.some(o => x.id === parseInt(o)))
      item.product_order_b = filter;
      this.dataEdit = item;
      this.$bvModal.show("links-new");
    },
    setProduct(reset, produtor = false) {
      if (!produtor) {
        if (this.$route.query.affiliation_id && !reset) {
          this.product_id_afiliado = {
            id: +this.$route.query.affiliation_id,
            name: this.$route.query.p_name,
          };
        } else if (reset) {
          this.product_id_afiliado = null;
        }
      } else {
        this.product_id = {
          id: +this.$route.query.p_id,
          name: this.$route.query.p_name,
          type: this.$route.query.p_type,
        };

        if (this.has_orderbump) {
          this.$bvModal.show("links-new");
          if (this.product_id.type == "SUBSCRIPTION") {
            this.$bvToast.toast(
              `${this.$t('views.seller.links.text_1613')} ${this.product_id.name} ${this.$t('views.seller.links.text_1136')}`,
              {
                title: "Link",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        }
      }
    },
    cutText(text) {
      if (text) {
        return text.length > this.maxLength
          ? text.substr(0, this.maxLength) + "..."
          : text;
      } else return text;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    affiliateLink() {
      if (this.product.id) {
        let data = {
          product_id: this.product.id,
          url: "invite",
        };

        serviceAffiliate
          .search(data)
          .then((response) => {
            this.link_afiliado = `https://adm.${this.isHeaven?'heaven':'greenn'}.${this.isHeaven?'me':'com.br'}/recrutamento/${response.hash}/?p_id=${this.product.id}`;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    removeLink(id, affiliate) {
      Vue.swal({
        title: this.$t('views.seller.links.text_1138'),
        text: this.$t('views.seller.links.text_1139'),
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('views.seller.links.text_1138'),
        cancelButtonText: this.$t('views.seller.links.text_1614'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: id,
          };

          serviceLinks.destroy(data).then(() => {
            if (affiliate) {
              this.selectProduct(true);
            } else this.selectProduct(false);

            this.$bvToast.toast(this.$t('views.seller.links.text_1140'), {
              title: "Link",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
        }
      });
    },
    copyAddress(link) {
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t('views.seller.links.text_1615'), {
        title: this.$t('views.seller.links.text_1616'),
        variant: "success",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    selectProduct(affiliate) {
      this.load = true;
      if (this.product_id) {
        let product = [...this.products].find((item) => {
          if (item.id == this.product_id.id) {
            return item;
          }
        });
        this.product = product;
      }
      if (affiliate) {
        if (this.product_id_afiliado) {
          let product = [...this.products].find((item) => {
            if (item.id == this.product_id_afiliado.id) {
              return item;
            }
          });
          this.product = product.product || product;
        }
      }
      this.fetchLinks();
      this.fetchOffers();
      this.verifyAfilliate();
      setTimeout(() => {
        this.load = false;
      }, 500);
    },
    verifyAfilliate() {
      if (this.userID === this.product.seller_id) {
        this.createLink = true;
      } else {
        this.createLink = false;
      }
    },
    tabSelect(newTabIndex) {
      this.product_id = null;
      this.product_id_afiliado = null;
      this.link_afiliado = "---";
      switch (newTabIndex) {
        case 0: {
          this.comingAffiliate = false;
          this.fetchSellerProducts();
          break;
        }
        case 1: {
          this.comingAffiliate = true;
          this.fetchAffiliateProducts();
          break;
        }
      }
    },
    fetchLinks() {
      let data = {
        product_id: this.product.id,
      };

      if(!this.product.id){
        return
      }

      serviceLinks.search(data).then((response) => {
        this.links = response;
      });
    },
    fetchOffers() {
      if(this.product.id) {
        let data = {
          id: this.product.id + "/offers",
        };
        serviceProducts
          .read(data)
          .then((response) => {
            this.offers = response;
          })
      }
    },
    fetchSellerProducts2() {
      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          this.productsSeller = [];
          response.forEach((element) => {
            this.productsSeller.push({
              id: element.id,
              name: element.name,
              type: element.type,
              max_boleto_installments: element.max_boleto_installments,
              format: element.format,
              offers: element.offers
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchSellerProducts() {
      this.loading = true;
      this.index = null;
      this.product = {
        sales_page: "",
        slug: "",
      };
      this.links = [];
      this.productsAffiliate = [];

      let data = {
        list: true,
      };

      serviceProducts
        .search(data)
        .then((response) => {
          this.productsSeller = [];
          this.products = response;
          this.products.forEach((element) => {
            this.productsSeller.push({
              id: element.id,
              name: element.name,
              type: element.type,
              max_boleto_installments: element.max_boleto_installments,
              format: element.format,
              offers: element.offers,
              product_type_id: element.product_type_id
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async aux_affiliate_product_debounce(query) {
      this.pagination.currentPage = 1;
      this.pagination.totalPages = 1;
      this.pagination.totalData = 0;
      this.searchAfiliateProduct = query;
      await this.debounce_affiliate_product();
    },
    debounce_affiliate_product: _.debounce(async function () {
      await this.fetchAffiliateProducts(this.searchAfiliateProduct);
    }, 500),
    async fetchAffiliateProducts(search = null) {
      if(this.loading) return
      this.loading = true;

      this.index = null;
      this.product = {
        sales_page: "",
        slug: "",
      };
      this.links = [];
      this.productsSeller = [];

      let data = {
        per_page: this.pagination.perPage,
        page: this.pagination.currentPage,
        notUseAuth: "true"
      }
      if(search) data.search = search
      this.search = search;

      await serviceAffiliate
        .search(data)
        .then((response) => {
          this.pagination.currentPage = response.current_page;
          this.pagination.totalPages = response.last_page;
          this.pagination.totalData = response.total;

          if(!response.data.length || search){
            this.products = [];
            this.productsAffiliate = [];
          }

          const tempAffiliate = [...this.productsAffiliate];

          response.data.forEach((element) => {
            if (!tempAffiliate.some(product => product.id === element.id)) {
              this.products.push(element);
              tempAffiliate.push({
                id: element.id,
                name: element.product.name,
                type: element.type,
              });
            }
          });
          tempAffiliate.sort((a, b) => a.id - b.id);
          this.productsAffiliate = tempAffiliate;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCheckouts() {
      return new Promise(async (resolve, reject) => {
        try {
          if (!this.$store.getters.setPanel || (this.$store.getters.setPanel && this.checkoutRead)) {
            const response = await serviceCheckouts.search();
            this.checkouts = response;
            resolve(response);
          } else {
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getPermissions() {
      return new Promise(resolve => {
        if(this.$store.getters.setPanel) {
          var dados = Cookies.get("setPanelPermission")
          if(dados) {
            dados = JSON.parse(dados)
            if(!dados.find((x) => x.name === "LinkRead")) {
              this.$router.push("/dashboard")
            }
            if(dados.find((x) => x.name === "LinkCreate")) this.linkCreate = true
            if(dados.find((x) => x.name === "LinkEdit")) this.linkEdit = true
            if(dados.find((x) => x.name === "LinkDelete")) this.linkDelete = true
            if(dados.find((x) => x.name === "CheckoutRead")) this.checkoutRead = true
            if(dados.find((x) => x.name === "AffiliateRead")) this.affiliateRead = true
          }
          resolve(dados)
        } else {
          resolve('não é subusuário')
        }
      })
    }
  },
  async mounted() {
    this.fetchSellerProducts();
    await this.getPermissions();
    await this.fetchCheckouts();
    await this.$store.dispatch('fetchFlags')

    // cupom
    if (typeof this.$route.query.c_id != "undefined") {
      this.setProduct("", true);
      const query = this.$route.query;

      this.has_cupom = true;

      this.cupom = {
        id: +query.c_id,
        name: query.c_name,
      };

      this.product = {
        id: +query.p_id,
        name: query.p_name,
        type: query.p_type,
      };

      this.$bvModal.show("links-new");
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="scss">
.link {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  width: 100%;
}
.copy-link {
  width: 15px;
  filter: invert(50%);
  cursor: pointer;
  transition: 0.3s ease;
}
.copy-link:hover {
  filter: invert(0);
}
.carregando {
  background: rgba(0, 0, 0, 0.03);
  color: transparent;
}
h6 {
  font-weight: 600;
  color: $base-color;
}
h6.heaven {
  color: $base-color-heaven;
}
.outros {
  padding-top: 30px;
  border-top: 1px solid #ededf079;
}
.outros-links {
  color: #333;
  font-size: 16px;
}
.wrapper {
  margin-top: -20px !important;
  z-index: 2;
}
.outro-item {
  margin-bottom: 7px;
}
.campanha_nome {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
.sub-titulo {
  font-weight: 600;
  color: #81858e;
  font-size: 14px;
}
.titulo-link {
  font-weight: 600;
  font-size: 14px;
}
.container-item {
  padding: 50px !important;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0;
  }
  .wrapper {
    padding: 30px 20px !important;
  }
  .container-item {
    padding: 30px !important;
    margin-bottom: 20px;
  }
  .tabs {
    margin: 40px 0 !important;
  }
  h6 {
    font-size: 15px;
  }
  .btn-adicionar {
    width: 100%;
    margin-top: -30px;
    margin-bottom: 10px;
  }

  .link-extra + .link-extra {
    margin-top: 15px;
  }
}
.p-editado {
  font-size: 14px;
  color: #81858e;
}

.form-control.heaven:hover {
  border-color: $base-color-heaven !important;
}

</style>
