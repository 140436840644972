<template>
  <b-overlay :show="pageLoading" spinner-variant="dark">
    <main class="update-product container">
      <!-- Header -->
      <BaseHeader :title="productName" :zIndex="0">
        <BaseButton
          variant="link-info"
          class="mt-4 mt-md-0"
          @click="$router.push('/produtos')"
          :style="dynamicHeight"
          >{{ $t("views.seller.edit_product.text_1000") }}</BaseButton
        >
      </BaseHeader>


      <!-- Content -->
      <PageNotFound v-if="errorInFetchProduct" :no_product="true" />

      <section v-else>
        <b-tabs
          pills
          fill
          nav-class="tabs-horizontal"
          class="tabs-content"
          active-nav-item-class="tab-active"
          v-if="hasProduct"
        >
          <b-tab
            v-for="(tab, index) in tabs.filter((item) => item.show)"
            :key="index"
            :active="tab.component === selectedTab.component"
            lazy
            @click="handleTabClick(tab)"
          >
            <template #title v-if="tab.component !== 'PhysicalProduct'">
              {{ tab.title }}
            </template>

            <template
              #title
              v-else-if="
                tab.component == 'PhysicalProduct' &&
                (product.product_type_id == 4 ||
                  product.format == 'PHYSICALPRODUCT')
              "
            >
              {{ tab.title }}
            </template>
          </b-tab>
        </b-tabs>
        <CustomColumns v-if="hasProduct">
          <component
            data-anima="left"
            :is="selectedTab.component"
            @finishValidation="update"
          ></component>
          <template #after>
            <section class="custom-columns-after">
              <BaseButton
                v-if="product.status === 'DISAPPROVED'"
                variant="info"
                @click="validate('DISAPPROVED')"
                class="mt-4 mt-md-0"
                >{{ $t("views.seller.edit_product.text_1002_1") }}
              </BaseButton>
              <product-info :status="productStatus" origin="edit" />
              <product-checklist
                v-if="!!productChecklist"
                :checklist="kycPersonalChecklist"
                @clicked="setTab"
                @update-product="updateProduct"
              />
            </section>
          </template>
        </CustomColumns>
      </section>

      <!-- Footer -->
      <footer
        class="update-product__footer"
        v-if="!errorInFetchProduct && hasProduct"
      >
        <section class="update-product__footer__links">
          <section
            class="update-product__footer__links__item"
            v-if="product.affiliation"
          >
            <p>{{ $t("views.seller.edit_product.text_2273") }}</p>
            <!-- Generate Affiliate Link -->
            <a
              data-anima="bottom"
              v-if="!affiliateLink"
              href="#"
              @click.prevent="fetchAffiliateLink"
              :class="{ isLoading: affiliateLoading }"
              >{{ $t("views.seller.edit_product.text_2274") }}
            </a>
            <!-- Affileate Link -->
            <a
              data-anima="bottom"
              v-if="affiliateLink"
              :href="affiliateLink"
              target="_blank"
              :class="{ isLoading: affiliateLoading }"
              >{{ affiliateLink }}
            </a>
          </section>
          <!-- Link Checkout -->
          <section class="update-product__footer__links__item">
            <p>{{ $t("views.seller.edit_product.text_2275") }}</p>
            <a
              :href="salesPage"
              target="_blank"
              :class="{ isLoading: isLoading }"
              >{{ salesPage }}</a
            >
          </section>
        </section>
        <!-- Save Edit -->
        <BaseButton
          :disabled="isLoading"
          variant="primary"
          class="mt-4 mt-md-0"
          :class="{ heaven: isHeaven }"
          v-show="!hideUpdateButton"
          @click="validate"
        >
          {{ $t("views.seller.edit_product.text_2276") }}
        </BaseButton>
      </footer>
    </main>

    <delivery-options
      v-if="!!hasProduct"
      :productId="productId"
      :product="product"
      :selectedTab="selectedTab.component"
      @finished="init"
    />
  </b-overlay>
</template>
<script>
// Utils
import Permissions from "@/utils/permissions.js";
import updatedFieldsProduct from "@/utils/updatedFieldsProduct.js";
import { EventBus } from "@/main.js";
import hasFlag from '@/utils/validateFlag.js'

// Components
import CustomColumns from "@/components/shared/CustomColumns.vue";
import ProductInfo from "../CreateProduct/ProductInfo.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import DeliveryOptions from "./DeliveryOptions.vue";
import ProductChecklist from "./ProductChecklist.vue";

// Components - Tabs
import BasicInformations from "./BasicInformations.vue";
import PhysicalProduct from "./PhysicalProduct.vue";
import ChargesAndOffers from "./ChargesAndOffers.vue";
import BatchesEvent from "@/views/Seller/Event/Event.vue";
import CoProductions from "./CoProductions.vue";
import OpportunityProduct from "./OpportunityProduct.vue";
import Affiliated from "./Affiliated.vue";
import Contents from "./Contents.vue";
import Integrations from "./Integrations.vue";
import Advanced from "./Advanced.vue";
import Mentoring from "./Mentoring.vue";
import Event from "./Event.vue";

// Vuex
import { mapActions, mapGetters } from "vuex";

// Services
import ProductTypesService from "@/services/resources/ProductTypes";
const typeService = ProductTypesService.build();

import CustomThankYouPages from "@/services/resources/CustomThankYouPages";
const serviceCustomThankYouPages = CustomThankYouPages.build();

import InfusionsoftService from "@/services/resources/InfusionsoftService";
const infusionsoftService = InfusionsoftService.build();

import ConnectionService from "@/services/resources/ConnectionService";
import { validateHttpUrl } from "../../../utils/validateHttpUrl";
const connectionService = ConnectionService.build();

export default {
  components: {
    CustomColumns,
    ProductInfo,
    ProductChecklist,
    PageNotFound,
    DeliveryOptions,

    // Tabs
    BasicInformations,
    PhysicalProduct,
    ChargesAndOffers,
    BatchesEvent,
    CoProductions,
    Affiliated,
    Contents,
    OpportunityProduct,
    Integrations,
    Advanced,
    Mentoring,
    Event,
  },
  data() {
    return {
      // Always start with the first element of the tabs array
      selectedTab: {
        title: this.$t("views.seller.edit_product.tabs.basic_informations"),
        component: "BasicInformations",
        show: Permissions.hasPermission("EditGeneralProduct"),
      },
      custom_thank_you_pages_data: [],
      status: null,
      Permissions,
    };
  },
  computed: {
    ...mapGetters([
      "product",
      "hasProduct",
      "productId",
      "selectedTabData",
      "productName",
      "productStatus",
      "affiliateLink",
      "affiliateLoading",
      "isLoading",
      "saleExpressRedirectLoading",
      "errorInFetchProduct",
      "hideUpdateButton",
      "productChecklist",
      "callValidateFields",
      "getKycPersonalChecklist",
      "getFlags",
      "getHasSearchedFlag",
      "getEventFildsResult",
    ]),
    dynamicHeight(){
      if(this.productName?.length > 43){
        return 'height:auto !important;'
      }
    },
    eventTickets() {
      if (this.getFlags.includes("feature_tickets") && this.product.product_type_id === 3) return true;
      return false;
    },
    kycPersonalChecklist() {
      return [...this.productChecklist]
    },
    hasReceipientDataFlag() {
      return hasFlag('recipient_data')
    },
    /**
     * @returns {string} - Sales page or checkout link
     */
    salesPage() {
      if(this.eventTickets) {
        return this.baseURL + "/pre-checkout/" + this.productId;
      }
      return this.baseURL + "/" + this.productId;
    },
    /**
     * @return {boolean} - If user is heaven or greenn;
     */
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    getOpportunitiesSettings() {
      return this.$store.getters["opportunitiesProduct/getOpportunitiesSettings"];
    },
    /**
     * @returns {string} - Returns sales url
     */
    baseURL() {
      return this.isHeaven
        ? process.env.VUE_SALES_HEAVEN_PAGE
        : process.env.VUE_SALES_FAST_PAGE;
    },
    product() {
      return this.$store.getters["product"];
    },
    tabs() {            
      return [
        // Informações básicas
        {
          type_original: "basic_information",
          title: this.$t("views.seller.edit_product.tabs.basic_informations"),
          component: "BasicInformations",
          show: Permissions.hasPermission("EditGeneralProduct"),
        },
        // Produto fisico
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.physical_product"),
          component: "PhysicalProduct",
          show:
            Permissions.hasPermission("EditGeneralProduct") &&
            (this.product.product_type_id == 4 ||
              this.product.format == "PHYSICALPRODUCT"),
        },
        // Mentoria
        {
          type_original: "period_and_duration_of_mentoring",
          title: this.$t("views.seller.edit_product.tabs.mentoring"),
          component: "Mentoring",
          show:
            Permissions.hasPermission("EditGeneralProduct") &&
            this.product.product_type_id == 1,
        },
        // Evento
        {
          type_original: "date_time_and_location",
          title: this.$t("views.seller.edit_product.tabs.event"),
          component: "Event",
          show:
            Permissions.hasPermission("EditGeneralProduct") &&
            this.product.product_type_id == 3,
        },
        // Lotes do Evento
        {
          title: this.$t('views.seller.edit_product.text_1033_1'),
          component: "BatchesEvent",
          show: this.eventTickets &&
            Permissions.hasPermission("EditGeneralProduct") &&
            this.product.product_type_id == 3,
        },
        // Cobrança
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.charges_and_offers"),
          component: "ChargesAndOffers",
          show: this.getHasSearchedFlag && (this.eventTickets
            ? Permissions.hasPermission("EditProductOffers") && this.product.product_type_id !== 3
            : Permissions.hasPermission("EditProductOffers"))
        },
        // Parceiros
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.co-productions"),
          component: "CoProductions",
          show: Permissions.hasPermission("EditProductPartners"),
        },
        // Afiliados
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.affiliated"),
          component: "Affiliated",
          show: Permissions.hasPermission("EditProductAffiliates"),
        },
        // Oportunidade
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.opportunities"),
          component: "OpportunityProduct",
          show: Permissions.hasPermission("OpportunityProduct")
            && this.$store.getters.getFlags.includes('opportunity_settings')
            && (this.product.status === "APPROVED" 
            || this.product.status === "REVISION" 
            || this.product.status === "REVISION_RECOMMENDATION"),
        },
        // Conteúdos
        {
          type_original: "member_integration",
          title: this.$t("views.seller.edit_product.tabs.contents"),
          component: "Contents",
          show: Permissions.hasPermission("EditProductIntegrations"),
        },
        // Integrações
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.integrations"),
          component: "Integrations",
          show: Permissions.hasPermission("EditProductIntegrations"),
        },
        // Avançado
        {
          type_original: "",
          title: this.$t("views.seller.edit_product.tabs.advanced"),
          component: "Advanced",
          show:
            Permissions.hasPermission("EditGeneralProduct") ||
            Permissions.hasPermission("EditProductTracking") ||
            Permissions.hasPermission("EditProductTaxSetup"),
        },
      ];
    },
    countryCode() {
      return localStorage.getItem("locationadm") || "BR";
    },
    pageLoading() {
      return this.isLoading || this.saleExpressRedirectLoading;
    },
    thank_you_page: {
      get() {
        return this.$store.state.product.product.thank_you_page;
      },
    }
  },
  methods: {
    ...mapActions([
      "fetchProduct",
      "fetchAffiliateLink",
      "updateProduct",
      "setLoading",
      "fetchOffers",
      "fetchUserSettings",
      "fetchFlags",
      "fetchProductStatus",
      "saveOpportunitySettings",
    ]),
    /**
     * @description - This method init edit product page.
     */
    async init() {
      this.$store.dispatch('updateSaleExpressRedirectLoading', true);

      await this.$store.commit("setCountryCode", this.countryCode);
      await this.$store.commit("setAffiliateLink", "");
      await this.fetchProduct(this.$route.params.id_product);
      await this.fetchUserSettings();

      if (Permissions.hasPermission("EditProductOffers")) {
        await this.fetchFlags();
        await this.fetchOffers(this);
      } else {
        this.$bvToast.toast(this.$t("seller.settings.modal_permission_group.text_838"), {
          title: this.$t("seller.settings.sub_users.text_2689"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }

      this.verifySaleExpressFlow();
      this.getTypes();
    },
    isValidProductThankYouPageURLs() {
      const isValidThankYouPageUrl = this.thank_you_page?.length > 0 ? validateHttpUrl(this.thank_you_page) : true;
      const isValidThankYouPagesByPaymentMethods = Array.isArray(this.custom_thank_you_pages) && this.custom_thank_you_pages ?
      this.custom_thank_you_pages.every(thankYouPage => thankYouPage.url?.length > 0 ? validateHttpUrl(thankYouPage.url) : true) : true;
      return isValidThankYouPageUrl && isValidThankYouPagesByPaymentMethods;
    },
    validate(status = null) {
      
      if(!this.product.category_id) {
        this.$bvToast.toast(this.$t("views.seller.edit_product.text_1121"), {
          title: this.$t("views.seller.edit_product.text_1122"),
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }
      if(!this.isValidProductThankYouPageURLs()) {
        this.$bvToast.toast("Insira uma url válida.", {
          title: "Página de Obrigado Padrão",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return
      }

      if (status) {
        this.status = status;
      }
      serviceCustomThankYouPages.post(this.$route.params.id_product, {
        custom_thank_you_pages: this.custom_thank_you_pages,
      });
      if (
        ["BasicInformations", "Mentoring", "Event","PhysicalProduct"].includes(
          this.selectedTab.component
        )
      ) {
        this.$store.commit("setCallValidateFields", !this.callValidateFields);
        return;
      } else {

        if(this.getOpportunitiesSettings){
          this.$store.dispatch("opportunitiesProduct/saveOpportunitySettings", { settings:this.getOpportunitiesSettings });
        }
        
        // itens emissão de nota fiscal
        if(this.product.enable_invoice_items) {
          if(this.product.items_for_invoice.some(x => !x.title || !x.quantity || !x.percent || !x.ncm)) {
            this.$bvToast.toast(this.$t("views.seller.edit_product.text_3014"), {
              title: this.$t("views.seller.edit_product.text_3018"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return
          }
          if(this.product.items_for_invoice.some(x => parseFloat(x.percent) <= 0)) {
            this.$bvToast.toast(this.$t("views.seller.edit_product.text_3015"), {
              title: this.$t("views.seller.edit_product.text_3018"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return
          }
          if(this.product.items_for_invoice.some(x => parseInt(x.quantity) <= 0)) {
            this.$bvToast.toast(this.$t("views.seller.edit_product.text_3016"), {
              title: this.$t("views.seller.edit_product.text_3018"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return
          }
          let sun = 0
          this.product.items_for_invoice.forEach(x => {
            if(x && x.percent) sun += parseFloat(x.percent)
          })
          if(sun !== 100) {
            this.$bvToast.toast(this.$t("views.seller.edit_product.text_3017"), {
              title: this.$t("views.seller.edit_product.text_3018"),
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return
          }
        }
        // parcela commissão afiliado
        if(this.product.max_comission == 100) {
          this.$store.commit("setProduct", { ...this.product, max_comission: null });
        }
        this.update();
      }
    },
    async update() {
      if (this.eventTickets && !this.getEventFildsResult) {
        return
      }
      if (this.isLoading) return;
      if (this.status) {
        if (this.status === "DISAPPROVED") {
          this.$store.commit("setProduct", {
            ...this.product,
            status: "REQUESTED",
          });
        } else {
          this.$store.commit("setProduct", {
            ...this.product,
            status: "CHANGED",
          });
        }
      }
      await this.updateProduct()
        .then(() => {
          updatedFieldsProduct()
          
          this.$bvToast.toast(this.$t("views.seller.edit_product.text_2368"), {
            title: this.$t("views.seller.edit_product.text_2270"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          const errorMessage = err.response?.data?.errors?.path || 'Ocorreu um erro inesperado';
          this.$bvToast.toast(errorMessage, {
            title: this.$t("views.seller.edit_product.text_2270"),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$root.$emit("errorInUpdateProduct");
        })
        .finally(() => {
          if(this.product.max_comission == null) {
            this.$store.commit("setProduct", { ...this.product, max_comission: 100 });
            this.fetchProductStatus(this.$route.params.id_product);
          }
        });
    },
    async getTypes() {
      await typeService
        .read("")
        .then((res) => {})
        .finally(() => {});
    },
    handleTabClick(tab) {
      this.selectedTab = tab;
      this.$store.commit('setHideUpdateButton', false);
    },
    setTab(data) {
      this.selectedTab = this.tabs.filter(item => (item.type_original === data.type_original)).pop();
    },
    setTabByComponent(componentName) {
      this.selectedTab = this.tabs.filter(item => (item.component === componentName)).pop();
    },
    verifySaleExpressFlow() {
      const from = this.$route.query.from;
      if (from !== 'sale_express') {
        this.$store.dispatch('updateSaleExpressRedirectLoading', false);
        return;
      }
      this.setTabByComponent('ChargesAndOffers');
      this.removeQueryParam('from');
    },
    removeQueryParam(param) {
      if (this.$route.query[param]) {
        let newQuery = {...this.$route.query};
        delete newQuery[param];
        const newUrl = this.$router.resolve({ query: newQuery }).href;
        window.history.replaceState({}, '', newUrl);
      }
    }
  },
  mounted() {
    this.init();
    EventBus.$on("customThankYouPage", (data) => {
      this.custom_thank_you_pages = data;
    });
  },
  created(){
    if (this.$route.params.code !== undefined) {
      this.selectedTab = {
        title: this.$t("views.seller.edit_product.tabs.integrations"),
        component: "Integrations",
        show: Permissions.hasPermission("EditProductIntegrations")
      }
      setTimeout(() => {
        this.$bvModal.show('delivery-options')
      }, 500);
      infusionsoftService
        .create(
          {
            code: this.$route.query.code,
            redirect_uri: `${
              this.isHeaven
                ? process.env.VUE_ADM_HEAVEN_PAGE
                : process.env.VUE_ADM_PAGE
            }${this.$route.fullPath.split("?")[0]}`,
          },
          null,
          "/token"
        )
        .then((res) => {
          let data = res;
          data.title = this.$route.params.code;
          data.type = "infusionsoft";
          data.expires_in = `${data.expires_in}`;
          data.code = this.$route.query.code;
          connectionService.create(data).then(() => {
            this.$bvToast.toast(
              this.$t("views.seller.edit_product.text_2376"),
              {
                title: "Infusion Soft",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.$bvModal.show("modal-infusionsoft");
            let linkToReplace = `${this.$route.path}`.replace(
              `/${this.$route.params.code}`,
              ""
            );
            this.$router.push(linkToReplace);
          });
        })
        .catch((e) => {
          this.$bvToast.toast(`${e.scope ? e.scope[0] : ""}`, {
            title: "Infusion Soft",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            let linkToReplace = `${this.$route.path}`.replace(
              `/${this.$route.params.code}`,
              ""
            );
            this.$router.push(linkToReplace);
          }, 300);
        });
    }
    if(this.$route.query.callback && this.$route.query.callback === 'contaazul') {
      setTimeout(() => {
        this.selectedTab = {
          component: "Integrations",
          show: true,
          title: "Integrações",
          type_original: ""
        }
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
.update-product {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__footer {
    padding: 30px 0;
    border-top: 1px solid #ededf0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    &__links {
      display: flex;
      align-items: center;
      gap: 30px;
      &__item {
        p {
          font-size: 12px;
          max-width: 100%;
        }
        a {
          font-size: 12px;
          color: $secondary-color;
          max-width: 100%;
        }
      }
    }
  }
}

.custom-columns-after {
  max-width: 310px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.isLoading {
  background: rgba(0, 0, 0, 0.03);
  color: transparent !important;
  border-radius: 4px;
  pointer-events: none;
}
</style>
